import React from 'react'
import { styled } from '@mui/material/styles';

export const UserAccount = styled((props) => {
	const { className } = props;

	return (
		<div className={className}>
			<svg width="84" height="68" viewBox="0 0 84 68" fill="none" style={{ height: '20px', width: '20px' }} xmlns="http://www.w3.org/2000/svg">
                <circle cx="29.5" cy="17.5" r="17.5" fill="#7766DF"/>
                <path d="M0 61C0 49.402 9.40202 40 21 40H39.6787C41.1141 40 42.4395 40.7691 43.1517 42.0154L44.8542 44.9948C45.5632 46.2356 45.5564 47.7604 44.8363 48.9949L43.7114 50.9233C42.9659 52.2012 42.9867 53.7863 43.7655 55.0442L47.5166 61.1037C48.4062 62.5408 50.1031 63.2636 51.7557 62.9095L54.7905 62.2592C55.413 62.1258 56 62.6003 56 63.237V68H3C1.34314 68 0 66.6569 0 65V61Z" fill="#7766DF"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M64.9999 53.3333C61.5303 53.3333 58.7195 50.4985 58.7195 47C58.7195 43.5015 61.5303 40.6667 64.9999 40.6667C68.4694 40.6667 71.2815 43.5015 71.2815 47C71.2815 50.4985 68.4694 53.3333 64.9999 53.3333ZM82.744 51.4789L79.8014 49.7664C79.9661 48.8671 80.0751 47.9487 80.0751 47C80.0751 46.0525 79.9661 45.1329 79.8014 44.2336L82.744 42.5211C83.9461 41.8219 84.3566 40.2715 83.6624 39.0605L81.1505 34.6728C80.4576 33.4619 78.9198 33.0451 77.719 33.7456L74.746 35.4746C73.3577 34.2839 71.7793 33.3099 70.0249 32.6829V30.5333C70.0249 29.1349 68.9001 28 67.5118 28H62.488C61.0996 28 59.9748 29.1349 59.9748 30.5333V32.6829C58.2204 33.3099 56.6421 34.2839 55.2537 35.4746L52.2808 33.7456C51.0799 33.0451 49.5421 33.4619 48.8492 34.6728L46.3373 39.0605C45.6432 40.2715 46.0548 41.8219 47.2557 42.5211L50.1983 44.2336C50.0336 45.1329 49.9247 46.0525 49.9247 47C49.9247 47.9487 50.0336 48.8671 50.1983 49.7664L47.2557 51.4789C46.0548 52.1781 45.6432 53.7285 46.3373 54.9395L48.8492 59.3272C49.5421 60.5381 51.0799 60.9549 52.2808 60.2544L55.2537 58.5254C56.6421 59.7161 58.2204 60.6914 59.9748 61.3171V63.4667C59.9748 64.8651 61.0996 66 62.488 66H67.5118C68.9001 66 70.0249 64.8651 70.0249 63.4667V61.3171C71.7793 60.6914 73.3577 59.7161 74.746 58.5254L77.719 60.2544C78.9198 60.9549 80.4576 60.5381 81.1505 59.3272L83.6624 54.9395C84.3566 53.7285 83.9461 52.1781 82.744 51.4789Z" fill="#7766DF"/>
            </svg>
		</div>
	);
})`
	display: flex;
`;