import React from 'react'
import { styled } from '@mui/material/styles';

export const Application = styled((props) => {
	const { className } = props;

	return (
		<div className={className}>
			<svg width="166" height="129" viewBox="0 0 166 129" fill="none" style={{ height: '20px', width: '20px' }} xmlns="http://www.w3.org/2000/svg">
                <path d="M0 12C0 5.37305 5.37256 0 12 0H44C50.6274 0 56 5.37305 56 12V18H110V12C110 5.37305 115.373 0 122 0H154C160.627 0 166 5.37305 166 12V44C166 50.627 160.627 56 154 56H122C115.373 56 110 50.627 110 44V38H56V41.3984L79.4275 73H108C114.627 73 120 78.373 120 85V117C120 123.627 114.627 129 108 129H76C69.3726 129 64 123.627 64 117V85.7734L41.928 56H12C5.37256 56 0 50.627 0 44V12Z" fill="#7766DF"/>
            </svg>
		</div>
	);
})`
	display: flex;
`;