import React from 'react'
import { styled } from '@mui/material/styles';

export const FunctionalAdvanceAutomation = styled((props) => {
	const { className } = props;

	return (
		<div className={className}>
			<svg width="89" height="87" viewBox="0 0 89 87" fill="none" style={{ height: '20px', width: '20px' }} xmlns="http://www.w3.org/2000/svg">
                <mask id="path-1-inside-1_5325_4764" fill="white">
                    <rect x="27.8862" y="28.125" width="30.347" height="30.7571" rx="5.27264"/>
                </mask>
                <rect x="27.8862" y="28.125" width="30.347" height="30.7571" rx="5.27264" stroke="#7766DF" stroke-width="14.0604" mask="url(#path-1-inside-1_5325_4764)"/>
                <path d="M57.5 76.4549H21.0902C15.2662 76.4549 10.5449 71.7336 10.5449 65.9096V21.0922C10.5449 15.2682 15.2662 10.5469 21.0902 10.5469H65.0289C70.8529 10.5469 75.5742 15.2608 75.5742 21.0848C75.5742 30.5561 75.5742 44.5436 75.5742 55" stroke="#7766DF" stroke-width="7.90896"/>
                <path d="M28.1206 3.95448C28.1206 1.77048 29.8911 0 32.0751 0C34.2591 0 36.0296 1.77048 36.0296 3.95448V10.5453H28.1206V3.95448Z" fill="#7766DF"/>
                <path d="M28.1206 83.0455C28.1206 85.2295 29.8911 87 32.0751 87C34.2591 87 36.0296 85.2295 36.0296 83.0455V76.4547H28.1206V83.0455Z" fill="#7766DF"/>
                <path d="M3.95448 28.125C1.77048 28.125 -7.73902e-08 29.8955 -1.72856e-07 32.0795C-2.68321e-07 34.2635 1.77048 36.034 3.95448 36.034L10.5453 36.034L10.5453 28.125L3.95448 28.125Z" fill="#7766DF"/>
                <path d="M82.1652 28.125C84.3492 28.125 86.1196 29.8955 86.1196 32.0795C86.1196 34.2635 84.3491 36.034 82.1651 36.034L75.5743 36.034L75.5743 28.125L82.1652 28.125Z" fill="#7766DF"/>
                <path d="M50.0908 3.95448C50.0908 1.77048 51.8613 0 54.0453 0C56.2293 0 57.9998 1.77048 57.9998 3.95448V10.5453H50.0908V3.95448Z" fill="#7766DF"/>
                <path d="M50.0908 83.2954C50.0908 85.3414 51.7494 87 53.7954 87C55.8414 87 57.5 85.3414 57.5 83.2954V76.5L50.0908 76.4547V83.2954Z" fill="#7766DF"/>
                <path d="M3.95448 46.5742C1.77048 46.5742 -7.73902e-08 48.3447 -1.72856e-07 50.5287C-2.68321e-07 52.7127 1.77048 54.4832 3.95448 54.4832L10.5453 54.4832L10.5453 46.5742L3.95448 46.5742Z" fill="#7766DF"/>
                <path d="M82.1086 46.5742C84.3146 46.5742 86.094 48.3791 86.0627 50.5848L86.0553 51.1017C86.0247 53.2636 84.2634 55 82.1013 55L75.5743 55L75.5743 46.5742L82.1086 46.5742Z" fill="#7766DF"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M73.9999 77C71.2608 77 69.0417 74.762 69.0417 72C69.0417 69.238 71.2608 67 73.9999 67C76.739 67 78.9591 69.238 78.9591 72C78.9591 74.762 76.739 77 73.9999 77ZM88.0084 75.536L85.6854 74.184C85.8154 73.474 85.9014 72.749 85.9014 72C85.9014 71.252 85.8154 70.526 85.6854 69.816L88.0084 68.464C88.9575 67.912 89.2815 66.688 88.7335 65.732L86.7504 62.268C86.2034 61.312 84.9893 60.983 84.0413 61.536L81.6942 62.901C80.5982 61.961 79.3521 61.192 77.9671 60.697V59C77.9671 57.896 77.079 57 75.983 57H72.0168C70.9208 57 70.0327 57.896 70.0327 59V60.697C68.6477 61.192 67.4016 61.961 66.3056 62.901L63.9585 61.536C63.0105 60.983 61.7964 61.312 61.2494 62.268L59.2663 65.732C58.7183 66.688 59.0433 67.912 59.9913 68.464L62.3144 69.816C62.1844 70.526 62.0984 71.252 62.0984 72C62.0984 72.749 62.1844 73.474 62.3144 74.184L59.9913 75.536C59.0433 76.088 58.7183 77.312 59.2663 78.268L61.2494 81.732C61.7964 82.688 63.0105 83.017 63.9585 82.464L66.3056 81.099C67.4016 82.039 68.6477 82.809 70.0327 83.303V85C70.0327 86.104 70.9208 87 72.0168 87H75.983C77.079 87 77.9671 86.104 77.9671 85V83.303C79.3521 82.809 80.5982 82.039 81.6942 81.099L84.0413 82.464C84.9893 83.017 86.2034 82.688 86.7504 81.732L88.7335 78.268C89.2815 77.312 88.9575 76.088 88.0084 75.536Z" fill="#7766DF"/>
            </svg>
		</div>
	);
})`
	display: flex;
`;