import React from 'react'
import { styled } from '@mui/material/styles';

export const OrgSettings = styled((props) => {
	const { className } = props;

	return (
		<div className={className}>
			<svg width="85" height="68" viewBox="0 0 85 68" fill="none" style={{ height: '20px', width: '20px' }} xmlns="http://www.w3.org/2000/svg">
				<circle cx="42.5" cy="28.5" r="13.5" fill="#7766DF"/>
				<circle cx="18" cy="11" r="11" fill="#7766DF"/>
				<circle cx="68" cy="11" r="11" fill="#7766DF"/>
				<path d="M17 65.375C17 55.2268 25.2268 47 35.375 47H50C48.6472 56.8283 50.3618 61.3711 57 68H19.625C18.1753 68 17 66.8247 17 65.375Z" fill="#7766DF"/>
				<path d="M0 39.125C0 31.8763 5.87626 26 13.125 26H25.575C24.5266 33.0202 25.8554 36.2651 31 41H1.875C0.839466 41 0 40.1605 0 39.125Z" fill="#7766DF"/>
				<path d="M58.999 29C58.999 27.3431 60.3422 26 61.999 26H75.999C80.9696 26 84.999 30.0294 84.999 35V39.0441C84.999 39.8599 84.0692 40.3393 83.3832 39.8979C75.1343 34.5905 65.3 32.9675 59.8802 36.7936C59.5256 37.044 58.999 36.8039 58.999 36.3699V29Z" fill="#7766DF"/>
				<path fill-rule="evenodd" clip-rule="evenodd" d="M67.9999 58C65.2608 58 63.0417 55.762 63.0417 53C63.0417 50.238 65.2608 48 67.9999 48C70.739 48 72.9591 50.238 72.9591 53C72.9591 55.762 70.739 58 67.9999 58ZM82.0084 56.536L79.6854 55.184C79.8154 54.474 79.9014 53.749 79.9014 53C79.9014 52.252 79.8154 51.526 79.6854 50.816L82.0084 49.464C82.9575 48.912 83.2815 47.688 82.7335 46.732L80.7504 43.268C80.2034 42.312 78.9893 41.983 78.0413 42.536L75.6942 43.901C74.5982 42.961 73.3521 42.192 71.9671 41.697V40C71.9671 38.896 71.079 38 69.983 38H66.0168C64.9208 38 64.0327 38.896 64.0327 40V41.697C62.6477 42.192 61.4016 42.961 60.3056 43.901L57.9585 42.536C57.0105 41.983 55.7964 42.312 55.2494 43.268L53.2663 46.732C52.7183 47.688 53.0433 48.912 53.9913 49.464L56.3144 50.816C56.1844 51.526 56.0984 52.252 56.0984 53C56.0984 53.749 56.1844 54.474 56.3144 55.184L53.9913 56.536C53.0433 57.088 52.7183 58.312 53.2663 59.268L55.2494 62.732C55.7964 63.688 57.0105 64.017 57.9585 63.464L60.3056 62.099C61.4016 63.039 62.6477 63.809 64.0327 64.303V66C64.0327 67.104 64.9208 68 66.0168 68H69.983C71.079 68 71.9671 67.104 71.9671 66V64.303C73.3521 63.809 74.5982 63.039 75.6942 62.099L78.0413 63.464C78.9893 64.017 80.2034 63.688 80.7504 62.732L82.7335 59.268C83.2815 58.312 82.9575 57.088 82.0084 56.536Z" fill="#7766DF"/>
				<path fill-rule="evenodd" clip-rule="evenodd" d="M67.9999 58C65.2608 58 63.0417 55.762 63.0417 53C63.0417 50.238 65.2608 48 67.9999 48C70.739 48 72.9591 50.238 72.9591 53C72.9591 55.762 70.739 58 67.9999 58ZM82.0084 56.536L79.6854 55.184C79.8154 54.474 79.9014 53.749 79.9014 53C79.9014 52.252 79.8154 51.526 79.6854 50.816L82.0084 49.464C82.9575 48.912 83.2815 47.688 82.7335 46.732L80.7504 43.268C80.2034 42.312 78.9893 41.983 78.0413 42.536L75.6942 43.901C74.5982 42.961 73.3521 42.192 71.9671 41.697V40C71.9671 38.896 71.079 38 69.983 38H66.0168C64.9208 38 64.0327 38.896 64.0327 40V41.697C62.6477 42.192 61.4016 42.961 60.3056 43.901L57.9585 42.536C57.0105 41.983 55.7964 42.312 55.2494 43.268L53.2663 46.732C52.7183 47.688 53.0433 48.912 53.9913 49.464L56.3144 50.816C56.1844 51.526 56.0984 52.252 56.0984 53C56.0984 53.749 56.1844 54.474 56.3144 55.184L53.9913 56.536C53.0433 57.088 52.7183 58.312 53.2663 59.268L55.2494 62.732C55.7964 63.688 57.0105 64.017 57.9585 63.464L60.3056 62.099C61.4016 63.039 62.6477 63.809 64.0327 64.303V66C64.0327 67.104 64.9208 68 66.0168 68H69.983C71.079 68 71.9671 67.104 71.9671 66V64.303C73.3521 63.809 74.5982 63.039 75.6942 62.099L78.0413 63.464C78.9893 64.017 80.2034 63.688 80.7504 62.732L82.7335 59.268C83.2815 58.312 82.9575 57.088 82.0084 56.536Z" fill="#7766DF"/>
			</svg>
		</div>
	);
})`
	display: flex;
`;