import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
    main: {
        margin: "20px",
        border: '2px solid #DADADA',
        backgroundColor: 'white',
        borderRadius: '10px',
    },
    reminderbox: {
        margin: '20px',
        border: '2px solid #DADADA',
        borderRadius: '10px',
        textAlign: 'center',
        paddingTop: '10px',
        paddingBottom: '10px',
        backgroundColor: '#FAFAFA',
        fontFamily: 'Lexend Deca',
        color: '#646464',
        justifyContent:'center',
        alignItems:'center',
    },
    secondbox: {
        border: '0px solid pink',
        display: 'flex',
        flexDirection: 'row',
        margin: '20px',
    },
    CurrentPlan: {
        border: '3px solid #DADADA',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        width: '500px',
        marginRight: '20px',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Adds a subtle box shadow
    },
    billingDetails: {
        fontSize: '20px',
        marginLeft: '20px',
        fontFamily: 'Lexend Deca',
        color: '#654DF7',
        fontWeight: '500',
        textDecoration: 'underline', // Adds underline to the text
        textDecorationColor: '#654DF7', // Sets the underline color to match the text color
        border: '0px solid #654DF7',
        marginTop: '30px',
    },
    CurrentPlan_Details: {
        border: '2px solid #DADADA',
        borderRadius: '10px',
        marginLeft: '20px',
        display: 'flex',
        flexDirection: 'column',
        width: '90%',
        alignItems: 'center',
        marginTop: '20px',
    },
    CurrentPlan_Details_row: {
        borderBottom: '2px solid #DADADA',
        display: 'flex',
        flexDirection: 'row',
        width: '90%', // Fixed duplicated width property
        marginTop: '10px',
        paddingBottom: '10px',
        fontFamily: 'Lexend Deca',
        fontWeight: '300',
        fontSize: '14px',
    },
    button: {
        marginLeft: '20px',
        display: 'flex',
        flexDirection: 'column',
        width: '90%',
        textAlign: 'center',
        height: '30px',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#654DF7',
        fontWeight: '500',
        color: '#FFFFFF',
        marginTop: '10px',
        marginBottom: '10px',
    },
    includedplan: {
        display: 'flex',
        flexWrap: 'wrap',
        backgroundColor: 'white',
        margin: '10px',
    },
    available_plan: {
        border: '3px solid #DADADA', // Kept one of the borders, removed the duplicate
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Adds a subtle box shadow
        borderRadius: '10px',
        maxWidth:'60%',
    },
    planFeatureContainer: { // Fixed invalid key syntax, used camelCase
        width: 'calc(33.33% - 47px)',
        margin: '15px 23px',
        boxSizing: 'border-box',
        border: '2px solid #b6e1e7',
        borderRadius: '6px',
        fontSize: '12px',
    },
    dynamicImageDiv: { // Fixed invalid key syntax, used camelCase
        border: '1px solid green',
        borderRadius: '50%',
        height: '90px',
        width: '40px',
        position: 'absolute',
        left: '-22px',
        zIndex: 2,
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    CurrentPlan_Details_row_last:{
        display: 'flex',
        flexDirection: 'row',
        width: '90%', // Fixed duplicated width property
        marginTop: '10px',
        paddingBottom: '10px',
        fontFamily: 'Lexend Deca',
        fontWeight: '300',
        fontSize: '14px',

    }
});
