import React from 'react'
import { styled } from '@mui/material/styles';

export const TestUser = styled((props) => {
	const { className } = props;

	return (
		<div className={className}>
			<svg width="63" height="71" viewBox="0 0 63 71" fill="none" style={{ height: '20px', width: '20px' }} xmlns="http://www.w3.org/2000/svg">
                <circle cx="31.5" cy="17.5" r="17.5" fill="#654DF7"/>
                <path d="M0 58.9796C0 49.2064 8.19506 41.4313 17.9548 41.945C18.5714 41.9774 19.0893 42.4198 19.2178 43.0238L23.2203 61.8356C23.4335 62.8373 24.8358 62.9092 25.1502 61.9345L27.715 53.9836C28.5298 51.4578 28.1882 48.7011 26.7816 46.4505L24.9562 43.53C24.54 42.864 25.0188 42 25.8042 42H37.1958C37.9812 42 38.46 42.864 38.0438 43.53L36.0481 46.723C34.7404 48.8153 34.3491 51.3527 34.9656 53.7419L37.0652 61.8777C37.321 62.8689 38.7244 62.8811 38.9973 61.8944L44.2447 42.923C44.3972 42.3716 44.885 41.9792 45.4563 41.9483C54.9883 41.4331 63 49.0234 63 58.5693V69.3864C63 70.2776 62.2776 71 61.3864 71H1.61578C0.723406 71 0 70.2766 0 69.3842V58.9796Z" fill="#654DF7"/>
            </svg>
		</div>
	);
})`
	display: flex;
`;