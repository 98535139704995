import { styled } from '@mui/material/styles';

export const DashBoardIcon = styled(({ className, isActive, color }) => {
	return (
		<div style={{ color: isActive ? color : 'inherit' }}>
            <svg width="40" height="40" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="36.2995" cy="36.0944" rx="35.8795" ry="35.8796" fill={isActive ? "white" : "#7766DF"}/>
            <path d="M19.4141 36.1429C19.4141 35.4895 19.6946 34.8675 20.1844 34.4351L34.9926 21.3623C35.3925 21.0093 35.9075 20.8145 36.4409 20.8145C36.9681 20.8145 37.4776 21.0048 37.8757 21.3504L52.7995 34.3075C53.2979 34.7402 53.5841 35.3677 53.5841 36.0277V36.3808C53.5841 37.3593 52.7908 38.1526 51.8123 38.1526H49.9139V48.9098C49.9139 50.1679 48.8941 51.1878 47.636 51.1878H42.3206C41.0625 51.1878 40.0426 50.1679 40.0426 48.9098V43.5945C40.0426 42.6159 39.2494 41.8227 38.2708 41.8227H34.4742C33.4956 41.8227 32.7024 42.6159 32.7024 43.5945V48.9098C32.7024 50.1679 31.6825 51.1878 30.4244 51.1878H25.3622C24.1041 51.1878 23.0842 50.1679 23.0842 48.9098V38.1526H21.1858C20.2073 38.1526 19.4141 37.3593 19.4141 36.3808V36.1429Z" fill={isActive ? "#7766DF" : "white"}/>
            </svg>
		</div>
	);
})`
	display: flex;
`;