import React from 'react'
import { styled } from '@mui/material/styles';

export const Performance = styled((props) => {
	const { className } = props;

	return (
		<div className={className}>
			<svg width="88" height="53" viewBox="0 0 88 53" fill="none" style={{ height: '20px', width: '20px' }} xmlns="http://www.w3.org/2000/svg">
                <path d="M84.9953 4.31758C85.0611 3.56708 84.4287 2.93933 83.6727 3.00468L61.517 4.91992C60.4833 5.00928 60.0257 6.25656 60.7594 6.98486L80.9856 27.0623C81.7193 27.7906 82.9758 27.3363 83.0659 26.3102L84.9953 4.31758Z" fill="#7766DF"/>
                <path d="M80.9856 27.0623L83.0991 24.9332L80.9856 27.0623ZM83.0659 26.3102L80.0773 26.0481L83.0659 26.3102ZM61.517 4.91992L61.2586 1.93106L61.517 4.91992ZM60.7594 6.98486L58.6459 9.114L60.7594 6.98486ZM83.6727 3.00468L83.931 5.99354L83.6727 3.00468ZM84.9953 4.31758L87.9838 4.57976V4.57976L84.9953 4.31758ZM29.0733 24.1185L31.1868 26.2476L29.0733 24.1185ZM0.886525 47.8709C-0.289365 49.0381 -0.296377 50.9376 0.870863 52.1135C2.0381 53.2894 3.93759 53.2964 5.11348 52.1291L0.886525 47.8709ZM54.8069 32.5184L52.6934 30.3893L54.8069 32.5184ZM46.1712 32.5184L48.2846 30.3892L46.1712 32.5184ZM37.709 24.1185L35.5955 26.2476L37.709 24.1185ZM83.4143 0.0158303L61.2586 1.93106L61.7754 7.90877L83.931 5.99354L83.4143 0.0158303ZM58.6459 9.114L78.8721 29.1914L83.0991 24.9332L62.8729 4.85573L58.6459 9.114ZM86.0544 26.5724L87.9838 4.57976L82.0068 4.0554L80.0773 26.0481L86.0544 26.5724ZM78.8721 29.1914C81.3844 31.6852 85.7389 30.1681 86.0544 26.5724L80.0773 26.0481C80.2127 24.5046 82.0542 23.896 83.0991 24.9332L78.8721 29.1914ZM61.2586 1.93106C57.7166 2.23726 56.0726 6.55958 58.6459 9.114L62.8729 4.85573C63.9788 5.95353 63.25 7.7813 61.7754 7.90877L61.2586 1.93106ZM83.931 5.99354C82.8566 6.08641 81.9073 5.1888 82.0068 4.0554L87.9838 4.57976C88.2149 1.94536 86.0008 -0.20776 83.4143 0.0158303L83.931 5.99354ZM26.9598 21.9894L0.886525 47.8709L5.11348 52.1291L31.1868 26.2476L26.9598 21.9894ZM78.7667 4.50774L52.6934 30.3893L56.9204 34.6475L82.9937 8.76601L78.7667 4.50774ZM48.2846 30.3892L39.8225 21.9894L35.5955 26.2476L44.0577 34.6475L48.2846 30.3892ZM52.6934 30.3893C51.4785 31.5952 49.4996 31.5952 48.2846 30.3892L44.0577 34.6475C47.6121 38.1758 53.3659 38.1758 56.9204 34.6475L52.6934 30.3893ZM31.1868 26.2476C32.4017 25.0416 34.3806 25.0416 35.5955 26.2476L39.8225 21.9894C36.2681 18.4611 30.5143 18.4611 26.9598 21.9894L31.1868 26.2476Z" fill="#7766DF"/>
            </svg>
		</div>
	);
})`
	display: flex;
`;