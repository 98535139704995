import { toast } from "react-toastify";
import { getBaseUrl } from "../../../utils/configService";
import axios from "axios";
import { header } from "../../../utils/authheader";
export const SET_EXPANDED_NODE = "SET_EXPANDED_NODE";
export const EXPAND_PARENT = "EXPAND_PARENT";
export const SET_ROOT_ID = "SET_ROOT_ID";
export const SET_SELECTED_NODE = "SET_SELECTED_NODE";
export const SCHEDULE_SUITE = "SCHEDULE_SUITE";
export const SET_PARENT_ID = "SET_PARENT_ID";
export const SET_NODE_COUNT = "SET_NODE_COUNT";
export const EXECUTING_SUITE = "EXECUTING_SUITE";

export const setParentId = (parentId) => ({
  type: SET_PARENT_ID,
  payload: parentId,
});

export const setRootId = (suitId) => {
  return {
    type: SET_ROOT_ID,
    payload: suitId,
  };
};

export const setSelectedNode = (node) => (dispatch) => {
    dispatch({
      type: SET_ROOT_ID,
      payload: node.id,
    });

    dispatch({
      type: SET_SELECTED_NODE,
      payload: node,
    });
};
export const setExpandedNodes = (id) => {
  return {
    type: SET_EXPANDED_NODE,
    payload: id,
  };
};

export const ExpandParent = (id) => {
  return {
    type: EXPAND_PARENT,
    payload: id,
  };
};

export const SetSchedule = (isScheduling) => {
  return {
    type: SCHEDULE_SUITE,
    payload:isScheduling
  };
};


export const setNodeCount = (count) => {
  return {
    type: SET_NODE_COUNT,
    payload: count,
  };
};

export const setExecutingSuite = (suiteName) => {
  return {
    type: EXECUTING_SUITE,
    payload: suiteName,
  };
};
 
export const ExecutingSuiteAndProjectByRootId = (data) => {
  return async (dispatch) => {
    const BASE_URL = await getBaseUrl();
    try {
      const response = await axios.post(
        `${BASE_URL}/AddTestLab/InvokeTestLabTestRunPyhtonAPI`,
        data,
        header(),
      );
      if (response.status === 200) {
        toast.info("Successfully Executed", {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      }
      dispatch(setExecutingSuite([]));
    } catch (error) {
      console.log("Network error", error);
      dispatch(setExecutingSuite([]))
      toast.error("Network error")
    }
  };
};