import { styled } from '@mui/material/styles';

export const GenieTestingIcon = styled(({ className, isActive, color }) => {
	return (
		<div style={{ color: isActive ? color : 'inherit' }}>
            <svg width="40" height="40" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="31.2999" cy="30.7819" rx="30.6881" ry="30.6881" fill={isActive ? "white" : "#7766DF"}/>
            <mask id="path-2-inside-1_5186_129" fill={isActive ? "#7766DF" : "white"}>
            <rect x="25.7139" y="25.1445" width="10.5714" height="10.7143" rx="1.83673"/>
            </mask>
            <rect x="25.7139" y="25.1445" width="10.5714" height="10.7143" rx="1.83673" fill={isActive ? "#7766DF" : "white"} stroke={isActive ? "white" : "#7766DF"} stroke-width="4.89796" mask="url(#path-2-inside-1_5186_129)"/>
            <mask id="path-3-inside-2_5186_129" fill={isActive ? "#7766DF" : "white"}>
            <rect x="25.7139" y="25.1445" width="10.5714" height="10.7143" rx="1.83673"/>
            </mask>
            <rect x="25.7139" y="25.1445" width="10.5714" height="10.7143" rx="1.83673" stroke={isActive ? "#7766DF" : "white"} stroke-width="4.89796" mask="url(#path-3-inside-2_5186_129)"/>
            <rect x="21.0504" y="20.399" width="19.898" height="20.2041" rx="2.29592" stroke={isActive ? "#7766DF" : "white"} stroke-width="2.7551"/>
            <path d="M25.7954 16.7252C25.7954 15.9644 26.4122 15.3477 27.173 15.3477C27.9338 15.3477 28.5505 15.9644 28.5505 16.7252V19.0211H25.7954V16.7252Z" fill={isActive ? "#7766DF" : "white"}/>
            <path d="M25.7954 44.2767C25.7954 45.0375 26.4122 45.6543 27.173 45.6543C27.9338 45.6543 28.5505 45.0375 28.5505 44.2767V41.9808H25.7954V44.2767Z" fill={isActive ? "#7766DF" : "white"}/>
            <path d="M17.3771 25.1445C16.6163 25.1445 15.9995 25.7613 15.9995 26.5221C15.9995 27.2829 16.6163 27.8996 17.3771 27.8996L19.673 27.8996L19.673 25.1445L17.3771 25.1445Z" fill={isActive ? "#7766DF" : "white"}/>
            <path d="M44.622 25.1445C45.3828 25.1445 45.9995 25.7613 45.9995 26.5221C45.9995 27.2829 45.3828 27.8996 44.622 27.8996L42.326 27.8996L42.326 25.1445L44.622 25.1445Z" fill={isActive ? "#7766DF" : "white"}/>
            <path d="M33.4487 16.7252C33.4487 15.9644 34.0655 15.3477 34.8263 15.3477C35.5871 15.3477 36.2038 15.9644 36.2038 16.7252V19.0211H33.4487V16.7252Z" fill={isActive ? "#7766DF" : "white"}/>
            <path d="M33.4487 44.2767C33.4487 45.0375 34.0655 45.6543 34.8263 45.6543C35.5871 45.6543 36.2038 45.0375 36.2038 44.2767V41.9808H33.4487V44.2767Z" fill={isActive ? "#7766DF" : "white"}/>
            <path d="M17.3771 31.5723C16.6163 31.5723 15.9995 32.189 15.9995 32.9498C15.9995 33.7106 16.6163 34.3274 17.3771 34.3274L19.673 34.3274L19.673 31.5723L17.3771 31.5723Z" fill={isActive ? "#7766DF" : "white"}/>
            <path d="M44.622 31.5723C45.3828 31.5723 45.9995 32.189 45.9995 32.9498C45.9995 33.7106 45.3828 34.3274 44.622 34.3274L42.326 34.3274L42.326 31.5723L44.622 31.5723Z" fill={isActive ? "#7766DF" : "white"}/>
            </svg>
		</div>
	);
})`
	display: flex;
`;