import { styled } from "@mui/material/styles";

export const TotalDuration = styled((props) => {
  const { className } = props;

  return (
    <div className={className}>
   <svg width="35" height="27" viewBox="0 0 35 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.136719" width="33.8507" height="26.4314" rx="4" fill="#FACE09" fill-opacity="0.19"/>
    <path d="M14.5438 4H10.1824C8.99048 4 8 4.88813 8 5.98095V9.28255C8 10.3754 8.99048 11.2635 10.1824 11.2635H14.5438C15.7323 11.2635 16.7228 10.3754 16.7228 9.28255V5.98095C16.7228 4.88813 15.7455 4 14.5438 4ZM12.7246 9.28255H11.9982C11.5388 9.3032 11.09 9.14115 10.7497 8.83181C10.4094 8.52248 10.2054 8.09103 10.1824 7.63175C10.2054 7.17248 10.4094 6.74102 10.7497 6.43169C11.09 6.12236 11.5388 5.9603 11.9982 5.98095H12.7246C13.1843 5.96029 13.6335 6.12224 13.9743 6.43148C14.3151 6.74073 14.5198 7.17219 14.5438 7.63175C14.5198 8.09132 14.3151 8.52277 13.9743 8.83202C13.6335 9.14127 13.1843 9.30322 12.7246 9.28255Z" fill="#FACE09"/>
    <path d="M21.0835 11.2635H25.4449C26.6368 11.2635 27.6272 10.3754 27.6272 9.28255V5.98095C27.6272 4.88813 26.6368 4 25.4449 4H21.0736C19.885 4 18.8945 4.88813 18.8945 5.98095V9.28255C18.8945 10.3754 19.885 11.2635 21.0835 11.2635ZM22.9027 5.98095H23.629C24.0884 5.9603 24.5373 6.12236 24.8776 6.43169C25.2178 6.74102 25.4218 7.17248 25.4449 7.63175C25.4218 8.09103 25.2178 8.52248 24.8776 8.83181C24.5373 9.14115 24.0884 9.3032 23.629 9.28255H22.9027C22.4429 9.30322 21.9937 9.14127 21.6529 8.83202C21.3121 8.52277 21.1074 8.09132 21.0835 7.63175C21.1074 7.17219 21.3121 6.74073 21.6529 6.43148C21.9937 6.12224 22.4429 5.96029 22.9027 5.98095Z" fill="#FACE09"/>
    <path d="M14.5438 13.2444H10.1824C8.99048 13.2444 8 14.1325 8 15.2253V18.5269C8 19.6198 8.99048 20.5079 10.1824 20.5079H14.5438C15.7323 20.5079 16.7228 19.6198 16.7228 18.5269V15.2253C16.7228 14.1325 15.7455 13.2444 14.5438 13.2444ZM12.7246 18.5269H11.9982C11.5604 18.5269 11.1405 18.353 10.8309 18.0434C10.5214 17.7338 10.3474 17.314 10.3474 16.8761C10.3474 16.4383 10.5214 16.0184 10.8309 15.7088C11.1405 15.3993 11.5604 15.2253 11.9982 15.2253H12.7246C13.1624 15.2253 13.5823 15.3993 13.8919 15.7088C14.2015 16.0184 14.3754 16.4383 14.3754 16.8761C14.3754 17.314 14.2015 17.7338 13.8919 18.0434C13.5823 18.353 13.1624 18.5269 12.7246 18.5269Z" fill="#FACE09"/>
    <path d="M27.7395 19.7255C27.7149 19.691 27.6848 19.6609 27.6503 19.6364L26.8877 19.0091C26.7815 18.9192 26.647 18.87 26.508 18.87C26.3689 18.87 26.2344 18.9192 26.1283 19.0091L24.8407 20.0755C24.6228 20.2604 24.2431 20.1448 24.2431 19.8675V13.7067C24.2268 13.5772 24.1631 13.4583 24.0644 13.373C23.9656 13.2876 23.8387 13.2418 23.7082 13.2445H22.622C22.4907 13.243 22.3634 13.2889 22.2633 13.3738C22.1632 13.4588 22.0971 13.577 22.0772 13.7067V19.8675C22.0772 20.1448 21.6976 20.2604 21.4796 20.0755L20.2184 19.0091C20.1115 18.9196 19.9765 18.8706 19.8371 18.8706C19.6977 18.8706 19.5627 18.9196 19.4558 19.0091L18.7063 19.6364C18.6236 19.6937 18.567 19.7816 18.5491 19.8807C18.5311 19.9797 18.5532 20.0819 18.6106 20.1646C18.6373 20.2013 18.6696 20.2336 18.7063 20.2604L22.7838 23.6709C22.8899 23.7607 23.0244 23.81 23.1635 23.81C23.3025 23.81 23.437 23.7607 23.5431 23.6709L27.6503 20.2604C27.7324 20.2008 27.7878 20.1113 27.8045 20.0112C27.8212 19.9111 27.7978 19.8085 27.7395 19.7255Z" fill="#FACE09"/>
    </svg>
    </div>
  );
})`
  display: flex;
`;