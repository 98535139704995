import { styled } from '@mui/material/styles';

export const Browser = styled((props) => {
	const { className } = props;

	return (
		<div className={className}>
			<svg 
                width="71" 
                height="71" 
                viewBox="0 0 71 71" 
                fill="none" 
                style={{ height: '20px', width: '20px' }}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M46.6957 30C46.8891 31.902 47 33.7427 47 35.4656C47 38.6905 46.6114 42.3321 46.017 46.014C42.3461 46.6103 38.7153 47 35.5 47C32.2992 47 28.6695 46.6113 24.9827 46.013C24.3886 42.3311 24 38.6901 24 35.4656C24 33.7434 24.1108 31.9023 24.3046 30C28.174 30.6213 32.034 31.0292 35.5 31.0292C38.9663 31.0292 42.8263 30.6213 46.6957 30Z" fill="#7766DF"/>
                <path d="M51.4017 28.5815C51.6491 30.8741 51.793 33.1061 51.793 35.1985C51.793 38.2514 51.4871 41.6006 51 45C57.9079 43.5597 64.2928 41.7197 67.7133 40.6721C68.8185 40.3337 69.253 40.199 69.8356 39.9661C70.1268 39.8498 70.3937 39.7314 70.7418 39.5653C70.9122 38.1334 71 36.676 71 35.1985C71 31.2922 70.3868 27.5292 69.2516 24L68.9558 24.093C65.3792 25.1802 58.6838 27.1022 51.4017 28.5815Z" fill="#7766DF"/>
                <path d="M67 18.9325C63.5774 19.9781 57.6378 21.6772 51.2726 23C49.8063 14.0169 47.2365 5.05736 46 1C55.194 3.82952 62.7731 10.3907 67 18.9325Z" fill="#7766DF"/>
                <path d="M46 24.0469C42.334 24.6234 38.7099 25 35.4998 25C32.2897 25 28.6658 24.6233 25 24.047C26.4047 15.698 28.9122 7.16933 30.1945 3.09796C30.5225 2.05603 30.6531 1.64677 30.8788 1.09747C30.9915 0.823054 31.1063 0.571363 31.2672 0.243481C32.6551 0.0826928 34.0677 0 35.4998 0C36.996 0 38.4703 0.0902462 39.9179 0.265451C39.9486 0.37977 39.977 0.482069 40.005 0.578851C40.088 0.864795 40.2179 1.26667 40.4359 1.94102L40.4588 2.01186C41.5518 5.3932 44.4484 14.8243 46 24.0469Z" fill="#7766DF"/>
                <path d="M19.476 23C21.005 14.0565 23.6797 5.14518 24.9725 1.08639L25 1C15.3679 3.82949 7.42799 10.3907 3 18.9326C6.5853 19.9782 12.8077 21.6772 19.476 23Z" fill="#7766DF"/>
                <path d="M1.66113 24C0.582507 27.5293 0 31.2925 0 35.1989C0 36.7269 0.08914 38.2331 0.262257 39.7121L0.810007 39.9043L0.814376 39.9057L0.819739 39.9078L0.823512 39.9088C0.999807 39.9704 6.16683 41.7652 12.9714 43.5461C14.8915 44.0485 16.9246 44.545 19 45C18.5372 41.6009 18.2468 38.2519 18.2468 35.1989C18.2468 33.1057 18.3833 30.8736 18.6182 28.5817C11.6987 27.1018 5.33643 25.1791 1.9396 24.0921L1.91597 24.0845L1.66113 24Z" fill="#7766DF"/>
                <path d="M35.4999 51.793C38.4589 51.793 41.7052 51.4871 45 51C43.604 57.9079 41.8206 64.2928 40.8052 67.713C40.4772 68.8185 40.3466 69.2526 40.1209 69.8356C40.0082 70.1268 39.8934 70.3937 39.7325 70.7418C38.3445 70.9122 36.932 71 35.4999 71C34.0677 71 32.6552 70.9122 31.2673 70.7418C31.1063 70.3937 30.9916 70.1268 30.8789 69.8356C30.6531 69.253 30.5225 68.8185 30.1945 67.7133C29.1792 64.2931 27.3959 57.9086 26 51.0014C29.2838 51.4868 32.5307 51.793 35.4999 51.793Z" fill="#7766DF"/>
                <path d="M11.6083 48.8221C7.13454 47.7552 3.32427 46.6837 1 46C4.49858 56.9825 13.54 65.6472 25 69L24.9728 68.9149C23.8548 65.419 21.6933 58.2737 20.1548 50.6443C17.1627 50.0899 14.2538 49.4527 11.6083 48.8221Z" fill="#7766DF"/>
                <path d="M68.9149 46.0261C65.419 47.0976 58.2731 49.1691 50.6433 50.6433C49.1691 58.2731 47.0976 65.4186 46.0261 68.9149L46 69C56.9825 65.6472 65.6472 56.9825 69 46L68.9149 46.0261Z" fill="#7766DF"/>
            </svg>

		</div>
	);
})`
	display: flex;
`;