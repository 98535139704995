import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  Switch,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import SearchField from "../../../comman/SearchField";
import { TestCaseTable_TestRun } from "./TestCaseTable";
import { useSelector, useDispatch } from "react-redux";
import {
  GetBrowser_testlab,
  GetEnvironment_testlab,
} from "../../../redux/actions/seleniumAction";
import { GetTestUserList_testlab } from "../../../redux/actions/settingAction";
import {
  StyledFormControl,
  StyledOutlinedInput,
  useStyles,
} from "./styleTestCase";
import useStyle from "./styles";
import { getEnviornment_Variableset_testlab } from "../../../redux/actions/settingAction";
import { AddTestRunDetail } from "../../../redux/actions/testlab/testrunAction";

export default function CreateTestRun() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { rootId } = useParams();
  const classe = useStyle();

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const style = {
    padding: "5px 20px",
    fontSize: "12px",
    lineHeight: "18px",
    letterSpacing: "normal",
    fontFamily: `"Lexend Deca"`,
  };

  useEffect(() => {
    dispatch(GetBrowser_testlab());
    dispatch(GetEnvironment_testlab());
    dispatch(GetTestUserList_testlab());
    dispatch(getEnviornment_Variableset_testlab());
  }, [dispatch]);

  const { browserList_testlab, environementList_testlab } = useSelector(
    (state) => state.selenium
  );
  const { enviornment_variable_set_testlab, testUserList_testlab } = useSelector(
    (state) => state.settings
  );
  const { testCases } = useSelector((state) => state.testlabTestCase);
  console.log("test_user", testUserList_testlab);
  console.log("test_cases", testCases);



  const [formData, setFormData] = useState({
    rootId: rootId,
    testRunId: 0,
    testRunName: "",
    testRunDescription: "",
    browserId: "",
    environmentId: 0,
    environmentVariableId: 0,
    testUserId: 0,
    emailRecipients: 0,
    isParallelExecution: 0,
    noOfThreadsForParallelExecution: 0,
    selectedTestCases: "",
  });

  console.log("formdata", formData);

  useEffect(() => {
    const testCaseIds = selectedRows.map(row => row.TestCaseDetailsId).join(',');

    setFormData(prevFormData => ({
      ...prevFormData,
      selectedTestCases: testCaseIds,  // Update selectedTestCases with comma-separated IDs
    }));
  }, [selectedRows]);


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectChange = (selectedOption, fieldName) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: selectedOption ? selectedOption.value : "",
    }));
  };

  const handleSubmit = () => {
    dispatch(AddTestRunDetail(formData, onBack))
    console.log("Submitted Data: ", formData);
    // Add logic for form submission
  };

  const onBack = () => {
    navigate(-1);
  };

  // Handle checkbox selection
  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      setSelectedRows(testCases); // Select all test cases
      setSelectAll(true);
    } else {
      setSelectedRows([]);
      setSelectAll(false);
    }
  };

  const handleCheckboxChange = (event, row) => {
    const isSelected = selectedRows.some(selectedRow => selectedRow.TestCaseDetailsId === row.TestCaseDetailsId);

    if (isSelected) {
      setSelectedRows(selectedRows.filter(selectedRow => selectedRow.TestCaseDetailsId !== row.TestCaseDetailsId));
    } else {
      setSelectedRows([...selectedRows, row]);
    }
    // Update selectAll state
    setSelectAll(selectedRows.length + (isSelected ? 0 : 1) === testCases.length);
  };

  return (
    <div style={{ margin: 0, padding: 0 }}>
      <Grid container mt={3} justifyContent="center" sx={{ margin: 0, padding: 0 }}>
        <Card sx={{ width: "100%", padding: 2, margin: 0, minHeight: "calc(100vh - 70px)" }}>
          <Typography variant="h4" sx={{ mb: 2 }}>Create Test Run</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Box sx={{ padding: "10px" }}>
                    <div style={style}>
                      <StyledFormControl>
                        <Typography>Test Run Name</Typography>
                        <StyledOutlinedInput
                          type="text"
                          name="testRunName"
                          placeholder="Enter Test Run Name"
                          value={formData.testRunName}
                          onChange={handleInputChange}
                        />
                      </StyledFormControl>
                    </div>
                    <div style={style}>
                      <StyledFormControl>
                        <Typography>Test Run Description</Typography>
                        <StyledOutlinedInput
                          type="text"
                          name="testRunDescription"
                          placeholder="Enter Description"
                          value={formData.testRunDescription}
                          onChange={handleInputChange}
                        />
                      </StyledFormControl>
                    </div>
                    <div style={style}>
                      <Typography>Browser</Typography>
                      <Select
                        isClearable={true}
                        placeholder="Select Browser"
                        options={browserList_testlab?.map(browser => ({
                          value: browser.BrowserId,
                          label: browser.BrowserName,
                        }))}
                        onChange={(option) => handleSelectChange(option, "browserId")}
                        value={browserList_testlab?.find(options => options.value === formData.browserId)}
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            borderColor: state.isFocused ? '#654DF7' : provided.borderColor,
                            boxShadow: state.isFocused ? '0 0 0 1px #654DF7' : provided.boxShadow,
                            '&:hover': {
                              borderColor: '#654DF7',
                            },
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? '#654DF7' : provided.backgroundColor,
                            color: state.isFocused ? 'white' : provided.color,
                            '&:hover': {
                              backgroundColor: '#654DF7',
                              color: 'white',
                            },
                          }),
                        }}
                      />
                    </div>
                    <div style={style}>
                      <Typography>Environment</Typography>
                      <Select
                        isClearable={true}
                        placeholder="Select Environment"
                        options={environementList_testlab?.map(env => ({
                          value: env.EnvironmentId,
                          label: env.EnvironmentName,
                        }))}
                        onChange={(option) => handleSelectChange(option, "environmentId")}
                        value={environementList_testlab?.find(option => option.value === formData.environmentId)}
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            borderColor: state.isFocused ? '#654DF7' : provided.borderColor,
                            boxShadow: state.isFocused ? '0 0 0 1px #654DF7' : provided.boxShadow,
                            '&:hover': {
                              borderColor: '#654DF7',
                            },
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? '#654DF7' : provided.backgroundColor,
                            color: state.isFocused ? 'white' : provided.color,
                            '&:hover': {
                              backgroundColor: '#654DF7',
                              color: 'white',
                            },
                          }),
                        }}
                      />
                    </div>
                    <div style={style}>
                      <Typography>Environment Variable</Typography>
                      <Select
                        isClearable={true}
                        placeholder="Select Environment Variable"
                        options={enviornment_variable_set_testlab?.map(env => ({
                          value: env.id,
                          label: env.name,
                        }))}
                        onChange={(option) => handleSelectChange(option, "environmentVariableId")}
                        value={enviornment_variable_set_testlab?.find(option => option.value === formData.environmentVariableId)}
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            borderColor: state.isFocused ? '#654DF7' : provided.borderColor,
                            boxShadow: state.isFocused ? '0 0 0 1px #654DF7' : provided.boxShadow,
                            '&:hover': {
                              borderColor: '#654DF7',
                            },
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? '#654DF7' : provided.backgroundColor,
                            color: state.isFocused ? 'white' : provided.color,
                            '&:hover': {
                              backgroundColor: '#654DF7',
                              color: 'white',
                            },
                          }),
                        }}
                      />
                    </div>
                    <div style={style}>
                      <Grid container alignItems="center" direction="row" spacing={2}>
                        <Grid item>
                          <Typography>Parallel Execution</Typography>
                        </Grid>
                        <Grid item>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={formData.isParallelExecution === 1}
                                onChange={(e) =>
                                  setFormData(prevState => ({
                                    ...prevState,
                                    isParallelExecution: e.target.checked ? 1 : 0,
                                  }))
                                }
                              />
                            }
                          />
                        </Grid>
                        {formData.isParallelExecution === 1 && (
                          <Grid item>
                            <Select
                              isClearable={true}
                              placeholder="Select Number of Threads"
                              options={[
                                { value: 1, label: "1 Thread" },
                                { value: 2, label: "2 Threads" },
                                { value: 3, label: "3 Threads" },
                              ]}
                              onChange={(option) => handleSelectChange(option, "noOfThreadsForParallelExecution")}
                              value={
                                formData.noOfThreadsForParallelExecution
                                  ? {
                                    value: formData.noOfThreadsForParallelExecution,
                                    label: `${formData.noOfThreadsForParallelExecution} Thread${formData.noOfThreadsForParallelExecution > 1 ? 's' : ''}`,
                                  }
                                  : null
                              }
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  borderColor: state.isFocused ? '#654DF7' : provided.borderColor,
                                  boxShadow: state.isFocused ? '0 0 0 1px #654DF7' : provided.boxShadow,
                                  '&:hover': {
                                    borderColor: '#654DF7',
                                  },
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: state.isFocused ? '#654DF7' : provided.backgroundColor,
                                  color: state.isFocused ? 'white' : provided.color,
                                  '&:hover': {
                                    backgroundColor: '#654DF7',
                                    color: 'white',
                                  },
                                }),
                              }}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </div>
                    <div style={style}>
                      <Grid container alignItems="center" direction="row" spacing={2}>
                        <Grid item>
                          <Typography>Email Recipient</Typography>
                        </Grid>
                        <Grid item>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={formData.emailRecipients === 1}
                                onChange={(e) =>
                                  setFormData(prevState => ({
                                    ...prevState,
                                    emailRecipients: e.target.checked ? 1 : 0,
                                  }))
                                }
                              />
                            }
                          />
                        </Grid>
                        {formData.emailRecipients === 1 && (
                          <Grid item>
                            <RadioGroup
                              row
                              name="emailRecipient"
                              value={formData.emailRecipient}
                              onChange={(e) =>
                                setFormData(prevState => ({
                                  ...prevState,
                                  emailRecipient: e.target.value,
                                }))
                              }
                            >
                              <FormControlLabel value="me" control={<Radio />} label="Me" />
                              <FormControlLabel value="all" control={<Radio />} label="All" />
                            </RadioGroup>
                          </Grid>
                        )}
                      </Grid>
                    </div>
                    <div style={style}>
                      <Grid item xs={12}>
                        <Typography>Test User</Typography>
                        <Select
                          isClearable={true}
                          placeholder="Select Test User"
                          options={testUserList_testlab?.map(testUser => ({
                            value: testUser.UserId,
                            label: testUser.UserName,
                          }))}
                          onChange={(option) => handleSelectChange(option, "testUserId")}
                          value={testUserList_testlab?.find(options => options.value === formData.testUserId)}
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              borderColor: state.isFocused ? '#654DF7' : provided.borderColor,
                              boxShadow: state.isFocused ? '0 0 0 1px #654DF7' : provided.boxShadow,
                              '&:hover': {
                                borderColor: '#654DF7',
                              },
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isFocused ? '#654DF7' : provided.backgroundColor,
                              color: state.isFocused ? 'white' : provided.color,
                              '&:hover': {
                                backgroundColor: '#654DF7',
                                color: 'white',
                              },
                            }),
                          }}
                        />
                      </Grid>
                    </div>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card sx={{ boxShadow: 'none' }}>
                    <Grid container alignItems="center" justifyContent="space-between" sx={{ padding: "5px" }}>
                      <Typography variant="h6">Test Cases</Typography>
                      <SearchField placeholder="Search Test Cases..." onChange={(value) => console.log(value)} />
                    </Grid>
                    <div style={{ overflow: "auto", maxHeight: "calc(100vh - 223px)", width: '95%', margin: '0 auto' }}>
                      <TestCaseTable_TestRun
                        rows={testCases}
                        selectedRows={selectedRows}
                        handleSelectAllChange={handleSelectAllChange}
                        handleCheckboxChange={handleCheckboxChange}
                        selectAll={selectAll}
                      />
                    </div>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                sx={{
                  position: "absolute",
                  bottom: 25,
                  right: 30,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  width: '100%',
                  padding: '10px',
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onBack}
                  sx={{
                    backgroundColor: "white",
                    color: "#654DF7",
                    border: "1px solid #654DF7", // Add border color
                    marginRight: "10px",
                    '&:hover': {
                      backgroundColor: "white", // No color change on hover
                      border: "1px solid #654DF7", // Keep the border color on hover
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  sx={{
                    backgroundColor: "#654DF7",
                    color: "white",
                    border: "1px solid #654DF7", // Add border color
                    marginRight: "10px",
                    '&:hover': {
                      backgroundColor: "#654DF7", // No color change on hover
                      border: "1px solid #654DF7", // Keep the border color on hover
                    },
                  }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </div>
  );
}
