import React, { useState, useRef, useEffect } from "react";

const IncludePlans = ({ name }) => {
  const imageSrc = `/images/plan_icon.svg`;
  const textRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [shouldShowTooltip, setShouldShowTooltip] = useState(false);

  // Define the border color based on the provided name
  let borderColor;
  switch (name) {
    case "WebCam":
      borderColor = "#36B37E";
      break;
    case "Project & Task Report":
      borderColor = "#3F8A9A";
      break;
    case "Screenshot":
      borderColor = "#563E9A";
      break;
    case "Attendence":
      borderColor = "#B6446D";
      break;
    case "Employee Settings":
      borderColor = "#B96247";
      break;
    case "Leave Module":
      borderColor = "#007EC5";
      break;
    case "Payroll":
      borderColor = "#36B37E";
      break;
    case "Time Line LOGS":
      borderColor = "#563E9A";
      break;
    case "Work schedule":
      borderColor = "#B6446D";
      break;
    default:
      borderColor = "#b6e1e7"; // Default border color
  }

  // Style object for the parent div
  const parentDivStyle = {
    border: `1px solid ${borderColor}`,
    height: "30px",
    marginBottom: "10px",
    display: "flex",
    alignItems: "center",
    position: "relative", // Necessary for absolute positioning of the child
    overflow: "visible", // Allow image to overlap
    cursor: "pointer", // Change cursor to pointer
  };

  const dynamicImageDivStyle = {
    border: `1px solid ${borderColor}`,
    borderRadius: "50%",
    height: "40px",
    width: "40px",
    position: "absolute",
    left: "-20px", // Adjust this to position the image properly
    bottom: "-10px",
    zIndex: 100, // Higher z-index to ensure it overlaps
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const textStyle = {
    padding: "2px 11px",
    marginLeft: "20px", // Adjust this based on image position
    marginTop: "5px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  };

  // Check if text overflows
  useEffect(() => {
    if (textRef.current) {
      const isOverflowing = textRef.current.scrollWidth > textRef.current.clientWidth;
      setShouldShowTooltip(isOverflowing);
    }
  }, [name]); // Re-evaluate if the name changes

  return (
    <div
      className="plan-feature-container"
      style={parentDivStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="dynamic-image-div" style={dynamicImageDivStyle}>
        <img src={imageSrc} alt="plan icon" height={25} />
      </div>
      <div style={textStyle} ref={textRef}>
        {name}
        {isHovered && shouldShowTooltip && (
          <div
            style={{
              position: "absolute",
              backgroundColor: "black",
              color: "white",
              padding: "5px",
              borderRadius: "4px",
              bottom: "100%", // Position above the text
              left: "50%",
              transform: "translateX(-50%)",
              whiteSpace: "nowrap",
              zIndex: 200,
            }}
          >
            {name}
          </div>
        )}
      </div>
    </div>
  );
};

export default IncludePlans;
