import React from 'react'
import { styled } from '@mui/material/styles';

export const GlobalSelector = styled((props) => {
	const { className } = props;

	return (
		<div className={className}>
			<svg width="150" height="150" viewBox="0 0 150 150" fill="none" style={{ height: '20px', width: '20px' }} xmlns="http://www.w3.org/2000/svg">
                <path d="M85 75C85 80.5229 80.5229 85 75 85C69.4771 85 65 80.5229 65 75C65 69.4771 69.4771 65 75 65C80.5229 65 85 69.4771 85 75Z" fill="#7766DF"/>
                <path d="M138 75C138 109.794 109.794 138 75 138C40.2061 138 12 109.794 12 75C12 40.2061 40.2061 12 75 12C109.794 12 138 40.2061 138 75ZM30.2709 75C30.2709 99.7032 50.2968 119.729 75 119.729C99.7032 119.729 119.729 99.7032 119.729 75C119.729 50.2968 99.7032 30.2709 75 30.2709C50.2968 30.2709 30.2709 50.2968 30.2709 75Z" fill="#7766DF"/>
                <path d="M0 75C0 69.4771 4.47715 65 10 65H34C39.5228 65 44 69.4771 44 75C44 80.5229 39.5228 85 34 85H10C4.47715 85 0 80.5229 0 75Z" fill="#7766DF"/>
                <path d="M75 0C80.5229 2.41411e-07 85 4.47715 85 10V34C85 39.5228 80.5229 44 75 44C69.4771 44 65 39.5228 65 34V10C65 4.47715 69.4771 -2.41411e-07 75 0Z" fill="#7766DF"/>
                <path d="M75 106C80.5229 106 85 110.477 85 116V140C85 145.523 80.5229 150 75 150C69.4771 150 65 145.523 65 140V116C65 110.477 69.4771 106 75 106Z" fill="#7766DF"/>
                <path d="M106 75C106 69.4771 110.477 65 116 65H140C145.523 65 150 69.4771 150 75C150 80.5229 145.523 85 140 85H116C110.477 85 106 80.5229 106 75Z" fill="#7766DF"/>
            </svg>
		</div>
	);
})`
	display: flex;
`;