import { styled } from "@mui/material/styles";

export const ErrorIcon = styled((props) => {
  const { className } = props;

  return (
    <div className={className}>
    <svg width="56" height="44" viewBox="0 0 56 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.273926" width="56" height="43.726" rx="4" fill="#F64E4E" fill-opacity="0.19"/>
    <path d="M27.505 32.9172C26.1215 32.9172 24.9961 34.0446 24.9961 35.4311C24.9961 36.8176 26.1215 37.946 27.505 37.946C28.8884 37.946 30.0138 36.8186 30.0138 35.4311C30.0138 34.0456 28.8884 32.9172 27.505 32.9172ZM27.505 36.9456C26.6727 36.9456 25.9964 36.2664 25.9964 35.4311C25.9964 34.5958 26.6727 33.9176 27.505 33.9176C28.3372 33.9176 29.0135 34.5968 29.0135 35.4311C29.0135 36.2654 28.3372 36.9456 27.505 36.9456Z" fill="#F64E4E"/>
    <path d="M27.5079 14.7031C25.8533 14.7031 24.5068 15.9686 24.5078 17.5541L25.163 28.5939C25.163 29.7603 26.1684 30.7097 27.4038 30.7097C28.6392 30.7097 29.6446 29.7603 29.6426 28.6329L30.5089 17.5241C30.5089 15.9686 29.1624 14.7031 27.5079 14.7031ZM28.6442 28.5929C28.6442 29.2081 28.0881 29.7083 27.4038 29.7083C26.7196 29.7083 26.1634 29.2081 26.1624 28.5629L25.5072 17.5241C25.5072 16.5208 26.4045 15.7035 27.5079 15.7035C28.6112 15.7035 29.5085 16.5198 29.5095 17.4851L28.6442 28.5929Z" fill="#F64E4E"/>
    <path d="M12.5945 36.0104H21.4406C21.7167 36.0104 21.9408 35.7863 21.9408 35.5102C21.9408 35.2341 21.7167 35.01 21.4406 35.01H12.5945C11.6252 35.01 10.7729 34.5059 10.3147 33.6606C9.86458 32.8303 9.90359 31.8609 10.4198 31.0687L25.3339 8.17275C25.8121 7.4385 26.6254 7.00035 27.5087 7.00035C28.392 7.00035 29.2053 7.4385 29.6834 8.17275L44.5976 31.0687C45.1138 31.8609 45.1528 32.8303 44.7026 33.6606C44.2445 34.5059 43.3922 35.01 42.4228 35.01H33.5768C33.3007 35.01 33.0766 35.2341 33.0766 35.5102C33.0766 35.7863 33.3007 36.0104 33.5768 36.0104H42.4228C43.7653 36.0104 44.9457 35.3101 45.5819 34.1367C46.2101 32.9783 46.1551 31.6269 45.4359 30.5215L30.5217 7.62656C29.8585 6.60721 28.7321 6 27.5087 6C26.2853 6 25.1589 6.60821 24.4956 7.62656L9.58148 30.5225C8.86123 31.6279 8.80721 32.9793 9.43543 34.1377C10.0707 35.3101 11.2521 36.0104 12.5945 36.0104Z" fill="#F64E4E"/>
    </svg>
    </div>
  );
})`
  display: flex;
`;