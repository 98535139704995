import React from "react";
import { styled } from "@mui/material/styles";

export const GlobalTestDataIcon = styled((props) => {
  const { className } = props;

  return (
    <div className={className}>
      <svg
        width="147"
        height="127"
        viewBox="0 0 147 127"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
		    style={{ height: '18px', width: '18px' }}
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 8C0 3.58172 3.58172 0 8 0H139C143.418 0 147 3.58172 147 8V46C147 50.4183 143.418 54 139 54H8C3.58172 54 0 50.4183 0 46V8ZM127 27C127 31.4183 123.418 35 119 35C114.582 35 111 31.4183 111 27C111 22.5817 114.582 19 119 19C123.418 19 127 22.5817 127 27ZM100 35C104.418 35 108 31.4183 108 27C108 22.5817 104.418 19 100 19C95.5817 19 92 22.5817 92 27C92 31.4183 95.5817 35 100 35Z"
          fill="#7766DF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 81C0 76.5817 3.58172 73 8 73H139C143.418 73 147 76.5817 147 81V119C147 123.418 143.418 127 139 127H8C3.58172 127 0 123.418 0 119V81ZM127 100C127 104.418 123.418 108 119 108C114.582 108 111 104.418 111 100C111 95.5817 114.582 92 119 92C123.418 92 127 95.5817 127 100ZM100 108C104.418 108 108 104.418 108 100C108 95.5817 104.418 92 100 92C95.5817 92 92 95.5817 92 100C92 104.418 95.5817 108 100 108Z"
          fill="#7766DF"
        />
      </svg>
    </div>
  );
})`
  display: flex;
`;
