import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Icon,
  Button,
  Breadcrumbs,
  Chip,
  CircularProgress,
} from "@material-ui/core";
import { useStyles } from "./styles";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import Donut from "./DonutChart";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BugReportIcon from "@mui/icons-material/BugReport";
import VideoChell from "./modals/VideoChell";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  GetRunTestCaseDetails,
  GetRunTestCaseStepsDetails,
  ExecuteRun,
} from "../../../../../redux/actions/AdvanceAutomation/JourneyAction";

export default function TestSuiteResults() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { clientReferenceId, browserName } = useParams();
  const [activeRow, setActiveRow] = useState(null);
  const [playIconState, setPlayIconState] = useState("play");
  const [donutHeight, setDonutHeight] = useState("100%");
  const [donutData, setDonutData] = useState(null);
  const { userId } = useSelector((state) => state.auth);

  const testCaseDetails = useSelector(
    (state) => state.journey.runTestCaseDetails
  );

  const { runTestCaseStepsDetails } = useSelector((state) => state.journey);

  useEffect(() => {
    dispatch(GetRunTestCaseDetails(clientReferenceId, browserName));
    const height = calculateDonutHeight();
    setDonutHeight(height);
  }, [dispatch, clientReferenceId, browserName]);

  useEffect(() => {
    if (testCaseDetails) {
      setDonutData({
        series: [
          testCaseDetails.PassedTestCases,
          testCaseDetails.FailedTestCases,
        ],
        labels: ["Passed", "Failed"],
      });
    }
  }, [testCaseDetails]);

  const handleRowClick = (row) => {
    setActiveRow(row);
    dispatch(
      GetRunTestCaseStepsDetails(testCaseDetails.JourneyId, row.TestCaseName)
    );
  };

  const handlePlayClick = async () => {
    setPlayIconState("loading");
    try {
      await dispatch(ExecuteRun(testCaseDetails.RunId, testCaseDetails.JourneyId, userId));
      setPlayIconState("completed");
      console.log('Execution completed successfully');
      setTimeout(() => {
        setPlayIconState("play");
      }, 5000);
    } catch (error) {
      console.error('Error executing run:', error);
      setPlayIconState("play");
    }
  };

  const formatTimeDifference = (timeDifference) => {
    const hours = Math.floor(timeDifference / 3600000);
    const minutes = Math.floor((timeDifference % 3600000) / 60000);
    const seconds = Math.floor((timeDifference % 60000) / 1000);
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const calculateDonutHeight = () => {
    const parentContainer = document.getElementById("donut-container");
    const parentContainerHeight = parentContainer
      ? parentContainer.clientHeight
      : window.innerHeight;
    const desiredPercentage = 38;
    const calculatedHeight = `${
      (parentContainerHeight * desiredPercentage) / 100
    }px`;

    return calculatedHeight;
  };

  const iconStyle = {
    fontSize: "2rem", 
    cursor: "pointer",
    marginRight: "10px", 
    color: "rgb(101, 77, 247)",
    cursor: "pointer",
  };

  if (!testCaseDetails) {
    return <CircularProgress />;
  }

  return (
    <Grid className={classes.mainContainer}>
      {/* Header */}
      <Grid
        container
        style={{ marginBottom: "15px" }}
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid>
          <Breadcrumbs separator="›" className={classes.breadCrumbHead}>
            <Box className={classes.breadCrumbStyle}>
              {testCaseDetails.JourneyName}
            </Box>
            <Box fontSize="14px">{testCaseDetails.TestRunName}</Box>
          </Breadcrumbs>
        </Grid>
        <Grid>
          <Stack direction="row" spacing={1} alignItems="center">
            {testCaseDetails?.IsBaseline && (
              <>
                {playIconState === "play" && !testCaseDetails.IsInProgress && (
                  <PlayCircleIcon onClick={handlePlayClick} style={iconStyle} />
                )}
                {playIconState === "loading" && (
                  <CircularProgress size={32} style={iconStyle} />
                )}
                {playIconState === "completed" && (
                  <CheckCircleIcon style={iconStyle} />
                )}
              </>
            )}
            <Button
              className={classes.backBtn}
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBackIcon fontSize="small" />
              Back
            </Button>
          </Stack>
        </Grid>
      </Grid>

      {/* Main content */}
      <Grid container spacing={2}>
        {/* Left side content */}
        <Grid item xs={12} sm={7}>
          <Grid container spacing={2}>
            {/* Donut chart */}
            <Grid item xs={12} sm={6}>
              <Card
                style={{
                  background: "#f1f1f1",
                  height: "40vh",
                  padding: "10px 15px",
                }}
              >
                {donutData && (
                  <Donut
                    series={donutData.series}
                    labels={donutData.labels}
                    height={donutHeight}
                  />
                )}
              </Card>
            </Grid>

            {/* Summary cards */}
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Card style={{ height: "19vh" }}>
                    <CardContent className={classes.hederStyle}>
                      <Typography style={{ fontSize: "14px" }}>
                        Tests
                      </Typography>
                    </CardContent>
                    <CardContent>
                      <Typography variant="body1" className={classes.tbodyFont}>
                        {testCaseDetails.PassedTestCases} Tests Passed
                      </Typography>
                      <Typography variant="body1" className={classes.tbodyFont}>
                        {testCaseDetails.FailedTestCases} Tests Failed
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card style={{ height: "19vh" }}>
                    <CardContent className={classes.hederStyle}>
                      <Typography style={{ fontSize: "14px" }}>
                        Start Date, Time
                      </Typography>
                    </CardContent>
                    <CardContent>
                      <Typography variant="body1" className={classes.tbodyFont}>
                        {`${testCaseDetails.TestRunStartDate} ${testCaseDetails.TestRunStartTime}`}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card style={{ height: "19vh" }}>
                    <CardContent className={classes.hederStyle}>
                      <Typography style={{ fontSize: "14px" }}>
                        Tests
                      </Typography>
                    </CardContent>
                    <CardContent>
                      <Typography variant="body1" className={classes.tbodyFont}>
                        {testCaseDetails.TotalTestCases} Total Test Case
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ color: "#654DF7" }}
                        className={classes.tbodyFont}
                      >
                        {(
                          (testCaseDetails.PassedTestCases /
                            testCaseDetails.TotalTestCases) *
                          100
                        ).toFixed(2)}
                        %
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card style={{ height: "19vh" }}>
                    <CardContent className={classes.hederStyle}>
                      <Typography style={{ fontSize: "14px" }}>
                        Duration
                      </Typography>
                    </CardContent>
                    <CardContent>
                      <Typography variant="body1" className={classes.tbodyFont}>
                        {formatTimeDifference(
                          new Date(
                            `${testCaseDetails.TestRunEndDate}T${testCaseDetails.TestRunEndTime}`
                          ) -
                            new Date(
                              `${testCaseDetails.TestRunStartDate}T${testCaseDetails.TestRunStartTime}`
                            )
                        )}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Test case list */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <Table>
                  <TableHead>
                    <TableRow style={{ backgroundColor: "#f0f0f0" }}>
                      <TableCell>Status</TableCell>
                      <TableCell>Test Case Name</TableCell>
                      <TableCell></TableCell>
                      <TableCell>Video</TableCell>
                      <TableCell>Start Time</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {testCaseDetails &&
                    testCaseDetails.TestCaseDetailsList &&
                    testCaseDetails.TestCaseDetailsList.length > 0 ? (
                      testCaseDetails.TestCaseDetailsList.map((row, index) => (
                        <TableRow
                          key={index}
                          className={`${classes.hoverPointer} ${
                            row === activeRow ? classes.C : ""
                          }`}
                          onClick={() => handleRowClick(row)}
                        >
                          <TableCell>
                            {row.TestCaseStatus &&
                            row.TestCaseStatus.toLowerCase() === "passed" ? (
                              <Icon
                                component={CheckCircleIcon}
                                style={{ color: "#198754" }}
                              />
                            ) : (
                              <Icon
                                component={CancelIcon}
                                style={{ color: "#dc3545" }}
                              />
                            )}
                          </TableCell>
                          <TableCell className={classes.tbodyFont}>
                            {row.TestCaseName || "N/A"}
                          </TableCell>
                          <TableCell>
                            {row.TestCaseStatus &&
                              row.TestCaseStatus.toLowerCase() === "failed" && (
                                <BugReportIcon style={{ color: "#dc3545" }} />
                              )}
                          </TableCell>
                          <TableCell>
                            {row.TestCaseVideoURL && (
                              <VideoChell row={row} />
                            )}
                          </TableCell>
                          <TableCell className={classes.tbodyFont}>
                            {row.TestRunStartTime || "N/A"}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          No test case details available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        {/* Right side content */}
        <Grid item xs={12} sm={5}>
          {/* Info card */}
          <Grid item xs={12}>
            <Card style={{ height: "40vh" }}>
              <CardContent className={classes.headrRightSite}>
                <Typography className={classes.theadFont}>Info...</Typography>
              </CardContent>
              <div style={{ height: "calc(40vh - 50px)", overflowY: "auto" }}>
                <Table>
                  <TableBody>
                    {[
                      {
                        name: "Environment",
                        value: testCaseDetails.Environment,
                      },
                      {
                        name: "Browser",
                        value: testCaseDetails.BrowserName,
                      },
                      {
                        name: "Test User",
                        value: testCaseDetails.TestUser,
                      },
                      {
                        name: "Build Version",
                        value: testCaseDetails.BuildVersion,
                      },
                      {
                        name: "Release Version",
                        value: testCaseDetails.ReleaseVersion,
                      },
                      {
                        name: "Journey Name",
                        value: testCaseDetails.JourneyName,
                      },
                      {
                        name: "Run Name",
                        value: testCaseDetails.TestRunName,
                      },
                    ].map((row, index) => (
                      <TableRow key={index}>
                        <TableCell className={classes.tbodyFont}>
                          {row.name}
                        </TableCell>
                        <TableCell className={classes.tbodyFont}>
                          {row.value}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </Card>
          </Grid>

          {/* Test case steps */}
          {activeRow && (
            <Grid item xs={12}>
              <Card style={{ minHeight: "40vh", marginTop: "16px" }}>
                <CardContent className={classes.headrRightSite}>
                  <Typography>{activeRow.TestCaseName}</Typography>
                </CardContent>
                <CardContent
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    padding: "10px 0 0 0",
                  }}
                >
                  <Box textAlign="center">
                    <Typography variant="subtitle1">Start DateTime</Typography>
                    <Chip
                      label={`${activeRow.TestRunStartDate} ${activeRow.TestRunStartTime}`}
                      color="primary"
                      variant="outlined"
                      style={{ marginRight: 8, marginBottom: 8 }}
                    />
                  </Box>
                  <Box textAlign="center">
                    <Typography variant="subtitle1">End DateTime</Typography>
                    <Chip
                      label={`${activeRow.TestRunEndDate} ${activeRow.TestRunEndTime}`}
                      color="secondary"
                      variant="outlined"
                      style={{ marginRight: 8, marginBottom: 8 }}
                    />
                  </Box>
                  <Box textAlign="center">
                    <Typography variant="subtitle1">Duration</Typography>
                    <Chip
                      label={formatTimeDifference(
                        new Date(
                          `${activeRow.TestRunEndDate}T${activeRow.TestRunEndTime}`
                        ) -
                          new Date(
                            `${activeRow.TestRunStartDate}T${activeRow.TestRunStartTime}`
                          )
                      )}
                      color="default"
                      variant="outlined"
                      style={{ marginBottom: 8 }}
                    />
                  </Box>
                </CardContent>
                <hr />
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Step</TableCell>
                      <TableCell>Expected Result</TableCell>
                      <TableCell>Actual Result</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {runTestCaseStepsDetails &&
                      runTestCaseStepsDetails.length > 0 &&
                      runTestCaseStepsDetails.map((item, index) => {
                        const steps = JSON.parse(item.TestCaseSteps);
                        return steps.actions.map((action, actionIndex) => (
                          <TableRow key={`${index}-${actionIndex}`}>
                            <TableCell>{action.step}</TableCell>
                            <TableCell>{action.name}</TableCell>
                            <TableCell>{action.result}</TableCell>
                          </TableRow>
                        ));
                      })}
                  </TableBody>
                </Table>
              </Card>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
