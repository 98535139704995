import React from 'react'
import { styled } from '@mui/material/styles';

export const HelpIcon = styled((props) => {
	const { className } = props;

	return (
		<div className={className}>
			 <svg
        width="45"
        height="42"
        viewBox="0 0 45 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ height: '30px', width: '30px' }}
      >
        <ellipse
          cx="22.5"
          cy="21.0021"
          rx="22"
          ry="20.5333"
          fill="#654DF7"
          fill-opacity="0.8"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M25.7992 16.535C26.2471 16.9991 26.4105 17.6512 26.3335 18.2817C26.222 19.1968 25.9579 20.0537 25.648 20.9175C25.3164 21.8418 24.9799 22.7641 24.6417 23.686C24.1007 25.161 23.5554 26.6344 23.0204 28.1116C22.8875 28.4785 22.7926 28.8703 22.7548 29.2579C22.7135 29.6799 23.0171 29.8876 23.3983 29.7004C23.7035 29.5503 24.0007 29.346 24.2397 29.1043C24.6302 28.7094 25.0191 28.3126 25.3984 27.9068C25.5881 27.7038 25.7756 27.4986 25.9596 27.2903C26.1062 27.1243 26.2568 26.8745 26.458 26.7735C26.839 26.5824 27.133 27.0528 27.064 27.3821C26.9981 27.6971 26.7812 27.9735 26.5817 28.2249L26.5679 28.2423C26.5498 28.265 26.5318 28.2878 26.5138 28.3107C25.8432 29.1638 25.1077 29.9862 24.2734 30.6815C23.4532 31.3632 22.5426 31.9431 21.5426 32.3191C21.2169 32.4415 20.8831 32.5422 20.5439 32.6193C19.889 32.7683 19.1652 32.7572 18.4959 32.66C18.0052 32.5888 17.564 32.2758 17.356 31.8203C16.9949 31.0295 17.2463 30.053 17.433 29.2481C17.6617 28.2627 18.005 27.3245 18.3557 26.3805L18.449 26.1294C18.8554 25.0353 19.2732 23.9456 19.6923 22.8564C20.0748 21.8627 20.4589 20.8698 20.8374 19.8747C20.8663 19.7988 20.8986 19.7233 20.9312 19.6479L20.9434 19.6197C21.0269 19.4265 21.11 19.2332 21.1411 19.0319C21.2157 18.5486 20.8672 18.1184 20.3835 18.397C19.5472 18.8785 18.921 19.5946 18.3004 20.3152L18.2249 20.4028C18.032 20.6267 17.8389 20.8502 17.6394 21.0663C17.4427 21.2793 17.2086 21.5434 16.8705 21.2994C16.5097 21.0389 16.6167 20.7049 16.8218 20.422C17.9606 18.8505 19.328 17.3804 21.11 16.5502C21.8548 16.2033 22.8959 15.8501 23.8708 15.8385C24.6017 15.8298 25.2955 16.0131 25.7992 16.535ZM26.9241 9.44684C27.8616 9.81162 28.3668 10.6267 28.3667 11.7742C28.3666 13.842 26.133 15.2564 24.2658 14.3708C23.3008 13.9132 22.8314 12.8284 23.0653 11.5964C23.3891 9.89016 25.3115 8.81943 26.9241 9.44684Z"
          fill="white"
        />
      </svg>
		</div>
	);
})`
	display: flex;
`;