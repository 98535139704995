import { makeStyles } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

export const useTableStyles = makeStyles((theme) => ({
  tableRow: {
    cursor: "pointer",
    "&:hover": {
      border: "2px solid #654DF7",
      backgroundColor: theme.palette.action.hover,
      cursor: "pointer",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.action.selected,
    },
    height: "40px",
  },
  activeRow: {
    border: "2px solid #654DF7",
  },
}));

export const StyledTableCell = styled(TableCell)(({ theme, isFirstColumn, isLastColumn }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(242, 242, 242)",
    color: "#5c5c5c",
    padding: "10px 20px",
    fontFamily: "Lexend Deca",
    fontSize: "12px",
    borderTop: "1px solid rgb(217, 217, 217)",
    lineHeight: "18px",
    borderLeft: isFirstColumn ? "1px solid rgb(217, 217, 217)" : undefined,
    borderRight: isLastColumn ? "1px solid rgb(217, 217, 217)" : undefined,
  },
  [`&.${tableCellClasses.body}`]: {
    padding: "10px 20px",
    fontSize: "12px",
    lineHeight: "18px",
    letterSpacing: "normal",
    fontFamily: `"Lexend Deca"`,
    borderLeft: isFirstColumn ? "1px solid rgb(217, 217, 217)" : undefined,
    borderRight: isLastColumn ? "1px solid rgb(217, 217, 217)" : undefined,
  },
}));
