import axios from "axios";
import { toast } from "react-toastify";
import { header } from "../../utils/authheader";
import { getBaseUrl } from "../../utils/configService";
export const GET_TEST_SUITS = "GET_TEST_SUITS";
export const GET_TEST_SUITS_LIST = "GET_TEST_SUITS_LIST";
export const GET_TEST_RUN_DETAILS_LIST = "GET_TEST_RUN_DETAILS_LIST";
export const GET_TEST_CASE_DETAILS = "GET_TEST_CASE_DETAILS";
export const GET_TEST_CASE_STESPS = "GET_TEST_CASE_STESPS";
export const GET_APPLICATION_LIST = "GET_APPLICATION_LIST";
export const GET_APPLICATION_LIST_TESTLAB = "GET_APPLICATION_LIST_TESTLAB";

export const GET_ENVIRONMENT_LIST = "GET_ENVIRONMENT_LIST";
export const GET_ENVIRONMENT_LIST_TESTLAB = "GET_ENVIRONMENT_LIST_TESTLAB";

export const GET_TEST_USER_LIST = "GET_TEST_USER_LIST";
export const GET_BROWSER_LIST = "GET_BROWSER_LIST";
export const GET_BROWSER_LIST_TESTLAB = "GET_BROWSER_LIST_TESTLAB";

export const GET_TEST_CASE_LIST = "GET_TESTCASE_LIST";
export const ADD_UPDATE_TEST_SUITS = "ADD_UPDATE_TEST_SUITS";
export const SUITE_TO_EDIT = "SUITE_TO_EDIT";
export const ADD_TEST_SUITE = "ADD_TEST_SUITE";
export const EXECUTING_SUITE = "EXECUTING_SUITE";
export const SELECETED_SUITE = "SELECETED_SUITE";
export const SELECETED_TAB = "SELECETED_TAB";
export const EXPANDED_ACC = "EXPANDED_ACC";
export const GET_TESTCASE_BY_APPLICATION = "GET_TESTCASE_BY_APPLICATION";
export const RESET_TEST_RUN_DETAILS_LIST = "RESET_TEST_RUN_DETAILS_LIST";

export const getTestSuites = () => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/Selenium/GetDataTestSuits`,
        header()
      );
      dispatch({
        type: GET_TEST_SUITS,
        payload: response.data,
      });
    } catch (error) {
      toast.error("NETWORK ERROR");
    }
  };
};

export const getListByTestSuitsName = (data) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/Selenium/GetDashboardDetails?testSuitName=${data}`,
        header()
      );
      dispatch({
        type: GET_TEST_SUITS_LIST,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      toast.error("NETWORK ERROR");
    }
  };
};

export const getTestCaseRundetailsByTestName = (data, setInProgress) => {
  return async (dispatch) => {
    setInProgress(true);
    dispatch({
      type: RESET_TEST_RUN_DETAILS_LIST,
    });
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/Selenium/GetRunDetails?testSuitName=${data.suiteName}&RootId=${data.rootId}`,
        header()
      );
      dispatch({
        type: GET_TEST_RUN_DETAILS_LIST,
        payload: response.data,
      });
      setInProgress(false);
    } catch (error) {
      setInProgress(false);
      console.error(error);
      // toast.error("NETWORK ERROR");
    }
  };
};

export const ExecuteTestCasesByTestSuite = (data) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.post(
        `${BASE_URL}/Selenium/ExecuteTestSuite`,
        // `${BASE_URL}/Selenium/ExecuteTestSuite`,
        data,
        header()
      );

      if (Array.isArray(response.data)) {
        const failedItem = response.data.find(
          (item) => item?.status === "Failed"
        );
        if (failedItem) {
          toast.error(failedItem.message);
        } else {
          const finishedItem = response.data.find(
            (item) => item?.status === "Finished"
          );
          if (finishedItem) {
            toast.info(finishedItem.message, {
              style: {
                background: "rgb(101, 77, 247)",
                color: "rgb(255, 255, 255)",
              },
            });
          }
        }
      } else if (response.data?.status === "Conflict") {
        toast.error(response.data.message);
      }

      dispatch({
        type: EXECUTING_SUITE,
        payload: null,
      });
      dispatch({
        type: ADD_TEST_SUITE,
        payload: {},
      });
    } catch (error) {
      console.error(error);
      toast.error("NETWORK ERROR");
    }
  };
};

export const GetTestCaseDetails = (data, setLoading) => {
  return async (dispatch) => {
    setLoading(true);
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/Selenium/GetTestCaseDetails?testSuitName=${
          data.testSuitName
        }&runId=${data.runId}&RootId=${
          data.RootId !== undefined ? data.RootId : 0
        }`,
        header()
      );
      dispatch({
        type: GET_TEST_CASE_DETAILS,
        payload: response.data,
      });
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.error("NETWORK ERROR");
      setLoading(false);
    }
  };
};

export const GetTestCaseStepsDetails = (data) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/Selenium/GetTestCaseStepsDetails?testSuitName=${data.testSuitName}&runId=${data.runId}&testCaseName=${data.testCaseName}&RootId=${data.RootId}&testCaseName=${data.testCaseName}`,
        header()
      );
      dispatch({
        type: GET_TEST_CASE_STESPS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const GetApplication = () => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/Selenium/GetApplication`,
        header()
      );

      dispatch({
        type: GET_APPLICATION_LIST,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      toast.error("NETWORK ERROR");
    }
  };
};

export const GetApplication_testlab = () => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/TestLabSetting/GetTestLabApplicationList`,
        header()
      );

      dispatch({
        type: GET_APPLICATION_LIST_TESTLAB,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      toast.error("NETWORK ERROR");
    }
  };
};

export const GetTestCaseByApplicationId = (id) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/Selenium/GetTestCaseDetailsByApplicationId?ApplicationId=${id}`,
        header()
      );
      let data;
      if (
        response.data.message === "Test Case not found" &&
        response.data.status === "fail"
      ) {
        data = [];
      } else {
        data = response.data;
      }
      dispatch({
        type: GET_TESTCASE_BY_APPLICATION,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const GetEnvironment = () => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/Selenium/GetEnvironments`,
        header()
      );
      dispatch({
        type: GET_ENVIRONMENT_LIST,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      toast.error("NETWORK ERROR");
    }
  };
};

export const GetEnvironment_testlab = () => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/TestLabSetting/GetTestLabEnvironmentList`,
        header()
      );
      dispatch({
        type: GET_ENVIRONMENT_LIST_TESTLAB,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      toast.error("NETWORK ERROR");
    }
  };
};


export const GetTestUser = () => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/Selenium/GetAllTestUser`,
        header()
      );
      dispatch({
        type: GET_TEST_USER_LIST,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      toast.error("NETWORK ERROR");
    }
  };
};

export const GetBrowser = () => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/Selenium/GetBrowsers`,
        header()
      );
      dispatch({
        type: GET_BROWSER_LIST,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      toast.error("NETWORK ERROR");
    }
  };
};

export const GetBrowser_testlab = () => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/TestLabSetting/GetTestLabBrowserList`,
        header()
      );
      dispatch({
        type: GET_BROWSER_LIST_TESTLAB,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      toast.error("NETWORK ERROR");
    }
  };
};

export const GetTestCases = () => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/Selenium/GetTestCases`,
        header()
      );
      dispatch({
        type: GET_TEST_CASE_LIST,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const AddUpdateTestSuites = (
  data,
  action,
  handleLoading,
  setIsSubmitting
) => {
  setIsSubmitting(true);
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/Selenium/AddUpdateTestSuites?action=${action}`,
        data,
        header()
      );
      if (res.data.status === "success") {
        handleLoading("pass");
        let resData = {
          rootId: data.rootId,
          actionType: res?.data?.data?.actionType,
          testSuiteName: res?.data?.data?.testSuiteName,
        };
        dispatch({
          type: ADD_TEST_SUITE,
          payload: resData,
        });
        dispatch({
          type: GET_TESTCASE_BY_APPLICATION,
          payload: [],
        });
        setIsSubmitting(false);
        toast.info(res.data.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      }
    } catch (error) {
      handleLoading("error");
      toast.error(error.response.data.message);
      console.log({ error: error.response });
      setIsSubmitting(false);
    }
  };
};

export const setExecutingSuite = (suiteName) => {
  return {
    type: EXECUTING_SUITE,
    payload: suiteName,
  };
};
export const setSelectedSuite = (data) => {
  return {
    type: SELECETED_SUITE,
    payload: data,
  };
};

export const setSelectedTab = (tabNo) => {
  return {
    type: SELECETED_TAB,
    payload: tabNo,
  };
};

export const setExpandedAccord = (acc) => {
  return {
    type: EXPANDED_ACC,
    payload: acc,
  };
};
export const Getsuitebyname = (suitName, rootid) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.get(
        `${BASE_URL}/Selenium/GetTestSuiteByName?TestSuiteName=${suitName}&RootId=${rootid}`,
        header()
      );
      dispatch({
        type: SUITE_TO_EDIT,
        payload: res.data,
      });
    } catch (error) {
      console.log("error getting suite by name ", error);
    }
  };
};

export const DeleteTestSuite = (suiteName) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/Selenium/DeleteTestSuites?TestSuiteName=${suiteName}`,
        suiteName,
        header()
      );
      if (res.status === 200) {
        dispatch(getTestSuites());
        toast.info("Successfully deleted", {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      }
    } catch (error) {
      console.log("error deleting ", error);
      toast("deleting error");
    }
  };
};
