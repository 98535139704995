import React, { useState, useEffect } from "react";
import { useStyles } from "./styles";
import clsx from "clsx";
import {
  Button,
  FormControl,
  Grid,
  Typography,
  OutlinedInput,
  Box,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  Card,
  Avatar,
  Switch,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getUserId } from "../../../../../redux/actions/authActions";
import {
  GetEmailScheduler,
  scheduleEmailReports,
} from "../../../../../redux/actions/settingAction";
import { Edit as EditIcon } from "@material-ui/icons";
import { EmailReportSummariesInside } from "../../../../../comman/icons";
import { toast } from "react-toastify";

export default function EmailReport() {
  const classes = useStyles();
  const [selectedOption, setSelectedOption] = useState(""); // Initialize to empty
  const [radioValue, setRadioValue] = useState("onlyMe");
  const [showForm, setShowForm] = useState(false);
  const [switchState, setSwitchState] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [currentId, setCurrentId] = useState(0);
  const dispatch = useDispatch();
  const { emailSchediler } = useSelector((state) => state.settings);
  const { userId } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getUserId());
  }, [dispatch]);

  useEffect(() => {
    if (userId) {
      dispatch(GetEmailScheduler(userId));
    }
  }, [userId, dispatch]);

  useEffect(() => {
    if (emailSchediler) {
      const emailSettings = emailSchediler;
      setSwitchState(emailSettings.IsEmailSummaryEnabled);
      setSelectedOption(emailSettings.Frequency || ""); // Use default if undefined
      setRadioValue(emailSettings.IsOnlyForMe ? "onlyMe" : "allUsers");
      setCurrentId(emailSettings.Id);
    }
  }, [emailSchediler]);

  const frequencyOptions = [
    { value: "Daily", label: "Daily" },
    { value: "Weekly", label: "Weekly" },
    { value: "BiWeekly", label: "Bi-Weekly" },
    { value: "Monthly", label: "Monthly" },
  ];

  const handleFrequencyChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  const resetFormData = () => {
    setSelectedOption("");
    setRadioValue("onlyMe");
  };

  const handleSave = () => {
    if (!selectedOption || selectedOption.trim() === "") {
      toast.warn("Please select a frequency before saving.");
      return;
    }

    const email = localStorage.getItem("email");
    const currentDate = new Date();
    const formattedDateTime = currentDate.toISOString();
    let payload = {
      id: currentId,
      userId: userId,
      userEmail: email,
      isEmailSummaryEnabled: switchState,
      isOnlyForMe: radioValue === "onlyMe",
      frequency: selectedOption,
      startDateTime: formattedDateTime,
    };

    dispatch(
      scheduleEmailReports(payload, setShowForm, setSwitchState, (success) => {
        if (success) {
          resetFormData();
        }
      })
    );
  };

  const handleCancel = () => {
    setSwitchState(emailSchediler?.IsEmailSummaryEnabled || false);
    setShowForm(false);
  };

  const toggleFormVisibility = () => {
    setSelectedCard("emailReport");
    setShowForm(!showForm);
  };

  const handleToggleChange = () => {
    const newState = !switchState;
    setSwitchState(newState);

    if (!newState) {
      const email = localStorage.getItem("email");
      const currentDate = new Date();
      const formattedDateTime = currentDate.toISOString();
      const payload = {
        id: currentId,
        userId: userId,
        userEmail: email,
        isEmailSummaryEnabled: false,
        isOnlyForMe: radioValue === "onlyMe",
        frequency: selectedOption,
        startDateTime: formattedDateTime,
      };

      dispatch(
        scheduleEmailReports(payload, setShowForm, (success) => {
          if (success) {
            resetFormData();
          }
        })
      );
    }
    setShowForm(newState);
  };

  return (
    <>
      <Grid
        container
        className={classes.main}
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={6} className={classes.header}>
          <div className={classes.highlight}>
            Schedule the Email Report Summary
          </div>
        </Grid>
      </Grid>

      {!showForm && (
        <Grid item xs={12} sm={6} md={4} className={classes.cardGrid}>
          <Card className={classes.card}>
            <div className={classes.cardHeader}>
              <div className={classes.logoAndTitle}>
                <Avatar
                  sx={{
                    bgcolor: "#fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className={classes.logo}
                >
                  <EmailReportSummariesInside className={classes.svgIcon} />
                </Avatar>
                <Typography variant="h6" component="div">
                  Email Report
                </Typography>
              </div>

              <div>
                {switchState && emailSchediler?.IsEmailSummaryEnabled && (
                  <EditIcon
                    onClick={toggleFormVisibility}
                    style={{
                      cursor: "pointer",
                      marginRight: "10px",
                      color: "rgb(101, 77, 247)",
                    }}
                  />
                )}
                <FormControlLabel
                  control={
                    <Switch
                      checked={switchState}
                      onChange={handleToggleChange}
                      color="primary"
                      style={{
                        cursor: "pointer",
                        marginRight: "10px",
                        color: "rgb(101, 77, 247)",
                      }}
                    />
                  }
                  label=""
                />
              </div>
            </div>
          </Card>
        </Grid>
      )}

      {showForm && (
        <>
          {/* Body */}
          <Grid container style={{ padding: "10px" }}>
            <Grid container>
              {/* Frequency Field */}
              <Grid container xs={6}>
                <Grid item xs={3} style={{ padding: "5px" }}>
                  <Typography
                    variant="subtitle1"
                    className={clsx(classes.customFontSize, classes.label)}
                  >
                    Frequency
                  </Typography>
                </Grid>
                <Grid item xs={9} style={{ padding: "5px" }}>
                  <FormControl fullWidth>
                    <Select
                      value={selectedOption}
                      onChange={handleFrequencyChange}
                      displayEmpty
                      input={
                        <OutlinedInput
                          sx={{
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#654DF7",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#654DF7",
                            },
                            "&.Mui-focused": {
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#654DF7",
                              },
                            },
                          }}
                        />
                      }
                    >
                      <MenuItem value="" disabled>
                        Select Frequency
                      </MenuItem>
                      {frequencyOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              {/* Email Recipient Field */}
              <Grid container xs={6}>
                <Grid item xs={3} style={{ padding: "5px" }}>
                  <Typography
                    variant="subtitle1"
                    className={clsx(classes.customFontSize, classes.label)}
                  >
                    Email Recipient
                  </Typography>
                </Grid>
                <Grid item xs={9} style={{ padding: "5px" }}>
                  <FormControl
                    component="fieldset"
                    className={classes.radioGroup}
                  >
                    <RadioGroup
                      aria-label="options"
                      name="options"
                      value={radioValue}
                      onChange={handleRadioChange}
                      row
                    >
                      <FormControlLabel
                        value="onlyMe"
                        control={<Radio style={{ color: "#654DF7" }} />}
                        label={
                          <Typography
                            variant="body1"
                            className={classes.radioButtonLabel}
                          >
                            Only for me
                          </Typography>
                        }
                        className={clsx(
                          classes.radioLabel,
                          classes.customFontSize
                        )}
                      />
                      <FormControlLabel
                        value="allUsers"
                        control={<Radio style={{ color: "#654DF7" }} />}
                        label={
                          <Typography
                            variant="body1"
                            className={classes.radioButtonLabel}
                          >
                            All users
                          </Typography>
                        }
                        className={classes.radioLabel}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/* Button Container */}
            {/* Footer */}
            <Grid container>
              <Grid item xs={12}>
                <Box className={classes.ButtonContainer}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    sx={{
                      borderColor: "#654DF7",
                      color: "#654DF7",
                      "&:hover": {
                        backgroundColor: "rgba(101, 77, 247, 0.08)",
                        borderColor: "#654DF7",
                      },
                    }}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    sx={{
                      backgroundColor: "rgb(101, 77, 247)",
                      "&:hover": {
                        backgroundColor: "rgb(101, 77, 247)",
                        borderColor: "#654DF7",
                      },
                    }}
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
