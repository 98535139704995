import React, { useState, useRef, useEffect } from "react";
import clsx from "clsx";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  Grid,
  Modal,
  Button,
  MenuItem,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuList,
  Drawer,
  Card,
} from "@material-ui/core/";
import { Avatar } from "@mui/material";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";

// Redux import
import { useDispatch, useSelector } from "react-redux";
import { getUserId, logout } from "../redux/actions/authActions";

import { useStyles } from "./styles";
import Navigations from "../Routes/Navigations";
import { LogoutIcon, UserIcon,HelpIcon } from "../comman/icons";
import SettingsIcon from "@mui/icons-material/Settings";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";

import { Box } from "@mui/material";
import { fetchOrganizationDetail } from "../redux/actions/userActions";
import NavBar from "./NavBar";
import { getCustomerSubscriptionDetails } from "../redux/actions/authActions";

export default function MiniDrawer() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const anchorRef = useRef(null);
  const [menustate] = useState(true);
  const [showmodel, setshowmodel] = useState(false);
  const [showmodel_subs, setshowmodel_subs] = useState(false);

  const [showMenu, setShowMenu] = useState(false);

  const imageSrc = `/images/LOGO_GHOST.svg`;




  // const userData = useSelector((store) => store.auth.userData);
  const { userData, userId, subscriptionDetail  } = useSelector((store) => store.auth);
  const { organizationDetails } = useSelector((state) => state.user);
  const getName = () => {
    const email = localStorage?.getItem("email");
    const i = email?.indexOf("@");
    const name = email?.substring(0, i);
    return name?.charAt(0)?.toUpperCase() + name?.slice(1);
  };

  useEffect(() => {
    dispatch(getUserId());
    dispatch(getCustomerSubscriptionDetails());

  }, []);
 
  useEffect(() => {
    if(subscriptionDetail?.remainingDaysInfo?.days_remaining<1)
    {
    setshowmodel_subs(true);
    console.log("useeffect");
    }
    
    

  },[subscriptionDetail]);
  
  useEffect(() => {
    if (userId) dispatch(fetchOrganizationDetail(userId));
  }, [userId]);
  // to logout when token expired
  useEffect(() => {
    const expiry = JSON.parse(localStorage.getItem("tokenExpiry"));
    const expiryTime = new Date(expiry?.expiration).getTime();
    const checkTokenExpiry = () => {
      const currentTime = new Date().getTime();

      if (expiryTime && currentTime > expiryTime) {
        // Token has expired, initiate logout
        alert("Login session expired");
        handleLogout();
      }
    };

    const interval = setInterval(checkTokenExpiry, 1000); // Check every second
    return () => clearInterval(interval);
  });
  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem("userData");
    localStorage.removeItem("tokenExpiry");
    navigate("/");
  };
  const handleMouseOver = () => {
    setShowMenu(true);
  };

  const handleMouseOut = () => {
    setShowMenu(false);
  };
  const isActive =
    location.pathname === "/" ||
    location.pathname.startsWith("/ghostLab") ||
    location.pathname.startsWith("/GhostAI") ||
    location.pathname.startsWith("/testcase") ||
    location.pathname.startsWith("/ghost-ready");

  return (
    <div className={classes.root}>
      <CssBaseline />

      {/* Header Bar ...........  */}
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: menustate,
        })}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Box style={{ paddingTop: "8px" }}>
                <img src={"/images/Logo_GhostQA.svg"} alt="logo" />{" "}
              </Box>
            </Grid>
            <Grid item>
              <Link
                to="/"
                className={`${classes.linkStyle} ${
                  isActive && classes.activeLink
                }`}
              >
                Functional
              </Link>
            </Grid>

            <Grid item>
              <Link
                to="/performance"
                className={`${classes.linkStyle} ${
                  (location.pathname === "/performance" || location.pathname.startsWith('/result')) && classes.activeLink
                }`}
              >
                Performance
              </Link>
            </Grid>
          </Grid>

          {/* <Grid container justifyContent="flex-end" spacing={2} style={{border:'1px solid red'}}>
            <Grid item> */}
            <Box
              sx={{ marginRight: "5px", cursor: "pointer" }}
              onClick={() => window.open("https://www.ghostqa.com/docs/", "_blank")}
            >
              <HelpIcon />
            </Box>
          <Box
            sx={{ display: "flex", alignItems: "center" }}
            ref={anchorRef}
            aria-controls={showMenu ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={setShowMenu}
          >
          
            <Box sx={{ marginRight: "5px" }}>
              <Avatar
                sx={{ m: 1, bgcolor: "#654DF7" }}
                src={organizationDetails ? organizationDetails.LogoPath : ""}
              />
            </Box>
            <Box>
              <Box
                style={{
                  paddingTop: "5px",
                  alignItems: "flex-start",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  width: "max-content",
                }}
              >
                <Typography
                  className={classes.profileEmail}
                  ref={anchorRef}
                  aria-controls={showMenu ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                >
                  {getName()}
                  {showMenu ? <ArrowDropUp /> : <ArrowDropDown />}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Popper
            open={showMenu}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            className={classes.mainPoper}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                >
                  <ClickAwayListener onClickAway={() => setShowMenu(false)}>
                    <MenuList
                      autoFocusItem={showMenu}
                      id="menu-list-grow"
                      onKeyDown={() => setShowMenu(false)}
                      className={classes.customMenuList}
                    >
                      {/* <MenuItem
                            onClick={() => {
                              setShowMenu(false);
                              navigate("/myaccount");
                            }}
                          >
                            <UserIcon />
                            <span style={{ marginLeft: "10px" }}>
                              My Account
                            </span>
                          </MenuItem> */}
                      <MenuItem
                        onClick={() => {
                          setShowMenu(false);
                          navigate("/settings");
                        }}
                      >
                        <SettingsIcon style={{ color: "rgb(115, 115, 115" }} />
                        <span style={{ marginLeft: "10px" }}>Settings</span>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setShowMenu(false);
                          navigate("/subscriptions");
                        }}
                      >
                        <SubscriptionsIcon style={{ color: "rgb(115, 115, 115" }} />
                        <span style={{ marginLeft: "10px" }}>Subscription</span>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setShowMenu(false);
                          setshowmodel(true);
                        }}
                      >
                        <LogoutIcon />
                        <span style={{ marginLeft: "10px" }}>Log out</span>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          {/* </Grid>
          </Grid> */}
        </Toolbar>
      </AppBar>

      {/* Left Drawer & Nav Bar ..............  */}

      {/* {isActive && (
        <Card className={clsx(classes.drawer, classes.navBarCustom)}>
          <NavBar />
        </Card>
      )} */}

      {isActive && (
        <div className={classes.drawerWrapper}>
          <Card className={clsx(classes.drawer, classes.navBarCustom)}>
            <NavBar />
          </Card>
        </div>
      )}

      {/* pages ............ */}
      {/* <main className={classes.content}>
        <div className={classes.toolbar} />
        <Navigations />
      </main> */}

      <main
        className={classes.content}
        style={{
          marginLeft: isActive ? "100px" : 0,
          width: isActive ? `calc(100% - ${100}px)` : "100%",
        }}
      >
        <div className={classes.toolbar} />
        <Navigations />
      </main>

      {/* model */}
      <Modal
        open={showmodel}
        onClose={() => setshowmodel(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.modelLogout}>
          <p id="simple-modal-description" className={classes.modelPara}>
            Hi,{userData.u_first_name} are you sure you want to logout?
          </p>
          <Grid
            container
            className={classes.buttonMain}
            justifyContent="center"
          >
            <Grid item>
              <Button
                className={classes.buttonyes}
                variant="contained"
                size="large"
                style={{
                  marginRight: "10px",
                  backgroundColor: "#654DF7",
                  height: "40px",
                  width: "120px",
                }}
                color="primary"
                onClick={() => handleLogout()}
              >
                YES
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.buttonNo}
                variant="contained"
                size="large"
                style={{
                  backgroundColor: "#6c757d",
                  width: "120px",
                  height: "40px",
                }}
                color="primary"
                onClick={() => setshowmodel(false)}
              >
                NO
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
      <Modal
        open={showmodel_subs}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.model_plan}>
        <Box className={classes.warning_box}> 
          Warning
  
         </Box>
         <Box
  sx={{
    display: 'flex',       // Enables flexbox
    justifyContent: 'center', // Centers content horizontally
    alignItems: 'center',  // Centers content vertically (optional)
    height: '100%', 
    marginTop:'20px',
    border:'0px solid blue', 
    paddingTop:'30px',
    borderTop:'2px solid #E8E8E8' ,     // Adjust as needed to center vertically as well
  }}
>
  <img src={imageSrc} alt="" height={100} />
</Box >
<Box  sx={{
    display: 'flex',       // Enables flexbox
    justifyContent: 'center', // Centers content horizontally
    alignItems: 'center',  // Centers content vertically (optional)
    marginTop:'30px',
    border:'0px solid blue',
    flexDirection:'column', 
    fontWeight:'500',      // Adjust as needed to center vertically as well
  }}>
<Typography
  style={{
    fontSize: '25px', // Replace with your desired font size
  }}
>
  Subscription Ended: Renew your Plan to regain Access
</Typography>


</Box>
<Box  sx={{
    display: 'flex',       // Enables flexbox
    justifyContent: 'center', // Centers content horizontally
    alignItems: 'center',  // Centers content vertically (optional)
    marginTop:'30px',
    border:'0px solid blue',
    flexDirection:'column',       // Adjust as needed to center vertically as well
  }}>
    <Typography sx={{marginTop:'20px'}}>
 Your free trail has been expired and your free services has been paused. 
 </Typography>
 <Typography  >
 Click below to resume your services. 
 </Typography>
  </Box>
          <Grid
            container
            className={classes.buttonMain}
            justifyContent="center"
          >
            <Grid item>
              <Button
                className={classes.buttonyes}
                variant="contained"
                size="large"
                style={{
                  marginRight: "10px",
                  backgroundColor: "#654DF7",
                  height: "40px",
                  width: "120px",
                }}
                color="primary"
                onClick={() => window.location.href = 'https://www.ghostqa.com/pricing/'}

              >
                Upgrade
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.buttonNo}
                variant="contained"
                size="large"
                style={{
                  backgroundColor: "#6c757d",
                  width: "120px",
                  height: "40px",
                }}
                color="primary"
                onClick={() => handleLogout()}
              >
                Logout
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}
