import React from 'react'
import { styled } from '@mui/material/styles';

export const ReleaseVersion = styled((props) => {
	const { className } = props;

	return (
		<div className={className}>
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" style={{ height: '20px', width: '20px' }} xmlns="http://www.w3.org/2000/svg">
                <path d="M19.6812 17.715V6.48366C19.6812 5.66749 19.0196 5.00586 18.2034 5.00586H16.5778M1.35645 17.715V6.48366C1.35645 5.66749 2.01808 5.00586 2.83425 5.00586H11.11" stroke="#7766DF" stroke-width="1.6"/>
                <mask id="path-2-inside-1_6043_1260" fill="white">
                <path d="M0.173828 18.1286C0.173828 17.9001 0.359086 17.7148 0.587614 17.7148H20.4493C20.6778 17.7148 20.8631 17.9001 20.8631 18.1286V18.1286C20.8631 19.0427 20.1221 19.7838 19.2079 19.7838H1.82897C0.914859 19.7838 0.173828 19.0427 0.173828 18.1286V18.1286Z"/>
                </mask>
                <path d="M0.173828 18.1286C0.173828 17.9001 0.359086 17.7148 0.587614 17.7148H20.4493C20.6778 17.7148 20.8631 17.9001 20.8631 18.1286V18.1286C20.8631 19.0427 20.1221 19.7838 19.2079 19.7838H1.82897C0.914859 19.7838 0.173828 19.0427 0.173828 18.1286V18.1286Z" stroke="#7766DF" stroke-width="2.06893" mask="url(#path-2-inside-1_6043_1260)"/>
                <path d="M10.5565 7.61089C11.3322 6.24857 12.3544 4.96523 13.8675 3.40317L16.1043 4.69455C15.4914 6.75721 14.9043 8.28401 14.1233 9.64436C13.344 11.0019 12.3667 12.2021 10.9644 13.6057L8.72294 12.3116C9.24917 10.4164 9.78262 8.97 10.5565 7.61089Z" stroke="#7766DF" stroke-width="0.4"/>
                <path d="M8.59185 12.357L8.55103 12.5059L8.68481 12.5831L8.70435 12.5944L6.38107 12.6914C7.60697 10.8342 8.43644 9.78011 9.69594 9.05704C9.28158 10.0357 8.9352 11.1042 8.59185 12.357Z" stroke="#7766DF" stroke-width="0.4"/>
                <path d="M11.0776 13.7268L10.9691 13.8367L10.8353 13.7594L10.8158 13.7481L11.8934 15.8086C12.8888 13.8184 13.387 12.573 13.3834 11.1207C12.7431 11.9689 11.9909 12.8031 11.0776 13.7268Z" stroke="#7766DF" stroke-width="0.4"/>
                <g filter="url(#filter0_d_6043_1260)">
                <path d="M16.7245 1.12193C16.7361 2.55908 16.6125 3.38828 16.1376 4.56984L13.9538 3.30892C14.8972 2.33101 15.5749 1.7942 16.7245 1.12193Z" stroke="#7766DF" stroke-width="0.4" shape-rendering="crispEdges"/>
                </g>
                <path d="M12.007 10.0488C12.007 10.428 11.6995 10.7355 11.3203 10.7355C10.941 10.7355 10.6336 10.428 10.6336 10.0488C10.6336 9.66955 10.941 9.36211 11.3203 9.36211C11.6995 9.36211 12.007 9.66955 12.007 10.0488Z" stroke="#7766DF" stroke-width="0.4"/>
                <path d="M13.4845 7.68551C13.4845 8.06476 13.1771 8.37219 12.7978 8.37219C12.4186 8.37219 12.1111 8.06476 12.1111 7.68551C12.1111 7.30627 12.4186 6.99883 12.7978 6.99883C13.1771 6.99883 13.4845 7.30627 13.4845 7.68551Z" stroke="#7766DF" stroke-width="0.4"/>
                <path d="M14.962 5.51754C14.962 5.89679 14.6546 6.20423 14.2754 6.20423C13.8961 6.20423 13.5887 5.89679 13.5887 5.51754C13.5887 5.1383 13.8961 4.83086 14.2754 4.83086C14.6546 4.83086 14.962 5.1383 14.962 5.51754Z" stroke="#7766DF" stroke-width="0.4"/>
                <rect x="9.54688" y="14.6797" width="0.295561" height="1.47781" fill="#7766DF"/>
                <rect x="8.9375" y="14.002" width="0.295561" height="1.47781" transform="rotate(30 8.9375 14.002)" fill="#7766DF"/>
                <rect x="8.2627" y="13.5156" width="0.295561" height="1.4778" transform="rotate(60 8.2627 13.5156)" fill="#7766DF"/>
                <defs>
                <filter id="filter0_d_6043_1260" x="12.4515" y="0.779297" width="5.6555" height="6.44261" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="1.18224"/>
                <feGaussianBlur stdDeviation="0.591122"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6043_1260"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6043_1260" result="shape"/>
                </filter>
                </defs>
            </svg>
		</div>
	);
})`
	display: flex;
`;