import { styled } from "@mui/material/styles";

export const SanariesIcon = styled((props) => {
  const { className } = props;

  return (
    <div className={className}>
    <svg width="35" height="27" viewBox="0 0 35 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.433594" width="33.8507" height="26.4314" rx="4" fill="#654DF7" fill-opacity="0.19"/>
    <path d="M14.4286 4V6.20062M17.75 4V6.20062M21.0714 4V6.20062M18.8571 10.6019H14.4286M21.0714 15.0031H14.4286M13.5429 23.8056H21.9571C23.1973 23.8056 23.8174 23.8056 24.291 23.5657C24.7076 23.3548 25.0464 23.0181 25.2586 22.604C25.5 22.1333 25.5 21.517 25.5 20.2846V8.6213C25.5 7.38884 25.5 6.7726 25.2586 6.30187C25.0464 5.88779 24.7076 5.55114 24.291 5.34016C23.8174 5.10031 23.1973 5.10031 21.9571 5.10031H13.5429C12.3027 5.10031 11.6827 5.10031 11.209 5.34016C10.7924 5.55114 10.4536 5.88779 10.2413 6.30187C10 6.7726 10 7.38883 10 8.6213V20.2846C10 21.517 10 22.1333 10.2413 22.604C10.4536 23.0181 10.7924 23.3548 11.209 23.5657C11.6827 23.8056 12.3027 23.8056 13.5429 23.8056Z" stroke="#654DF7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    </div>
  );
})`
  display: flex;
`;