import axios from "axios";
import { toast } from "react-toastify";
import { header } from "../../utils/authheader";
import {
  GetEnvironment,
  GetApplication,
  GetBrowser,
  GetBrowser_testlab,
  GetApplication_testlab,GetEnvironment_testlab,
} from "./seleniumAction";
import { GetEnvironmentAdAu } from "./AdvanceAutomation/JourneyAction";
import { getBaseUrl } from "../../utils/configService";
export const GET_TEST_SUITS = "GET_TEST_SUITS";
export const GET_LOC_COUNT = "GET_LOC_COUNT";
export const GET_USER_COUNT = "GET_USER_COUNT";
export const RESET_USER_COUNT = "RESET_USER_COUNT";
export const RESET_LOC_COUNT = "RESET_LOC_COUNT";
export const GET_USER_LIST = "GET_USER_LIST";
export const GET_USER_LIST_TESTLAB = "GET_USER_LIST_TESTLAB";
export const ENVIORNMENT_VARIABLE_SET = "ENVIORNMENT_VARIABLE_SET";
export const ENVIORNMENT_VARIABLE_SET_TESTLAB = "ENVIORNMENT_VARIABLE_SET_TESTLAB";
export const ENVIORNMENT_VARIABLE_SET_VALUE = "ENVIORNMENT_VARIABLE_SET_VALUE";
export const GET_PERFORMANCE_INTEGRATION = "GET_PERFORMANCE_INTEGRATION";
export const JIRA_ISSUE_TYPES = "JIRA_ISSUE_TYPES";
export const JIRA_PROJECT_LIST = "JIRA_PROJECT_LIST";
export const GET_ALL_JIRA_ISSUE = "GET_ALL_JIRA_ISSUE";
export const SET_ACTIVE_PARENT = "SET_ACTIVE_PARENT";
export const SET_SELECTED_ITEM = "SET_SELECTED_ITEM";
export const GET_EMAIL_SCHEDULE = "GET_EMAIL_SCHEDULE";
export const ENVIORNMENT_VARIABLE_SET_SELECTED =
  "ENVIORNMENT_VARIABLE_SET_SELECTED";
export const GET_ADVANCE_USER_LIST = "GET_ADVANCE_USER_LIST";
export const GET_BUILD_VERSION_LIST = "GET_BUILD_VERSION_LIST";
export const GET_BUILD_VERSION_DETAILS = "GET_BUILD_VERSION_DETAILS";
export const GET_RELEASE_VERSION_LIST = "GET_RELEASE_VERSION_LIST";
export const GET_RELEASE_VERSION_DETAILS = "GET_RELEASE_VERSION_DETAILS";

export const GetBuildVersion = () => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/AdvanceAutomation/GetAllAdvanceAutomationBuildVersions`,
        header()
      );

      dispatch({
        type: GET_BUILD_VERSION_LIST,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      toast.error("NETWORK ERROR");
    }
  };
};

export const AddUpdateBuildVersion = (data) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/AdvanceAutomation/AddUpdateAdvanceAutomationBuildVersion`,
        data,
        header()
      );
      if (res.data.status === "success") {
        dispatch(GetBuildVersion());
        toast.info(res.data.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      } else if (res.data.status === "fail") {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log("error adding ", error);
      toast("Posting error");
    }
  };
};

export const DeleteBuildVersion = (id) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/AdvanceAutomation/DeleteAdvanceAutomationBuildVersion`,
        { id: id },
        header()
      );

      const response = res.data;

      if (response.status === "success") {
        dispatch(GetBuildVersion());
        toast.info(response.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      }
      return response; // Return the response object
    } catch (error) {
      console.log("error deleting ", error);
      toast.error("Error deleting application");
      return error.response; // Return the error response
    }
  };
};

export const GetBuildVersionDetails = (id) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/AdvanceAutomation/GetAdvanceAutomationBuildVersionById?id=${id}`,
        header()
      );
      console.log("API Response:", response.data);
      dispatch({
        type: GET_BUILD_VERSION_DETAILS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      toast.error("NETWORK ERROR");
    }
  };
};

export const GetReleaseVersion = () => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/AdvanceAutomation/GetAllAdvanceAutomationReleaseVersions`,
        header()
      );

      dispatch({
        type: GET_RELEASE_VERSION_LIST,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      toast.error("NETWORK ERROR");
    }
  };
};

export const AddUpdateReleaseVersion = (data) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/AdvanceAutomation/AddUpdateAdvanceAutomationReleaseVersion`,
        data,
        header()
      );
      if (res.data.status === "success") {
        dispatch(GetReleaseVersion());
        toast.info(res.data.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      } else if (res.data.status === "fail") {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log("error adding ", error);
      toast("Posting error");
    }
  };
};

export const DeleteReleaseVersion = (id) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/AdvanceAutomation/DeleteAdvanceAutomationReleaseVersion`,
        { id: id },
        header()
      );

      const response = res.data;

      if (response.status === "success") {
        dispatch(GetReleaseVersion());
        toast.info(response.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      }
      return response; // Return the response object
    } catch (error) {
      console.log("error deleting ", error);
      toast.error("Error deleting application");
      return error.response; // Return the error response
    }
  };
};

export const GetReleaseVersionDetails = (id) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/AdvanceAutomation/GetAdvanceAutomationReleaseVersionById?id=${id}`,
        header()
      );
      console.log("API Response:", response.data);
      dispatch({
        type: GET_RELEASE_VERSION_DETAILS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      toast.error("NETWORK ERROR");
    }
  };
};

export const getTestSuitesList = () => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/Selenium/GetTestSuites`,
        header()
      );
      dispatch({
        type: GET_TEST_SUITS,
        payload: response.data,
      });
    } catch (error) {
      console.error("Error in getTestSuites:", error);
      //   toast.error("NETWORK ERROR");
    }
  };
};

export const AddUpdateEnvironment = (data, navigate, onBack) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/Selenium/AddUpdateEnvironment`,
        data,
        header()
      );
      if (res.data.status === "success") {
        dispatch(GetEnvironment());
        toast.info(res.data.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
        onBack();
      } else if (res.data.status === "fail") {
        toast.error(res.data.message);
        onBack();
      }
    } catch (error) {
      console.log("error sending ", error);
      // toast('Posting error')
    }
  };
};

export const AddUpdateEnvironment_testlab = (data, navigate, onBack) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/TestLabSetting/AddUpdateTestLabEnvironment`,
        data,
        header()
      );
      if (res.data.status === "success") {
        dispatch(GetEnvironment());
        toast.info(res.data.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
        onBack();
      } else if (res.data.status === "fail") {
        toast.error(res.data.message);
        onBack();
      }
    } catch (error) {
      console.log("error sending ", error);
      // toast('Posting error')
    }
  };
};

export const AddUpdateEnvironmentAdAu = (data, navigate, onBack) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/AdvanceAutomation/AddUpdateAdvanceAutomationEnvironment`,
        data,
        header()
      );
      if (res.data.status === "success") {
        dispatch(GetEnvironmentAdAu());
        toast.info(res.data.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
        onBack();
      } else if (res.data.status === "fail") {
        toast.error(res.data.message);
        onBack();
      }
    } catch (error) {
      console.log("error sending ", error);
    }
  };
};

export const DeleteEnvironmentAdAu = (id) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/AdvanceAutomation/DeleteAdvanceAutomationEnvironment`,
        { id: id },
        header()
      );
      const response = res.data;
      if (response.status === "success") {
        dispatch(GetEnvironmentAdAu());
        toast.info(response.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      }

      return response;
    } catch (error) {
      console.log("error sending ", error);
      // toast('Posting error')
    }
  };
};

export const AddUpdateApplication = (data) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/Selenium/AddUpdateApplication`,
        data,
        header()
      );
      if (res.data.status === "success") {
        dispatch(GetApplication());
        toast.info(res.data.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      } else if (res.data.status === "fail") {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log("error adding ", error);
      toast("Posting error");
    }
  };
};

export const AddUpdateApplication_testlab = (data) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/TestLabSetting/AddUpdateTestLabApplication`,
        data,
        header()
      );
      if (res.data.status === "success") {
        dispatch(GetApplication_testlab());
        toast.info(res.data.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      } else if (res.data.status === "fail") {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log("error adding ", error);
      toast("Posting error");
    }
  };
};

export const AddUpdateBrowser = (data) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/Selenium/AddUpdateBrowser`,
        data,
        header()
      );
      if (res.data.status === "success") {
        dispatch(GetBrowser());
        toast.info(res.data.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      } else if (res.data.status === "fail") {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log("error adding ", error);
      toast("Posting error");
    }
  };
};

export const AddUpdateBrowser_testlab = (data) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/TestLabSetting/AddUpdateTestLabBrowser`,
        data,
        header()
      );
      if (res.data.status === "success") {
        dispatch(GetBrowser_testlab());
        toast.info(res.data.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      } else if (res.data.status === "fail") {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log("error adding ", error);
      toast("Posting error");
    }
  };
};

export const DeleteEnvironment = (id) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/Selenium/DeleteEnvironment?EnvironmentId=${id}`,
  
        header()
      );
      const response = res.data;
      if (response.status === "success") {
        dispatch(GetEnvironment());
        toast.info(response.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      }

      return response;
    } catch (error) {
      console.log("error sending ", error);
      // toast('Posting error')
    }
  };
};

export const DeleteEnvironment_testlab = (id) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/TestLabSetting/DeleteTestLabEnvironment?EnvironmentId=${id}`,header()
      );
      const response = res.data;
      if (response.status === "success") {
        dispatch(GetEnvironment_testlab());
        toast.info(response.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      }

      return response;
    } catch (error) {
      console.log("error sending ", error);
      // toast('Posting error')
    }
  };
};

export const DeleteApplication = (appId) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/Selenium/DeleteApplication?ApplicationId=${appId}`,
        appId,
        header()
      );

      const response = res.data;

      if (response.status === "success") {
        dispatch(GetApplication());
        toast.info(response.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      }
      return response; // Return the response object
    } catch (error) {
      console.log("error deleting ", error);
      toast.error("Error deleting application");
      return error.response; // Return the error response
    }
  };
};

export const DeleteApplication_testlab = (appId) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/TestLabSetting/DeleteTestLabApplication?ApplicationId=${appId}`,
        appId,
        header()
      );

      const response = res.data;

      if (response.status === "success") {
        dispatch(GetApplication_testlab());
        toast.info(response.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      }
      return response; // Return the response object
    } catch (error) {
      console.log("error deleting ", error);
      toast.error("Error deleting application");
      return error.response; // Return the error response
    }
  };
};

export const DeleteBrowser = (brwId) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/Selenium/DeleteBrowser?BrowserId=${brwId}`,
        brwId,
        header()
      );

      const response = res.data;
      if (response.status === "success") {
        dispatch(GetBrowser());
        toast.info("Successfully deleted", {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      }
      return response;
    } catch (error) {
      console.log("Error deleting ", error);
      toast.error("Error deleting");
    }
  };
};

export const DeleteBrowser_testlab = (brwId) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/TestLabSetting/DeleteTestLabBrowser?BrowserId=${brwId}`,
        brwId,
        header()
      );

      const response = res.data;
      if (response.status === "success") {
        dispatch(GetBrowser_testlab());
        toast.info("Successfully deleted", {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      }
      return response;
    } catch (error) {
      console.log("Error deleting ", error);
      toast.error("Error deleting");
    }
  };
};

// for variable set

export const DeleteEnviornmentvariable_set = (payload) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/LocalTesting/DeleteEnvironmentVariableSet`,
        payload,
        header()
      );

      const response = res.data;
      if (response.status === "success") {
        dispatch(getEnviornment_Variableset());
        toast.info("Successfully deleted", {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      }
      return response;
    } catch (error) {
      console.log("Error deleting ", error);
      toast.error("Error deleting");
    }
  };
};


export const DeleteEnviornmentvariable_set_testlab = (payload) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/LocalTesting/DeleteEnvironmentVariableSet`,
        payload,
        header()
      );

      const response = res.data;
      if (response.status === "success") {
        dispatch(getEnviornment_Variableset_testlab());
        toast.info("Successfully deleted", {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      }
      return response;
    } catch (error) {
      console.log("Error deleting ", error);
      toast.error("Error deleting");
    }
  };
};
export const environment_variable_set = (data) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/LocalTesting/AddUpdateEnvironmentVariableSet`,
        data,
        header()
      );
      if (res.data.status === "success") {
        dispatch(getEnviornment_Variableset());
        toast.info(res.data.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      } else if (res.data.status === "fail") {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log("error adding ", error);
      toast("Posting error");
    }
  };
};



export const environment_variable_set_testlab = (data) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/TestLabSetting/AddUpdateEnvironmentVariableSet`,
        data,
        header()
      );
      if (res.data.status === "success") {
        dispatch(getEnviornment_Variableset_testlab());
        toast.info(res.data.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      } else if (res.data.status === "fail") {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log("error adding ", error);
      toast("Posting error");
    }
  };
};

export const DeleteEnviornmentvariable_value = (item) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/LocalTesting/DeleteEnvironmentVariable`,
        {
          id: item.id,
        },

        header()
      );

      const response = res.data;
      if (response.status === "success") {
        let payload = {
          id: item.setId,
        };

        dispatch(getEnviornment_Variableset_value(payload));
        toast.info("Successfully deleted", {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      }
      return response;
    } catch (error) {
      console.log("Error deleting ", error);
      toast.error("Error deleting");
    }
  };
};

// for Test User

export const AddUpdateTestUser = (data) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/Selenium/AddTestUser`,
        data,
        header()
      );
      if (res.data.status === "success") {
        dispatch(GetTestUserList());
        toast.info(res.data.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      } else if (res.data.status === "fail") {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log("error adding ", error);
      toast("Posting error");
    }
  };
};

export const AddUpdateTestUser_testlab = (data) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/TestLabSetting/AddUpdateTestLabTestUser`,
        data,
        header()
      );
      if (res.data.status === "success") {
        dispatch(GetTestUserList_testlab());
        toast.info(res.data.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      } else if (res.data.status === "fail") {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log("error adding ", error);
      toast("Posting error");
    }
  };
};

export const GetTestUserList = () => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/Selenium/GetAllTestUser`,
        header()
      );
      dispatch({
        type: GET_USER_LIST,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      toast.error("NETWORK ERROR");
    }
  };
};

export const GetTestUserList_testlab = () => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/TestLabSetting/GetTestLabTestUserList`,
        header()
      );
      dispatch({
        type: GET_USER_LIST_TESTLAB,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      toast.error("NETWORK ERROR");
    }
  };
};

export const getEnviornment_Variableset = () => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/LocalTesting/GetAllEnvironmentVariableSets`,
        header()
      );
      dispatch({
        type: ENVIORNMENT_VARIABLE_SET,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      toast.error("NETWORK ERROR");
    }
  };
};


export const getEnviornment_Variableset_testlab = () => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/TestLabSetting/GetAllEnvironmentVariableSets`,
        header()
      );
      dispatch({
        type: ENVIORNMENT_VARIABLE_SET_TESTLAB,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      toast.error("NETWORK ERROR");
    }
  };
};

export const getEnviornment_Variableset_value = (row) => {
  return async (dispatch) => {
    dispatch({
      type: ENVIORNMENT_VARIABLE_SET_VALUE,
      payload: null,
    });

    dispatch({
      type: ENVIORNMENT_VARIABLE_SET_SELECTED,
      payload: row,
    });
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/LocalTesting/GetEnvironmentVariablesBySetId?setId=${row.id}`,
        header()
      );
      if (response.data.length > 0) {
        dispatch({
          type: ENVIORNMENT_VARIABLE_SET_VALUE,
          payload: response.data,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const environment_variable_value = (data) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/LocalTesting/AddUpdateEnvironmentVariableSet`,
        data,
        header()
      );
      if (res.data.status === "success") {
        dispatch(getEnviornment_Variableset_value());
        toast.info(res.data.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      } else if (res.data.status === "fail") {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log("error adding ", error);
      toast("Posting error");
    }
  };
};
export const AddUpdateEnviormnet_variable = (data) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/LocalTesting/AddUpdateEnvironmentVariable`,
        data,
        header()
      );
      if (res.data.status === "success") {
        let payload = {
          id: data.setId,
        };

        dispatch(getEnviornment_Variableset_value(payload));
        toast.info(res.data.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      } else if (res.data.status === "fail") {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log("error adding ", error);
      toast("Posting error");
    }
  };
};
export const DeleteUser = (id) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/Selenium/DeleteTestUser?Id=${id}`,
        id,
        header()
      );

      const response = res.data;

      if (response.status === "success") {
        dispatch(GetTestUserList());
        toast.info(response.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      }
      return response; // Return the response object
    } catch (error) {
      console.log("error deleting ", error);
      toast.error("Error deleting application");
      return error.response; // Return the error response
    }
  };
};

export const DeleteUser_testlab = (id) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/TestLabSetting/DeleteTestLabTestUser?Id=${id}`,
        id,
        header()
      );

      const response = res.data;

      if (response.status === "success") {
        dispatch(GetTestUserList_testlab());
        toast.info(response.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      }
      return response; // Return the response object
    } catch (error) {
      console.log("error deleting ", error);
      toast.error("Error deleting application");
      return error.response; // Return the error response
    }
  };
};

// performance

export const getPerformanceIntegrationList = (userId, setInProgress) => {
  return async (dispatch) => {
    setInProgress(true);
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/AddInBuildTestSuite/GetAllUserIntegrations?userId=${userId}`,
        header()
      );
      dispatch({
        type: GET_PERFORMANCE_INTEGRATION,
        payload: response.data,
      });
      setInProgress(false);
    } catch (error) {
      console.error("Error in getTestSuites:", error);
      setInProgress(false);
    }
  };
};

export const updateZiraIntegration = (
  data,
  setOpenModal,
  setLoading,
  callback
) => {
  setLoading(true);
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.post(
        `${BASE_URL}/AddInBuildTestSuite/UpdateUserIntegration`,
        data,
        header()
      );
      if (response.data.message == "Success") {
        if (data.isIntegrated == true) {
          toast.info("Successfully Jira Integrated", {
            style: {
              background: "rgb(101, 77, 247)",
              color: "rgb(255, 255, 255)",
            },
          });
          setOpenModal(false);
          setLoading(false);
        } else {
          toast.info("Successfully Jira Integrated Removed", {
            style: {
              background: "rgb(101, 77, 247)",
              color: "rgb(255, 255, 255)",
            },
          });
          setOpenModal(false);
          setLoading(false);
        }
        // dispatch(getPerformanceIntegrationList(data.userId));
        callback(true);
      } else if (response.data.message == "Not Found") {
        toast.warn("Invalid Information Entered");
        setLoading(false);
        callback(false);
      } else if (response.data.message == "Unauthorized") {
        toast.warn("Invalid Information Entered");
        setLoading(false);
        callback(false);
      }
    } catch (error) {
      console.error("Error in updateZiraIntegration:", error);
      setOpenModal(false);
      setLoading(false);
      callback(false);
    }
  };
};

export const updateTeamsIntegration = (
  data,
  setOpenTeamsModal,
  setLoading,
  callback
) => {
  setLoading(true);
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.post(
        `${BASE_URL}/AddInBuildTestSuite/UpdateUserIntegration`,
        data,
        header()
      );
      if (response.data.message == "Success") {
        if (data.isIntegrated == true) {
          toast.info("Successfully Teams Integrated", {
            style: {
              background: "rgb(101, 77, 247)",
              color: "rgb(255, 255, 255)",
            },
          });
          setOpenTeamsModal(false);
          setLoading(false);
        } else {
          toast.info("Successfully Teams Integration Removed", {
            style: {
              background: "rgb(101, 77, 247)",
              color: "rgb(255, 255, 255)",
            },
          });
          setOpenTeamsModal(false);
          setLoading(false);
        }
        // dispatch(getPerformanceIntegrationList(data.userId));
        callback(true);
      } else if (response.data.message == "Not Found") {
        toast.warn("Invalid Information Entered");
        setLoading(false);
        callback(false);
      } else if (response.data.message == "Unauthorized") {
        toast.warn("Invalid Information Entered");
        setLoading(false);
        callback(false);
      }
    } catch (error) {
      console.error("Error in updateZiraIntegration:", error);
      setOpenTeamsModal(false);
      setLoading(false);
      callback(false);
    }
  };
};

export const GetAllJiraIssueTypes = (userId) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.get(
        `${BASE_URL}/AddInBuildTestSuite/GetAllJiraIssueTypes?userId=${userId}`,
        header()
      );
      dispatch({
        type: JIRA_ISSUE_TYPES,
        payload: res.data,
      });
    } catch (error) {
      console.log("error getting JIRA ISSUE TYPE", error);
    }
  };
};

export const GetProjectListJira = (userId) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.get(
        `${BASE_URL}/AddInBuildTestSuite/GetJiraProjectsList?userId=${userId}`,
        header()
      );
      dispatch({
        type: JIRA_PROJECT_LIST,
        payload: res.data,
      });
    } catch (error) {
      console.log("error getting project data", error);
    }
  };
};

export const GetAllJiraIssue = (userId) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.get(
        `${BASE_URL}/AddInBuildTestSuite/GetAllJiraIssues?userId=${userId}`,
        header()
      );
      dispatch({
        type: GET_ALL_JIRA_ISSUE,
        payload: res.data,
      });
    } catch (error) {
      console.log("error getting project data", error);
    }
  };
};

export const createIssueOnJira = (payload, closeDrawer, userId) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/AddInBuildTestSuite/CreateIssueOnJira`,
        payload,
        header()
      );

      if (res.data.status === "OK") {
        toast.info(`Successfully created Ticket ${res?.data?.data?.key}`, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
        dispatch(GetAllJiraIssue(userId));
        closeDrawer(false);
      }
    } catch (error) {
      console.log("error creating issue on jira ", error);
    }
  };
};

export const setActiveParent = (parent) => ({
  type: SET_ACTIVE_PARENT,
  payload: parent,
});

export const setSelectedItem = (item) => ({
  type: SET_SELECTED_ITEM,
  payload: item,
});

// Email Reports

export const GetEmailScheduler = (userId) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.get(
        `${BASE_URL}/Scheduler/GetEmailScheduleSummary?userId=${userId}`,
        header()
      );
      dispatch({
        type: GET_EMAIL_SCHEDULE,
        payload: res.data,
      });
    } catch (error) {
      console.log("error getting project data", error);
    }
  };
};

export const scheduleEmailReports = (
  Payload,
  setShowForm,
  setSwitchState,
  callback = () => {}
) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.post(
        `${BASE_URL}/Scheduler/ScheduleEmail`,
        Payload,
        header()
      );
      if (response.data.status === "Success") {
        dispatch(GetEmailScheduler(Payload.userId));
        if (typeof callback === "function") {
          callback(true);
        }

        setShowForm(false);
      } else {
        if (typeof callback === "function") {
          callback(false);
        }

        setShowForm(false);
      }
    } catch (error) {
      setSwitchState(false);

      if (error.response) {
        if (error.response.status === 404) {
          console.error("Resource not found");
          toast.error(
            "Error: Resource not found (404). Please check the API endpoint."
          );
        } else {
          toast.error(
            `Error: ${
              error.response.data.message || "An error occurred on the server."
            }`
          );
        }
      } else if (error.request) {
        console.error("Network Error: No response received from server");
        toast.error(
          "Network Error: Failed to reach the server. Please check your connection."
        );
      } else {
        console.error("Error:", error.message);
        toast.error("An unexpected error occurred.");
      }
      setShowForm(false);

      if (typeof callback === "function") {
        callback(false);
      }
    }
  };
};

//  for Advance Automation -- Test-User

export const AddUpdateAdTestUser = (data) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/AdvanceAutomation/AddUpdateAdvanceAutomationTestUser`,
        data,
        header()
      );
      if (res.data.status === "success") {
        dispatch(GetAdTestUserList());
        toast.info(res.data.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      } else if (res.data.status === "fail") {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log("error adding ", error);
    }
  };
};

export const GetAdTestUserList = () => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/AdvanceAutomation/GetAllAdvanceAutomationTestUsers`,
        header()
      );
      dispatch({
        type: GET_ADVANCE_USER_LIST,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const DeleteAdUser = (id) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/AdvanceAutomation/DeleteAdvanceAutomationTestUser`,
        {
          id: id,
        },
        header()
      );

      const response = res.data;

      if (response.status === "success") {
        dispatch(GetAdTestUserList());
        toast.info(response.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      }
      return response;
    } catch (error) {
      console.log("error deleting ", error);
      return error.response;
    }
  };
};
