import { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { StyledTypography, useStylesDetails } from "./styles";
import Details from "./Details";
import Run from "./Run";
import Result from "./Result";
import { Card, Grid } from "@material-ui/core";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { JourneyDetails } from "../../../redux/actions/AdvanceAutomation/JourneyAction";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pl: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function TabsPanel() {
  const classes = useStylesDetails();
  const dispatch = useDispatch();
  const { selectedNode } = useSelector((state) => state.journey);
  const [value, setValue] = useState(0); // Start with index 0

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleBack = () => {
    dispatch(JourneyDetails(null));
  };

  return (
    <Card style={{ minHeight: 'calc(100vh - 163px)' }}>
      <Grid
        container
        className={classes.header}
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={6} >
          <div className={classes.highlight}>
            {selectedNode?.name.length > 40
              ? selectedNode?.name.slice(0, 40) + "..."
              : selectedNode?.name}
          </div>
        </Grid>
        <Grid item>
          <Button
            className={classes.button}
            sx={{
              backgroundColor: "rgb(101, 77, 247)",
              "&:hover": {
                backgroundColor: "rgb(101, 77, 247) !important",
                borderColor: "#654DF7",
                color: "#fff",
                "&:before": {
                  backgroundColor: "rgb(101, 77, 247) !important",
                  color: "#fff",
                },
              },
              color: "#fff",
            }}
            onClick={handleBack}
          >
            <StyledTypography>Back</StyledTypography>
          </Button>
        </Grid>
      </Grid>
      <Grid className={classes.tabHeader}>
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "rgb(101, 77, 247)",
            },
            "& .MuiTab-root.Mui-selected": {
              color: "rgb(101, 77, 247)",
            },
          }}
        >
          <Tab
            value={0}
            label={
              <StyledTypography style={{ textTransform: "capitalize" }}>
                Details
              </StyledTypography>
            }
          />
          <Tab
            value={1}
            label={
              <StyledTypography style={{ textTransform: "capitalize" }}>
                Run
              </StyledTypography>
            }
          />
          <Tab
            value={2}
            label={
              <StyledTypography style={{ textTransform: "capitalize" }}>
                Result
              </StyledTypography>
            }
          />
        </Tabs>
      </Grid>

      <TabPanel value={value} index={0}>
        <Details />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Run />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Result />
      </TabPanel>
    </Card>
  );
}
