import axios from "axios";
import { toast } from "react-toastify";
import { header } from "../../utils/authheader";
import { getBaseUrl } from "../../utils/configService";
export const LOG_IN = "LOG_IN";
export const LOG_OUT = "LOG_OUT";
export const SET_USER_ID = "SET_USER_ID";
export const GET_CUSTOMER_SUBSCRIPTION_DETAILS = "GET_CUSTOMER_SUBSCRIPTION_DETAILS";


export const login = (data, setLoading) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(`${BASE_URL}/Login`, data);
      const response = res.data;

      if (response?.result === "Success") {
        toast.info("Successfully logged in", {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });

        const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        localStorage.setItem(
          "userData",
          JSON.stringify({
            ...response.data,
            token: response.token,
            TimeZone: localTimeZone,
          })
        );
        localStorage.setItem(
          "tokenExpiry",
          JSON.stringify({ ...response.data, expiration: response.expiration })
        );
        localStorage.setItem("email", data.email.toString());

        // Call getCustomerSubscriptionDetails before dispatching login values
        await dispatch(getCustomerSubscriptionDetails());

        dispatch({
          type: LOG_IN,
          payload: { ...response.data, token: response.token },
        });
      } else {
        if (response.message === "User Name or Password is Wrong") {
          toast.error("Invalid username or password");
        } else {
          toast.error(response.message);
        }
      }
    } catch (err) {
      if (err.response.data.status == "error") {
        toast.error(err.response.data.message);
      }else {
        console.error(err);
      }
    } finally {
      setLoading(false);
    }
  };
};


export const getCustomerSubscriptionDetails = () => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.get(`${BASE_URL}/Account/GetCustomerSubscriptionDetails`, header());
      const response = res.data;

      // Extract plan name
      const planName = response.data[1].name;

      // Extract features
      const featuresArray = response.data[2].data.map((item) => item.entitlement_feature.name);

      // Extract timestamps from the response
      const timestampStart = response.data[0].data[0].current_period_start;
      const timestampEnd = response.data[0].data[0].current_period_end;

      // Call the calculateRemainingDays function and store the result in a variable
      const remainingDaysInfo = {
        ...calculateRemainingDays(timestampStart, timestampEnd),
        plan_name: planName,
        features_available: featuresArray
      };


      // Dispatch the combined payload with remainingDaysInfo, including plan name and features
      dispatch({
        type: GET_CUSTOMER_SUBSCRIPTION_DETAILS,
        payload: { remainingDaysInfo }, // Adding remainingDaysInfo to the payload
      });
      
    } catch (err) {
      console.error("Error fetching customer subscription details:", err);
      toast.error("NETWORK ERROR");
    } 
  };
};



export const forgotPassword = (data, setLoading, navigate) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/AddInBuildTestSuite/SendPasswordResetMail`,
        data
      );
      const response = res.data;
      if (response?.status === "Success") {
        toast.info(
          `Password reset information has been sent to the ${data.email} email`,
          {
            style: {
              background: "rgb(101, 77, 247)",
              color: "rgb(255, 255, 255)",
            },
          }
        );
        navigate("/");
      } else {
        console.log("somethings wrong");
      }
    } catch (err) {
      toast.error(err.response.data.message);
      console.log({ error: err.response });
    } finally {
      setLoading(false);
    }
  };
};

export const resetPassword = (payload, navigate) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/AddInBuildTestSuite/ResetPassword`,
        payload
      );
      const response = res.data;
      console.log("response", response);
      if (response?.status === "Success") {
        toast.info(`${response.message}`, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
        navigate("/");
      } else {
        console.log("somethings wrong");
      }
    } catch (err) {
      toast.error(err.response.data.message);
      console.log({ error: err.response });
    } finally {
    }
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch({ type: LOG_OUT });
  };
};

export const InviteUser = (email) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/AddInBuildTestSuite/InviteUser?toEmail=${email}`,
        email
      );
      if (res.data.status === "Success") {
        toast.info("Successfully invited", {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      } else {
        toast.warn(res.data.message);
      }
    } catch (error) {
      console.log("error inviting ", error);
      toast.error("Network error");
    }
  };
};

export const AcceptInvitation = (email, handeSetAccept) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/AddInBuildTestSuite/AcceptInvitation?toEmail=${email}`,
        email
      );
      if (res?.data?.emailStatus?.status === "Success") {
        handeSetAccept();
        toast.info("Successfully Accepted", {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      } else if (res?.data?.status == 'Failed') {
        toast.warn(res.data.message);
      }else {
        console.log(res?.data)
      }
    } catch (error) {
      console.log("error inviting ", error);
      
    }
  };
};

export const ChangePasswordReq = (payload, redirectToLogin) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/AddInBuildTestSuite/ChangePassword`,
        payload
      );
      console.log("res ", res);
      if (res.data.status === "Success") {
        toast.info("Password Changed Successfully", {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
        redirectToLogin();
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log("error changing password ", error);
      toast.error("Network error");
    }
  };
};

export const setUserId = (id) => {
  return (dispatch) => {
    dispatch({ type: SET_USER_ID, payload: id });
  };
};
export const getUserId = () => {
  const emailFromSession = localStorage.getItem("email");
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/Selenium/GetProfilByEmail?Email=${emailFromSession}`,
        emailFromSession,
        header()
      );
      dispatch(setUserId(res.data?.Id));
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };
};

function calculateRemainingDays(timestampStart, timestampEnd) {
  // Convert timestamps from seconds to milliseconds and create Date objects
  const startDate = new Date(timestampStart * 1000);
  const endDate = new Date(timestampEnd * 1000);

  // Get today's date and reset the time part to 00:00:00 for accurate comparison
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // Calculate the difference in milliseconds between the end date and today
  const differenceInMilliseconds = endDate - today;

  // Convert the difference from milliseconds to days
  const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));

  // Return the result as a JSON object
  return {
    start_date: startDate.toISOString().split('T')[0], // Format as YYYY-MM-DD
    end_date: endDate.toISOString().split('T')[0],     // Format as YYYY-MM-DD
    days_remaining: differenceInDays > 0 ? differenceInDays : 0, // Ensure non-negative remaining days
  };
}

// Example usage
const timestampStart = 1724763278; // Example start date timestamp
const timestampEnd = 1727355278;   // Example end date timestamp

const result = calculateRemainingDays(timestampStart, timestampEnd);

console.log(result); 
