import React from "react";
import { useStyles } from "../../Layout/styles";
import { Link, Outlet, useLocation } from "react-router-dom";
import { Grid } from "@material-ui/core";

export default function Functional() {
  const classes = useStyles();
  const location = useLocation();

  return (
    <>
      <Grid
        container
        alignItems="center"
        style={{
          // marginTop: "-7px",
          // height: "50px",
          // padding: "0 20px",
          // position: "fixed",
          // background: "#fafafa",
          // zIndex: 1000,
          // width: "100%",
        }}
      >
        {/* <Grid item>
          <Link
            to="/"
            className={`${classes.linkStyle} ${
              location.pathname === "/" && classes.activeLink
            }`}
          >
            Dashboard
          </Link>
        </Grid>

        <Grid item>
          <Link
            to="/local-testing"
            style={{ marginLeft: "20px" }}
            className={`${classes.linkStyle} ${
              location.pathname.startsWith("/local-testing") &&
              classes.activeLink
            }`}
          >
            Local Testing
          </Link>
        </Grid> */}
        <Grid item>
          {/* <Link
                        to="/testcase"
                        style={{ marginLeft: "20px" }}
                        className={`${classes.linkStyle} ${location.pathname.startsWith("/testcase") && classes.activeLink}`}
                    >
                         Test Cases
                    </Link> */}
        </Grid>
      </Grid>

      <div style={{ paddingTop: "0px" }}>
        <Outlet />
      </div>
    </>
  );
}
