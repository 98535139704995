import React from 'react'
import { styled } from '@mui/material/styles';

export const Organization = styled((props) => {
	const { className } = props;

	return (
		<div className={className}>
			<svg width="53" height="71" viewBox="0 0 53 71" fill="none" style={{ height: '20px', width: '20px' }} xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6 0C2.68629 0 0 2.68629 0 6V65C0 68.3137 2.68629 71 6 71H20V58.5C20 54.9101 22.9101 52 26.5 52C30.0899 52 33 54.9101 33 58.5V71H47C50.3137 71 53 68.3137 53 65V6C53 2.68629 50.3137 0 47 0H6ZM23 13C22.4477 13 22 13.4477 22 14V21C22 21.5523 22.4477 22 23 22H30C30.5523 22 31 21.5523 31 21V14C31 13.4477 30.5523 13 30 13H23ZM9 14C9 13.4477 9.44771 13 10 13H17C17.5523 13 18 13.4477 18 14V21C18 21.5523 17.5523 22 17 22H10C9.44771 22 9 21.5523 9 21V14ZM36 31C35.4477 31 35 31.4477 35 32V39C35 39.5523 35.4477 40 36 40H43C43.5523 40 44 39.5523 44 39V32C44 31.4477 43.5523 31 43 31H36ZM35 14C35 13.4477 35.4477 13 36 13H43C43.5523 13 44 13.4477 44 14V21C44 21.5523 43.5523 22 43 22H36C35.4477 22 35 21.5523 35 21V14ZM23 31C22.4477 31 22 31.4477 22 32V39C22 39.5523 22.4477 40 23 40H30C30.5523 40 31 39.5523 31 39V32C31 31.4477 30.5523 31 30 31H23ZM10 31C9.44771 31 9 31.4477 9 32V39C9 39.5523 9.44771 40 10 40H17C17.5523 40 18 39.5523 18 39V32C18 31.4477 17.5523 31 17 31H10Z" fill="#7766DF"/>
            </svg>
		</div>
	);
})`
	display: flex;
`;