import { makeStyles } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

export const useStyles = makeStyles((theme) => ({
  main: {
    margin: "20px",
    padding: "10px 22px",
  },
  header: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "21px",
    padding: "10px 22px",
  },
  button: {
    height: "40px",
    minWidth: "110px",
    display: "flex",
    textTransform: "none !important",
    backgroundColor: "rgb(101, 77, 247)",
    color: "#fff",
  },
  customFontSize: {
    fontSize: "14px !important",
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      borderColor: "transparent !important",
      "&:hover fieldset": {
        borderColor: "#654DF7",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#654DF7",
      },
    },
  },
  label: {
    alignSelf: "center",
    padding: "10px 10px",
  },
  cardGrid: {
    padding: "10px",
  },
  ButtonContainer: {
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
    padding: "10px",
    // borderTop: "1px solid #ccc",
    gap: "8px",
  },
  highlight: {
    fontSize: "24px",
    fontWeight: "400",
    color: "rgb(56, 56, 56)",
  },
  card: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#f5f5f5",
    padding: "20px",
  },
  gridContainer: {
    padding: "20px",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  logoAndTitle: {
    display: "flex",
    alignItems: "center",
  },
  logo: {
    marginRight: "10px",
  },
  cardContent: {
    marginTop: "10px",
  },
  cardFooter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "auto",
    paddingTop: theme.spacing(2),
  },
  learnMoreLink: {
    color: "#3f51b5",
    textDecoration: "none",
    marginRight: theme.spacing(1),
    cursor: "pointer",
  },
  manageButton: {
    backgroundColor: "#654DF7",
    color: "white",
    padding: theme.spacing(1),
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#4638ab",
    },
  },
  svgIcon: {
    width: '70%', // Adjust size of the SVG inside Avatar
    height: '70%',
  },
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(242, 242, 242)",
    color: "#5c5c5c",
    padding: "10px 20px",
    fontFamily: "Lexend Deca",
    fontSize: "12px",
    borderTop: "1px solid rgb(217, 217, 217)",
    lineHeight: "18px",
  },
  [`&.${tableCellClasses.body}`]: {
    // backgroundColor: "#fdfdfd",
    padding: "10px 20px",
    fontSize: "12px",
    lineHeight: "18px",
    letterSpacing: "normal",
    fontFamily: `"Lexend Deca"`,
  },
}));

export const useTableStyles = makeStyles((theme) => ({
  tableRow: {
    cursor: "pointer",
    "&:hover": {
      border: "2px solid #654DF7",
      backgroundColor: theme.palette.action.hover,
      cursor: "pointer",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.action.selected,
    },
    height: "40px",
  },
  activeRow: {
    border: "2px solid #654DF7",
  },
}));
