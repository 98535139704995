import React from 'react'
import { styled } from '@mui/material/styles';

export const Integration = styled((props) => {
	const { className } = props;

	return (
		<div className={className}>
			<svg width="59" height="68" viewBox="0 0 59 68" fill="none" style={{ height: '20px', width: '20px' }} xmlns="http://www.w3.org/2000/svg">
                <path d="M6 18H15V29H40C42.7614 29 45 26.7614 45 24V18H54V34.1196C54 36.2627 52.2627 38 50.1196 38C49.3833 38 48.6571 37.8286 47.9986 37.4993L47.4828 37.2414C47.1653 37.0826 46.8152 37 46.4603 37H41.7454C41.2552 37 40.7718 37.1141 40.3333 37.3333C39.4565 37.7718 38.4896 38 37.5093 38H20C17.2386 38 15 40.2386 15 43V49H6V18Z" fill="#7766DF"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 21C16.299 21 21 16.2981 21 10.5C21 4.70188 16.299 0 10.5 0C4.70095 0 0 4.70188 0 10.5C0 16.2981 4.70095 21 10.5 21ZM10.5 14.3182C12.6086 14.3182 14.3182 12.6086 14.3182 10.5C14.3182 8.39142 12.6086 6.68182 10.5 6.68182C8.39142 6.68182 6.68182 8.39142 6.68182 10.5C6.68182 12.6086 8.39142 14.3182 10.5 14.3182Z" fill="#7766DF"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 68C16.299 68 21 63.2981 21 57.5C21 51.7019 16.299 47 10.5 47C4.70095 47 0 51.7019 0 57.5C0 63.2981 4.70095 68 10.5 68ZM10.5 61.3182C12.6086 61.3182 14.3182 59.6086 14.3182 57.5C14.3182 55.3914 12.6086 53.6818 10.5 53.6818C8.39142 53.6818 6.68182 55.3914 6.68182 57.5C6.68182 59.6086 8.39142 61.3182 10.5 61.3182Z" fill="#7766DF"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M48.5 21C54.299 21 59 16.2981 59 10.5C59 4.70188 54.299 0 48.5 0C42.701 0 38 4.70188 38 10.5C38 16.2981 42.701 21 48.5 21ZM48.5 14.3182C50.6086 14.3182 52.3182 12.6086 52.3182 10.5C52.3182 8.39142 50.6086 6.68182 48.5 6.68182C46.3914 6.68182 44.6818 8.39142 44.6818 10.5C44.6818 12.6086 46.3914 14.3182 48.5 14.3182Z" fill="#7766DF"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M43.9999 58C41.2608 58 39.0417 55.762 39.0417 53C39.0417 50.238 41.2608 48 43.9999 48C46.739 48 48.9591 50.238 48.9591 53C48.9591 55.762 46.739 58 43.9999 58ZM58.0084 56.536L55.6854 55.184C55.8154 54.474 55.9014 53.749 55.9014 53C55.9014 52.252 55.8154 51.526 55.6854 50.816L58.0084 49.464C58.9575 48.912 59.2815 47.688 58.7335 46.732L56.7504 43.268C56.2034 42.312 54.9893 41.983 54.0413 42.536L51.6942 43.901C50.5982 42.961 49.3521 42.192 47.9671 41.697V40C47.9671 38.896 47.079 38 45.983 38H42.0168C40.9208 38 40.0327 38.896 40.0327 40V41.697C38.6477 42.192 37.4016 42.961 36.3056 43.901L33.9585 42.536C33.0105 41.983 31.7964 42.312 31.2494 43.268L29.2663 46.732C28.7183 47.688 29.0433 48.912 29.9913 49.464L32.3144 50.816C32.1844 51.526 32.0984 52.252 32.0984 53C32.0984 53.749 32.1844 54.474 32.3144 55.184L29.9913 56.536C29.0433 57.088 28.7183 58.312 29.2663 59.268L31.2494 62.732C31.7964 63.688 33.0105 64.017 33.9585 63.464L36.3056 62.099C37.4016 63.039 38.6477 63.809 40.0327 64.303V66C40.0327 67.104 40.9208 68 42.0168 68H45.983C47.079 68 47.9671 67.104 47.9671 66V64.303C49.3521 63.809 50.5982 63.039 51.6942 62.099L54.0413 63.464C54.9893 64.017 56.2034 63.688 56.7504 62.732L58.7335 59.268C59.2815 58.312 58.9575 57.088 58.0084 56.536Z" fill="#7766DF"/>
            </svg>
		</div>
	);
})`
	display: flex;
`;