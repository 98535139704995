import { styled } from "@mui/material/styles";

export const ResponseTimeIcon = styled((props) => {
  const { className } = props;

  return (
    <div className={className}>
      <svg
        width="56"
        height="44"
        viewBox="0 0 56 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          y="0.273926"
          width="56"
          height="43.726"
          rx="4"
          fill="#00A879"
          fill-opacity="0.19"
        />
        <path d="M35.5 30.75H25.5V33.25H35.5V30.75Z" fill="#00A879" />
        <path d="M22.9961 30.75H20.4961V33.25H22.9961V30.75Z" fill="#00A879" />
        <path d="M35.5 24.5H25.5V27H35.5V24.5Z" fill="#00A879" />
        <path d="M22.9961 24.5H20.4961V27H22.9961V24.5Z" fill="#00A879" />
        <path d="M35.5 18.25H25.5V20.75H35.5V18.25Z" fill="#00A879" />
        <path d="M22.9961 18.25H20.4961V20.75H22.9961V18.25Z" fill="#00A879" />
        <path
          d="M39.25 8.25H35.5V7C35.5 6.33696 35.2366 5.70107 34.7678 5.23223C34.2989 4.76339 33.663 4.5 33 4.5H23C22.337 4.5 21.7011 4.76339 21.2322 5.23223C20.7634 5.70107 20.5 6.33696 20.5 7V8.25H16.75C16.087 8.25 15.4511 8.51339 14.9822 8.98223C14.5134 9.45107 14.25 10.087 14.25 10.75V37C14.25 37.663 14.5134 38.2989 14.9822 38.7678C15.4511 39.2366 16.087 39.5 16.75 39.5H39.25C39.913 39.5 40.5489 39.2366 41.0178 38.7678C41.4866 38.2989 41.75 37.663 41.75 37V10.75C41.75 10.087 41.4866 9.45107 41.0178 8.98223C40.5489 8.51339 39.913 8.25 39.25 8.25ZM23 7H33V12H23V7ZM39.25 37H16.75V10.75H20.5V14.5H35.5V10.75H39.25V37Z"
          fill="#00A879"
        />
      </svg>
    </div>
  );
})`
  display: flex;
`;