import React from 'react'
import { styled } from '@mui/material/styles';

export const MemberList = styled((props) => {
	const { className } = props;

	return (
		<div className={className}>
			<svg width="90" height="71" viewBox="0 0 90 71" fill="none" style={{ height: '20px', width: '20px' }} xmlns="http://www.w3.org/2000/svg">
				<circle cx="45" cy="30" r="15" fill="#7766DF"/>
				<path d="M18 66C18 56.6112 25.6112 49 35 49H55C64.3888 49 72 56.6112 72 66V68C72 69.6569 70.6569 71 69 71H21C19.3431 71 18 69.6569 18 68V66Z" fill="#7766DF"/>
				<circle cx="17.5" cy="11.5" r="11.5" fill="#7766DF"/>
				<path d="M0 37C0 31.4772 4.47715 27 10 27H23.8988C25.0421 27 25.9464 27.9589 25.9294 29.1021C25.8448 34.7702 26.8018 37.8736 30 42H2C0.89543 42 0 41.1046 0 40V37Z" fill="#7766DF"/>
				<circle cx="11.5" cy="11.5" r="11.5" transform="matrix(-1 0 0 1 84 0)" fill="#7766DF"/>
				<path d="M90 37C90 31.4772 85.5228 27 80 27H66.1012C64.9579 27 64.0536 27.9589 64.0706 29.1021C64.1552 34.7702 63.1982 37.8736 60 42H88C89.1046 42 90 41.1046 90 40V37Z" fill="#7766DF"/>
			</svg>
		</div>
	);
})`
	display: flex;
`;