import React from 'react'
import { styled } from '@mui/material/styles';

export const AppDynamicIcon = styled((props) => {
	const { className } = props;

	return (
		<div className={className}>
			<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.09375 2.43726C0.09375 1.1427 1.14319 0.0932617 2.43775 0.0932617H32.9098C34.2043 0.0932617 35.2537 1.14271 35.2537 2.43726V32.9093C35.2537 34.2038 34.2043 35.2533 32.9098 35.2533H2.43775C1.14319 35.2533 0.09375 34.2038 0.09375 32.9093V2.43726Z" fill="#43AF49"/>
<path d="M17.675 9.46905L25.879 26.424H24.5663L19.4469 19.3543C18.5735 18.1482 16.7764 18.1482 15.903 19.3543L10.7836 26.424H9.47096L17.675 9.46905Z" fill="white"/>
</svg>

		</div>
	);
})`
	display: flex;
`;
