import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch } from "react-redux";
import { DeleteRun } from "../../../../redux/actions/AdvanceAutomation/JourneyAction"; 

function DeleteModal({ open, onClose, run }) {
  const dispatch = useDispatch();
  const [runName, setRunName] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (run) {
      setRunName(run.Name); 
    }
  }, [run]);

  const handleDelete = async () => {
    setIsDeleting(true); 
    try {
      const response = await dispatch(DeleteRun(run.Id, run.JourneyId)); 
      if (response.status === "fail") {
        onClose();
      } else {
        onClose(); 
      }
    } catch (error) {
      console.error("Error handling run deletion:", error);
      onClose();
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div>
      {open && (
        <Dialog
          open={open}
          onClose={onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete Run"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this run <b>{runName}</b>?
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center", marginBottom: "20px" }}>
            <Button
              onClick={handleDelete}
              style={{
                marginRight: "10px",
                backgroundColor: "#654DF7",
                height: "30px",
                width: "100px",
                color: "white",
              }}
              disabled={isDeleting} 
            >
              {isDeleting ? "Deleting..." : "Delete"} 
            </Button>
            <Button
              onClick={onClose}
              color="primary"
              style={{
                backgroundColor: "#6c757d",
                width: "100px",
                height: "30px",
                color: "white",
              }}
              disabled={isDeleting} 
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

export default DeleteModal;