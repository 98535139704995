import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Checkbox, FormControlLabel, CircularProgress, Grid } from "@mui/material";
import PropTypes from 'prop-types';
import { useStyles, StyledTableCell } from "./styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from "react-redux";
import SavDelModal from "../Modal/SavDelModal";

function DetailsAccordion({
  baselineData = [],
  journeyData = [],
  journeyName = "",
  loading = false,
  selectAll = false,
  onTestCaseSelection,
  selectedTestCases,
}) {
  const classes = useStyles();
  const [expandedJourney, setExpandedJourney] = useState("");
  const [selectedItems, setSelectedItems] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { journeyDetails } = useSelector((state) => state.journey);
  const [journeyId, setJourneyId] = useState(null);

  useEffect(() => {
    const updatedSelection = {};
    journeyData.forEach((item) => {
      updatedSelection[item.id] = selectedTestCases.includes(item.id);
    });
    setSelectedItems(updatedSelection);
  }, [selectAll, journeyData, selectedTestCases]);

  const handleExpandJourney = (panel) => (e, isExpanded) => {
    setExpandedJourney(isExpanded ? panel : "");
  };

  const handleCheckboxChange = (testCaseId) => (e) => {
    const isChecked = e.target.checked;
    setSelectedItems((prev) => ({
      ...prev,
      [testCaseId]: isChecked,
    }));
    onTestCaseSelection(testCaseId, isChecked);
  };

  const handleDeleteClick = (item) => {
    setJourneyId(journeyDetails.id);
    setSelectedItem(item);
    setModalOpen(true);
  };

  const renderAccordion = (data, expanded, handleExpand, showCheckboxesAndDelete) =>
    data.map((item, index) => {
      const testCaseSteps = JSON.parse(item.testCaseSteps || "{}");
      const steps = testCaseSteps.actions || [];
      return (
        <div style={{ marginLeft: "5px" }} key={item.id}>
          <Accordion
            expanded={expanded === `panel${index}`}
            onChange={handleExpand(`panel${index}`)}
            className={classes.accordion}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}bh-content`}
              id={`panel${index}bh-header`}
              className={classes.accordionSummary}
            >
              <div className={classes.summaryContent}>
                {showCheckboxesAndDelete && (
                  <FormControlLabel
                    aria-label="Acknowledge"
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    control={
                      <Checkbox
                        checked={selectedItems[item.id] || false}
                        onChange={handleCheckboxChange(item.id)}
                        className={classes.checkbox}
                        sx={{
                          color: 'rgb(101, 77, 247)',
                          '&.Mui-checked': {
                            color: 'rgb(101, 77, 247)',
                          },
                        }}
                      />
                    }
                  />
                )}
                <Typography className={classes.heading}>{item.testCaseName}</Typography>
                {showCheckboxesAndDelete && (
                  <DeleteIcon
                    className={classes.deleteIcon}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteClick(item);
                    }}
                  />
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {steps.length > 0 ? (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Steps</StyledTableCell>
                        <StyledTableCell>Expected Results</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {steps.map((step, stepIndex) => (
                        <TableRow key={stepIndex} className={classes.tableRow}>
                          <StyledTableCell>{step.step}</StyledTableCell>
                          <StyledTableCell>{step.result}</StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography variant="body2" color="textSecondary">
                  No steps available
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>
        </div>
      );
    });

  return (
    <Box>
      {loading && <CircularProgress className={classes.loadingSpinner} size={25} />}
      <Grid container spacing={2}>
        {baselineData.length > 0 && (
          <Grid item xs={6}>
            <Box className={classes.headerBox}>
              <Typography className={classes.infoHeader}>Baseline Journey</Typography>
              {renderAccordion(baselineData, expandedJourney, handleExpandJourney, false)}
            </Box>
          </Grid>
        )}

        <Grid item xs={baselineData.length > 0 ? 6 : 12}>
          <Box className={classes.headerBox}>
            <Typography className={classes.infoHeader}>{journeyName}</Typography>
            {!loading && journeyData.length > 0 ? (
              renderAccordion(journeyData, expandedJourney, handleExpandJourney, true)
            ) : (
              <Typography className={classes.noDataText}>No data available</Typography>
            )}
          </Box>
        </Grid>
      </Grid>

      {selectedItem && (
        <SavDelModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          item={selectedItem}
          journeyId={journeyId}
          types="test case delete"
        />
      )}
    </Box>
  );
}

DetailsAccordion.propTypes = {
  baselineData: PropTypes.array,
  journeyData: PropTypes.array,
  journeyName: PropTypes.string,
  loading: PropTypes.bool,
  selectAll: PropTypes.bool,
  onTestCaseSelection: PropTypes.func.isRequired,
  selectedTestCases: PropTypes.array.isRequired,
};

export default DetailsAccordion;