import { styled } from '@mui/material/styles';

export const EmailReportSummaries = styled((props) => {
	const { className } = props;

	return (
		<div className={className}>
			<svg width="76" height="68" viewBox="0 0 76 68" fill="none" style={{ height: '20px', width: '20px' }} xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M23.3286 59.5982C22.2513 60.425 20.753 60.4251 19.6756 59.5984L0.804389 45.1173C0.475498 44.8649 0 45.0994 0 45.5139V64.625C0 66.2819 1.34315 67.625 3 67.625H40C41.6569 67.625 43 66.2819 43 64.625V45.5141C43 45.0995 42.5245 44.865 42.1956 45.1174L23.3286 59.5982ZM23.291 55.1419C22.2289 55.9309 20.7753 55.931 19.7132 55.142L0.40373 40.7999C0.149733 40.6112 0 40.3135 0 39.9971V37C0 35.3431 1.34315 34 3 34H40C41.6569 34 43 35.3431 43 37V38.9914C43 39.9405 42.5509 40.8336 41.789 41.3996L23.291 55.1419Z" fill="#7766DF"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M28 22C26.3431 22 25 23.3431 25 25V30C25 30.5523 25.4477 31 26 31H39C42.866 31 46 34.134 46 38V55C46 55.5523 46.4477 56 47 56H73C74.6569 56 76 54.6569 76 53V25C76 23.3431 74.6569 22 73 22H28ZM61.5 31C60.6716 31 60 31.6716 60 32.5V36.5C60 37.3284 60.6716 38 61.5 38H65.5C66.3284 38 67 37.3284 67 36.5V32.5C67 31.6716 66.3284 31 65.5 31H61.5Z" fill="#7766DF"/>
                <path d="M8 4C8 1.79086 9.79086 0 12 0H58C59.1046 0 60 0.895431 60 2V16C60 17.1046 59.1046 18 58 18H29C25.134 18 22 21.134 22 25V29C22 30.1046 21.1046 31 20 31H10C8.89543 31 8 30.1046 8 29V4Z" fill="#7766DF"/>
            </svg>
		</div>
	);
})`
	display: flex;
`;