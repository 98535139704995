import React from "react";
import { styled } from "@mui/material/styles";

export const DrawerClosedIcon = ({ isActive, color }) => {
  return (
    <div style={{ color: isActive ? color : "inherit" }}>
    <svg width="44" height="31" viewBox="0 0 44 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="43" height="30" rx="3.5" stroke="#DADADA"/>
    <path d="M11 23L20.7778 23M25.6667 23V8M11 18L20.7778 18M11 13L20.7778 13M11 8L20.7778 8M30.5556 18L33 15.5L30.5556 13" stroke="#646464" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    </div>
  );
};
