import React from 'react'
import { styled } from '@mui/material/styles';

export const EnvironmentVariableSet = styled((props) => {
	const { className } = props;

	return (
		<div className={className}>
			<svg width="150" height="150" viewBox="0 0 150 150" fill="none" style={{ height: '20px', width: '20px' }} xmlns="http://www.w3.org/2000/svg">
                <path d="M117.459 3.88655C122.536 -1.19027 130.648 -1.30882 135.579 3.62174L146.157 14.2003C151.088 19.1309 150.969 27.2435 145.892 32.3203L132.418 45.7949L103.984 17.3611L117.459 3.88655Z" fill="#7766DF"/>
                <path d="M99.2549 22.0904L127.411 50.2466L74.3904 103.267C72.5398 105.118 70.1897 106.383 67.6476 106.897L40.5225 112.381C38.4718 112.796 36.7059 111.03 37.1205 108.979L42.6045 81.8539C43.1185 79.3118 44.3836 76.9617 46.2342 75.1111L99.2549 22.0904Z" fill="#7766DF"/>
                <path d="M82.6472 19.3035H18C8.05888 19.3035 0 27.3624 0 37.3035V131.304C0 141.245 8.05887 149.304 18 149.304H112C121.941 149.304 130 141.245 130 131.304V65.5562L111 84.5562V130.304H19V38.3035H63.6472L82.6472 19.3035Z" fill="#7766DF"/>
            </svg>
		</div>
	);
})`
	display: flex;
`;