import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GetJourneyById } from "../redux/actions/AdvanceAutomation/JourneyAction";

export default function useAdvanceAutomation() {
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState(null);
  const [journeyData, setJourneyData] = useState([]);
  const { journeyDetails } = useSelector((state) => state.journey);
  
  const handleDetails = async (id) => {
    try {
      dispatch(GetJourneyById(id))
      setJourneyData(journeyDetails);
      setSelectedRow(journeyDetails);
    } catch (error) {
      console.error("Error fetching journey details:", error);
    }
  };

  const clearSelection = () => {
    setSelectedRow(null);
  };

  return {
    handleDetails,
    selectedRow,
    clearSelection,
    journeyData // Include this to use the fetched data in Details page
  };
}