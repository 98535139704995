import { makeStyles } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { Typography, OutlinedInput } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

export const useStyles = makeStyles({
    main: {
        margin: "20px",
    },
    bodyHeader: {
        borderBottom: "1px solid #DCD9D9",
        padding: '20px 14px',
    },
    card: {
        padding: '10px 20px',
    },
    filterButton: {
        margin: "35px 20px 10px",
        backgroundColor: "#40ACFB",
        color: "#FFFFFF",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "22px",
        lineHeight: "44px",
    },
    paper: {
        border: "1px solid #DCD9D9",
        borderRadius: "10px",
        marginTop: "10px",
        marginRight: "20px",
        marginLeft: "20px",
        cursor: "pointer",
        '&:hover': {
            border: '2px solid rgb(101, 77, 247)'
        }
    },
    paperActive: {
        border: "2px solid rgb(101, 77, 247)",
        borderRadius: "10px",
        marginTop: "10px",
        marginRight: "20px",
        marginLeft: "20px",
        cursor: "pointer",
        backgroundColor: 'rgb(101, 77, 247)'
    },
    activeColor: {
        color: 'white',
    },
    infoContainer: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-start",
    },
    paperColumn: {
        width: "33.33%",
        marginTop: "20px",
        marginBottom: "20px",
    },
    paperGrid: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    infoGridHeader: {
        width: "100%",
        padding: "10px 10px",        
    },
    header: {
        backgroundColor: "#c4c4c4",
        padding: "20px 0px 20px 30px",
    },
    infoHeader: {
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "21px",
        fontFamily: "Lexend Deca",
    },
    divider: {
        margin: "10px ​0 4px 0  !important",
        height: "4px !important",
    },
    searchHeading: {
        // fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "20px",
        lineHeight: "44px",
        color: "#727272",
        marginBottom: "5px",
    },
    savedSerchesClass: {
        backgroundColor: "transparent",
    },
    infoGrid: {
        height: "345px",
        width: "100%",
        overflowY: "scroll",
        paddingTop: "20px",
        "&::-webkit-scrollbar-track": {
            width: "40px",
            backgroundColor: "rgba(245, 245, 245, 0.75)",
            margin: "15px 0",
            scrollMargin: "20px",
        },
        "&::-webkit-scrollbar": {
            width: "10px",
            margin: "0 20px",
            scrollMargin: "20px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#E7E6E6",
            borderRadius: "10px",
            margin: "0 20px",
            scrollMargin: "20px",
        },
    },
    customFontSize: {
        fontSize: '14px', // Adjust font size as needed
    },
    togglebox: {
        marginLeft: "10px",
        marginTop: "30px"
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'row', // Ensure radio buttons are side by side
        alignItems: 'center',
    },
    radioLabel: {
        marginLeft: "10px",
    },
    radioButtonLabel: {
        fontSize: '10px',
    },
    checkedRadio: {
        color: "#654DF7", // Color when checked
    },
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    optionsContainer: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: "5px",
    },
});

export const useStylesAdvan = makeStyles((theme) => ({
    main: {
        margin: "20px",
        padding: "10px 22px",
    },
    header: {
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "21px",
        padding: "20px 26px",
        paddingBottom: theme.spacing(2),
    },
    button: {
        height: "40px",
        minWidth: "110px",
        display: "flex",
        textTransform: "none !important",
        backgroundColor: "rgb(101, 77, 247)",
        color: "#fff",
    },
    inputError: {
        color: "red",
        textAlign: "left",
        fontSize: "14px !important",
    },
    input: {
        display: "flex",
        flexDirection: "row",
    },
    customBackgroung: {
        backgroundColor: "rgb(242, 242, 242)",
        border: "none",
    },
    customheight: {
        height: "40px",
    },
    customFontSize: {
        fontSize: "14px !important",
    },
    textField: {
        "& .MuiOutlinedInput-root": {
            borderColor: "transparent !important",
            "&:hover fieldset": {
                borderColor: "#654DF7",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#654DF7",
            },
        },
    },
    label: {
        alignSelf: "center",
        padding: "10px 10px",
    },
    ButtonContainer: {
        display: "flex",
        justifyContent: "right",
        alignItems: "center",
        padding: "10px",
        // borderTop: "1px solid #ccc",
        gap: "8px",
    },
    highlight: {
        fontSize: "20px",
        fontWeight: "600",
        fontFamily: 'Lexend Deca',
        color: "#00000099"
    },
    content: {
        padding: 0,
    },
    innerCard: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'top',
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
        border: `1px solid #DADADA`,
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: "calc(100% - 32px)",
        minHeight: 'calc(100vh - 400px)',
        maxHeight: 'calc(100vh - 400px)',
        overflow: 'auto',
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 440px)',
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        border: `1px solid #DADADA`,
    },
    fillSpace: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'stretch',
        paddingTop: theme.spacing(2),
    },
    iconsContainer: {
        display: 'flex',
        gap: '1px',
        marginTop: theme.spacing(1),
    },
    iconButton: {
        border: '2px solid transparent',
        borderRadius: '8px',
        padding: '8px',
        minWidth: 'auto',
        transition: 'border-color 0.3s ease',
        '&:hover': {
        borderColor: theme.palette.primary.main,
        },
    },
    selectedIcon: {
        borderColor: theme.palette.primary.main,
    },
    lasttwomargin: {
        marginBottom: '20px', /* Adjust the value as needed */
      }
}));
export const StyledTypography = styled(Typography)(({ theme }) => ({
    fontFamily: 'Lexend Deca',
    fontSize: '14px'
    // Add other styles as needed
}));
export const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
    fontFamily: "Lexend Deca",
    fontSize: "14px",
    height: "40px",
    backgroundColor: "rgb(242, 242, 242)",
    width: "calc(100vw-900px)",
    borderColor: "rgb(242, 242, 242)", // Default border color
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: "rgb(242, 242, 242)", // Border color for the outline
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#654DF7', // Border color on hover
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#654DF7', // Border color when focused
    },
  // Add other styles as needed
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        // backgroundColor: "rgb(242, 242, 242)",
        color: "#5c5c5c",
        padding: "0px 20px",
        fontFamily: "Lexend Deca",
        fontSize: "12px",
        borderTop: "1px solid rgb(217, 217, 217)",
        lineHeight: "18px",
    },
    [`&.${tableCellClasses.body}`]: {
        padding: "5px 20px",
        fontSize: "12px",
        lineHeight: "18px",
        letterSpacing: "normal",
        fontFamily: `"Lexend Deca"`,
    },
}));
  