import { styled } from '@mui/material/styles';

export const AlertIcon = styled((props) => {
	const { className } = props;

	return (
		<div className={className}>
			<svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.9702 14.858C10.9702 14.7219 10.9971 14.5872 11.0494 14.4616C11.1019 14.3359 11.1785 14.2219 11.2752 14.1261C11.3719 14.0304 11.4866 13.9547 11.6127 13.9034C11.7388 13.8522 11.8737 13.8264 12.0098 13.8276C12.2106 13.8313 12.4058 13.8939 12.5711 14.0079C12.7363 14.1219 12.8645 14.2822 12.9392 14.4685C13.014 14.6549 13.032 14.8591 12.9914 15.0557C12.9506 15.2523 12.8528 15.4325 12.7102 15.574C12.5676 15.7153 12.3866 15.8114 12.1896 15.8504C11.9926 15.8894 11.7885 15.8695 11.6028 15.793C11.4171 15.7166 11.2581 15.5872 11.1456 15.4209C11.0331 15.2546 10.9721 15.0588 10.9702 14.858ZM11.3013 12.3464L11.1725 7.54402C11.1603 7.42857 11.1725 7.31185 11.2084 7.20144C11.2442 7.09101 11.3028 6.98934 11.3804 6.90304C11.4581 6.81674 11.553 6.74774 11.659 6.7005C11.7651 6.65326 11.8799 6.62885 11.9959 6.62885C12.112 6.62885 12.2268 6.65326 12.3329 6.7005C12.4389 6.74774 12.5338 6.81674 12.6115 6.90304C12.6891 6.98934 12.7477 7.09101 12.7835 7.20144C12.8194 7.31185 12.8317 7.42857 12.8194 7.54402L12.6998 12.3464C12.6998 12.5318 12.6262 12.7097 12.495 12.8408C12.3639 12.9719 12.186 13.0456 12.0006 13.0456C11.8151 13.0456 11.6373 12.9719 11.5062 12.8408C11.3751 12.7097 11.3013 12.5318 11.3013 12.3464Z" fill="#646464"/>
<path d="M12.0002 19.78C16.7001 19.78 20.5102 15.9699 20.5102 11.27C20.5102 6.57007 16.7001 2.76001 12.0002 2.76001C7.30029 2.76001 3.49023 6.57007 3.49023 11.27C3.49023 15.9699 7.30029 19.78 12.0002 19.78Z" stroke="#646464" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

		</div>
	);
})`
	display: flex;
`;