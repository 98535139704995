import React from "react";
import { styled } from "@mui/material/styles";
import chromeIcon from './chrome.png';

export const BrowserChrome = styled(({ className, size = 50 }) => {
  return (
    <div className={className}>
      <img
        src={chromeIcon}
        alt="Chrome Logo"
        style={{ height: `${size}px`, width: `${size}px` }} // Dynamic size
      />
    </div>
  );
})`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;
