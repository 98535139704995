import { GET_SELECTOR_LIST } from "../../actions/testlab/selectorAction";

const initialState = {
  selectorList: [],
};

const selectorReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SELECTOR_LIST:
      return {
        ...state,
        selectorList: action.payload,
      };
    default:
      return state;
  }
};

export default selectorReducer;
