import { styled } from '@mui/material/styles';

export const Details = styled((props) => {
	const { className } = props;

	return (
		<div className={className}>
			<svg width="52" height="67" viewBox="0 0 52 67" fill="none" style={{ height: '20px', width: '20px' }} xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M45 7H36.9119C35.0159 2.86894 30.843 0 26 0C21.157 0 16.9841 2.86894 15.0881 7H7C3.13401 7 0 10.134 0 14V60C0 63.866 3.13401 67 7 67H45C48.866 67 52 63.866 52 60V14C52 10.134 48.866 7 45 7ZM17 35.5C17 37.433 15.433 39 13.5 39C11.567 39 10 37.433 10 35.5C10 33.567 11.567 32 13.5 32C15.433 32 17 33.567 17 35.5ZM13.5 52C15.433 52 17 50.433 17 48.5C17 46.567 15.433 45 13.5 45C11.567 45 10 46.567 10 48.5C10 50.433 11.567 52 13.5 52ZM22 35.5C22 33.567 23.567 32 25.5 32H39.5C41.433 32 43 33.567 43 35.5C43 37.433 41.433 39 39.5 39H25.5C23.567 39 22 37.433 22 35.5ZM25.5 45C23.567 45 22 46.567 22 48.5C22 50.433 23.567 52 25.5 52H39.5C41.433 52 43 50.433 43 48.5C43 46.567 41.433 45 39.5 45H25.5ZM26.075 16.4C28.4636 16.4 30.4 14.4636 30.4 12.075C30.4 9.68637 28.4636 7.75 26.075 7.75C23.6864 7.75 21.75 9.68637 21.75 12.075C21.75 14.4636 23.6864 16.4 26.075 16.4Z" fill="#7766DF"/>
            </svg>
		</div>
	);
})`
	display: flex;
`;