import { styled } from '@mui/material/styles';

export const LogoutIcon = styled((props) => {
	const { className } = props;

	return (
		<div className={className}>
			<svg
				width='18'
				height='18'
				viewBox='0 0 18 18'
				fill='none'>
				<path
					d='M0.527342 9.59767L6.43359 15.5039C6.96094 16.0313 7.875 15.6621 7.875 14.9063V11.5313H12.6562C13.1238 11.5313 13.5 11.1551 13.5 10.6875V7.31252C13.5 6.84494 13.1238 6.46877 12.6562 6.46877H7.875V3.09377C7.875 2.34142 6.96445 1.96877 6.43359 2.49611L0.527342 8.40236C0.200388 8.73283 0.200388 9.2672 0.527342 9.59767ZM11.25 15.3281V13.9219C11.25 13.6899 11.4398 13.5 11.6719 13.5H14.625C15.2473 13.5 15.75 12.9973 15.75 12.375V5.62502C15.75 5.00275 15.2473 4.50002 14.625 4.50002H11.6719C11.4398 4.50002 11.25 4.31017 11.25 4.07814V2.67189C11.25 2.43986 11.4398 2.25002 11.6719 2.25002H14.625C16.4883 2.25002 18 3.76173 18 5.62502V12.375C18 14.2383 16.4883 15.75 14.625 15.75H11.6719C11.4398 15.75 11.25 15.5602 11.25 15.3281Z'
					fill='#F74D4D'
				/>
			</svg>
		</div>
	);
})`
	display: flex;
`;