import React from "react";

export const CompleteIcon = ({ isActive, color }) => {
  return (
    <div style={{ color: isActive ? color : "inherit" }}>
    <svg width="297" height="299" viewBox="0 0 297 299" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M33.8316 141.784L5.76562 140.121L5.64689 142.124L33.7129 143.787L33.8316 141.784Z" fill="#654DF7"/>
    <path d="M292.027 157.088L263.961 155.424L263.842 157.428L291.908 159.091L292.027 157.088Z" fill="#654DF7"/>
    <path d="M33.5929 151.854L5.48828 152.628L5.54355 154.634L33.6481 153.86L33.5929 151.854Z" fill="#654DF7"/>
    <path d="M292.132 144.585L264.027 145.374L264.084 147.38L292.188 146.591L292.132 144.585Z" fill="#654DF7"/>
    <path d="M34.2181 161.874L6.28906 165.104L6.51965 167.098L34.4487 163.867L34.2181 161.874Z" fill="#654DF7"/>
    <path d="M291.151 132.108L263.223 135.343L263.454 137.337L291.382 134.101L291.151 132.108Z" fill="#654DF7"/>
    <path d="M35.7303 171.809L0.265186 178.379L0.668853 180.343L36.1339 173.774L35.7303 171.809Z" fill="#654DF7"/>
    <path d="M289.087 119.782L261.547 125.439L261.951 127.405L289.491 121.748L289.087 119.782Z" fill="#654DF7"/>
    <path d="M38.0986 181.584L11.1562 189.62L11.7298 191.543L38.6722 183.507L38.0986 181.584Z" fill="#654DF7"/>
    <path d="M285.95 107.671L259.008 115.707L259.581 117.63L286.524 109.594L285.95 107.671Z" fill="#654DF7"/>
    <path d="M41.3035 191.113L15.1641 201.466L15.9031 203.332L42.0425 192.979L41.3035 191.113Z" fill="#654DF7"/>
    <path d="M281.772 95.8798L255.633 106.233L256.372 108.099L282.511 97.7456L281.772 95.8798Z" fill="#654DF7"/>
    <path d="M45.329 200.332L20.1914 212.925L21.0902 214.719L46.2278 202.127L45.329 200.332Z" fill="#654DF7"/>
    <path d="M276.591 84.4975L251.453 97.0898L252.352 98.8841L277.49 86.2917L276.591 84.4975Z" fill="#654DF7"/>
    <path d="M50.1256 209.177L26.1914 223.929L27.2444 225.637L51.1785 210.885L50.1256 209.177Z" fill="#654DF7"/>
    <path d="M270.429 73.5986L246.484 88.334L247.536 90.0431L271.481 75.3078L270.429 73.5986Z" fill="#654DF7"/>
    <path d="M55.7158 217.533L20.8899 243.523L22.0846 245.134L56.9105 219.144L55.7158 217.533Z" fill="#654DF7"/>
    <path d="M263.346 63.3017L240.777 80.0679L241.974 81.6788L264.543 64.9126L263.346 63.3017Z" fill="#654DF7"/>
    <path d="M61.9946 225.393L40.9727 244.062L42.3052 245.563L63.3272 226.894L61.9946 225.393Z" fill="#654DF7"/>
    <path d="M255.397 53.6665L234.375 72.3359L235.708 73.8364L256.729 55.167L255.397 53.6665Z" fill="#654DF7"/>
    <path d="M68.9242 232.672L49.6094 253.103L51.0677 254.482L70.3824 234.051L68.9242 232.672Z" fill="#654DF7"/>
    <path d="M246.631 44.7394L227.316 65.1699L228.775 66.5486L248.089 46.118L246.631 44.7394Z" fill="#654DF7"/>
    <path d="M76.4711 239.325L59.0122 261.364L60.5888 262.607L78.0377 240.568L76.4711 239.325Z" fill="#654DF7"/>
    <path d="M237.117 36.6245L219.656 58.6606L221.229 59.9069L238.69 37.8708L237.117 36.6245Z" fill="#654DF7"/>
    <path d="M60.2267 279.161L61.9012 280.271L86.2425 246.398L84.568 245.288L60.2267 279.161Z" fill="#654DF7"/>
    <path d="M226.916 29.3371L211.442 52.814L213.117 53.9134L228.59 30.4465L226.916 29.3371Z" fill="#654DF7"/>
    <path d="M216.146 23.0049L202.777 47.7383L204.543 48.6925L217.912 23.9591L216.146 23.0049Z" fill="#654DF7"/>
    <path d="M204.865 17.6164L193.703 43.4209L195.545 44.2176L206.707 18.4132L204.865 17.6164Z" fill="#654DF7"/>
    <path d="M194.983 4.97104L184.25 39.9107L186.163 40.5442L196.896 5.59453L194.983 4.97104Z" fill="#654DF7"/>
    <path d="M174.557 37.2375L176.51 37.7033L183.022 10.3548L181.069 9.88896L174.557 37.2375Z" fill="#654DF7"/>
    <path d="M164.676 35.4191L166.658 35.7166L170.757 7.90496L168.774 7.61738L164.676 35.4191Z" fill="#654DF7"/>
    <path d="M154.661 34.4757L156.664 34.5944L158.327 6.5284L156.324 6.41964L154.661 34.4757Z" fill="#654DF7"/>
    <path d="M143.169 0.179399L144.601 34.4098L146.605 34.3486L145.183 0.118736L143.169 0.179399Z" fill="#654DF7"/>
    <path d="M131.339 7.29072L134.584 35.2176L136.578 34.9857L133.343 7.05945L131.339 7.29072Z" fill="#654DF7"/>
    <path d="M119.004 9.35977L124.663 36.8996L126.627 36.4959L120.979 8.94673L119.004 9.35977Z" fill="#654DF7"/>
    <path d="M106.893 12.4934L114.928 39.4339L116.852 38.8578L108.817 11.9173L106.893 12.4934Z" fill="#654DF7"/>
    <path d="M95.1054 16.6735L105.459 42.8013L107.322 42.0717L96.9702 15.9239L95.1054 16.6735Z" fill="#654DF7"/>
    <path d="M81.6083 15.5247L96.316 46.9914L98.1092 46.0975L83.4022 14.6209L81.6083 15.5247Z" fill="#654DF7"/>
    <path d="M89.284 50.9136L74.5508 26.9666L72.8377 28.0253L87.5716 51.9623L89.284 50.9136Z" fill="#654DF7"/>
    <path d="M64.1502 33.9198L62.5391 35.1162L79.3013 57.6882L80.9124 56.4917L64.1502 33.9198Z" fill="#654DF7"/>
    <path d="M54.3794 41.7084L52.8789 43.041L71.5483 64.0629L73.0488 62.7304L54.3794 41.7084Z" fill="#654DF7"/>
    <path d="M45.3279 50.3576L43.9492 51.8159L64.3798 71.1307L65.7584 69.6724L45.3279 50.3576Z" fill="#654DF7"/>
    <path d="M59.1193 77.231L30.1462 54.7603L28.9025 56.3369L57.8657 78.8069L59.1193 77.231Z" fill="#654DF7"/>
    <path d="M29.6841 69.8249L28.5781 71.4995L52.0387 86.9936L53.1447 85.319L29.6841 69.8249Z" fill="#654DF7"/>
    <path d="M47.9228 93.9112L23.1809 80.5428L22.2356 82.3171L46.9581 95.6743L47.9228 93.9112Z" fill="#654DF7"/>
    <path d="M43.4436 102.917L17.6408 91.7561L16.8415 93.599L42.6443 104.76L43.4436 102.917Z" fill="#654DF7"/>
    <path d="M39.7828 112.282L13.1042 103.409L12.4707 105.322L39.1499 114.184L39.7828 112.282Z" fill="#654DF7"/>
    <path d="M36.9373 121.934L1.75292 112.967L1.2971 114.921L36.4715 123.887L36.9373 121.934Z" fill="#654DF7"/>
    <path d="M7.16042 127.703L6.86719 129.688L34.6807 133.796L34.9739 131.811L7.16042 127.703Z" fill="#654DF7"/>
    <path d="M81.5921 275.76L94.9609 251.026L93.1955 250.072L79.8267 274.806L81.5921 275.76Z" fill="#654DF7"/>
    <path d="M92.869 281.146L104.031 255.342L102.189 254.545L91.0271 280.35L92.869 281.146Z" fill="#654DF7"/>
    <path d="M102.752 293.79L113.484 258.85L111.571 258.217L100.838 293.166L102.752 293.79Z" fill="#654DF7"/>
    <path d="M123.177 261.525L121.224 261.059L114.713 288.408L116.665 288.874L123.177 261.525Z" fill="#654DF7"/>
    <path d="M133.063 263.344L131.08 263.046L126.981 290.858L128.964 291.145L133.063 263.344Z" fill="#654DF7"/>
    <path d="M143.081 264.285L141.078 264.166L139.415 292.232L141.419 292.341L143.081 264.285Z" fill="#654DF7"/>
    <path d="M154.569 298.583L153.138 264.353L151.134 264.414L152.555 298.644L154.569 298.583Z" fill="#654DF7"/>
    <path d="M166.4 291.472L163.154 263.545L161.16 263.777L164.396 291.703L166.4 291.472Z" fill="#654DF7"/>
    <path d="M178.734 289.403L173.076 261.863L171.111 262.267L176.76 289.816L178.734 289.403Z" fill="#654DF7"/>
    <path d="M190.845 286.272L182.811 259.331L180.886 259.907L188.921 286.848L190.845 286.272Z" fill="#654DF7"/>
    <path d="M202.633 282.087L192.279 255.959L190.416 256.689L200.768 282.837L202.633 282.087Z" fill="#654DF7"/>
    <path d="M216.126 283.238L201.418 251.771L199.625 252.665L214.332 284.142L216.126 283.238Z" fill="#654DF7"/>
    <path d="M208.454 247.849L223.188 271.796L224.901 270.737L210.167 246.8L208.454 247.849Z" fill="#654DF7"/>
    <path d="M233.584 264.843L235.195 263.647L218.433 241.075L216.822 242.271L233.584 264.843Z" fill="#654DF7"/>
    <path d="M243.359 257.054L244.859 255.722L226.19 234.7L224.689 236.032L243.359 257.054Z" fill="#654DF7"/>
    <path d="M252.41 248.407L253.789 246.949L233.359 227.634L231.98 229.092L252.41 248.407Z" fill="#654DF7"/>
    <path d="M238.619 221.532L267.592 244.002L268.836 242.426L239.873 219.956L238.619 221.532Z" fill="#654DF7"/>
    <path d="M268.058 228.938L269.164 227.263L245.703 211.769L244.598 213.444L268.058 228.938Z" fill="#654DF7"/>
    <path d="M249.819 204.852L274.561 218.22L275.507 216.446L250.784 203.088L249.819 204.852Z" fill="#654DF7"/>
    <path d="M254.295 195.846L280.097 207.007L280.897 205.164L255.094 194.003L254.295 195.846Z" fill="#654DF7"/>
    <path d="M257.959 186.483L284.638 195.356L285.271 193.443L258.592 184.58L257.959 186.483Z" fill="#654DF7"/>
    <path d="M260.801 176.827L295.985 185.793L296.441 183.84L261.267 174.874L260.801 176.827Z" fill="#654DF7"/>
    <path d="M290.578 171.058L290.871 169.073L263.058 164.965L262.764 166.95L290.578 171.058Z" fill="#654DF7"/>
    <circle cx="148.93" cy="149.588" r="100.34" fill="#654DF7"/>
    <path d="M92 145.216L138.941 188L206 112" stroke="white" stroke-width="20" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M204.392 65.9687C186.733 54.2563 165.82 48.4349 144.65 49.3389L144.855 54.1398C165.011 53.2791 184.923 58.8217 201.736 69.9732L204.392 65.9687Z" fill="#654DF7"/>
    </svg>
    </div>
  );
};