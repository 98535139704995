import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTableStyles, StyledTableCell } from "./styles";
import { Edit as EditIcon, Delete as DeleteIcon } from "@material-ui/icons";
import DeleteModal from "../Modal/DeleteModal";
import { CustomTable_variable } from "./CustomTable_variable";
import { environment_variable_set } from "../../../../../redux/actions/settingAction";
import { useNavigate, useLocation } from 'react-router-dom';
import { getEnviornment_Variableset_value } from "../../../../../redux/actions/settingAction";
import { useDispatch } from "react-redux";

export function CustomTable({ rows, handleEditUser }) {
  const classes = useTableStyles();
  const [openDelModal, setopenDelModal] = useState(false);
  const [item, setitem] = useState(null);
  const [AppOrBrow, setAppOrBrow] = useState("enviornment_variable");
  const [valueTable, setvalueTable] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const handleDelete = (row) => {
    setopenDelModal(true);
    setitem(row);
  };

  const handleEdit = (row) => {
    handleEditUser(row);
  };

  const handleNameClick = (row) => {
    // Get the current URL path
    localStorage.setItem('selectedRow', JSON.stringify(row));    
    dispatch(getEnviornment_Variableset_value(row));

    const currentPath = location.pathname;

    // Construct the new URL path
    const newUrl = `${currentPath}/value`;

    // Use navigate to push the new URL without refreshing the page
    navigate(newUrl);
  };

  return (
    <>
      <DeleteModal
        open={openDelModal}
        onClose={() => setopenDelModal(false)}
        item={item}
        types={AppOrBrow}
      />
      <TableContainer sx={{ marginBottom: "8vh" }}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => (
              <TableRow
                key={row.id}
                className={`${classes.tableRow}`}
                style={{ height: "10px" }}
              >
                <StyledTableCell
                  component="th"
                  scope="row"
                  style={{ cursor: "pointer", color: "blue" }}
                  onClick={() => handleNameClick(row)} // Add onClick to row.name
                >
                  {row.name}
                </StyledTableCell>
                <StyledTableCell>
                  <EditIcon
                    onClick={() => handleEdit(row)}
                    style={{
                      cursor: "pointer",
                      marginRight: "10px",
                      color: "rgb(101, 77, 247)",
                    }}
                  />
                  <DeleteIcon
                    onClick={() => handleDelete(row)}
                    style={{
                      cursor: "pointer",
                      marginRight: "10px",
                      color: "#F64E4E",
                    }}
                  />
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
