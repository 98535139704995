import React from "react";

export const CloseIcon = ({ isActive, color }) => {
  return (
    <div style={{ color: isActive ? color : "inherit" }}>
     <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1 10.9997C1.00034 6.22924 4.37046 2.12335 9.04934 1.19299C13.7282 0.262639 18.4127 2.76694 20.238 7.17437C22.0634 11.5818 20.5211 16.6648 16.5544 19.3149C12.5878 21.965 7.30149 21.4441 3.92842 18.0707C2.0532 16.1953 0.999809 13.6518 1 10.9997Z" stroke="#646464" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.71989 14.218C6.42694 14.5109 6.42685 14.9857 6.71969 15.2787C7.01253 15.5716 7.4874 15.5717 7.78035 15.2789L6.71989 14.218ZM11.5302 11.5305C11.8231 11.2377 11.8232 10.7628 11.5304 10.4699C11.2375 10.1769 10.7627 10.1768 10.4697 10.4697L11.5302 11.5305ZM10.4693 10.4706C10.1765 10.7636 10.1767 11.2385 10.4697 11.5313C10.7627 11.824 11.2375 11.8239 11.5303 11.5309L10.4693 10.4706ZM15.2787 7.77964C15.5715 7.48664 15.5713 7.01176 15.2783 6.71898C14.9853 6.4262 14.5104 6.42638 14.2176 6.71938L15.2787 7.77964ZM11.53 10.4696C11.237 10.1767 10.7622 10.1768 10.4693 10.4698C10.1765 10.7627 10.1766 11.2376 10.4695 11.5304L11.53 10.4696ZM14.2193 15.2788C14.5123 15.5716 14.9872 15.5715 15.28 15.2786C15.5728 14.9856 15.5727 14.5107 15.2798 14.2179L14.2193 15.2788ZM10.4694 11.531C10.7622 11.8239 11.2371 11.824 11.53 11.5312C11.823 11.2383 11.8231 10.7634 11.5302 10.4705L10.4694 11.531ZM7.78043 6.71928C7.48759 6.42633 7.01272 6.42624 6.71977 6.71908C6.42682 7.01192 6.42673 7.48679 6.71957 7.77974L7.78043 6.71928ZM7.78035 15.2789L11.5302 11.5305L10.4697 10.4697L6.71989 14.218L7.78035 15.2789ZM11.5303 11.5309L15.2787 7.77964L14.2176 6.71938L10.4693 10.4706L11.5303 11.5309ZM10.4695 11.5304L14.2193 15.2788L15.2798 14.2179L11.53 10.4696L10.4695 11.5304ZM11.5302 10.4705L7.78043 6.71928L6.71957 7.77974L10.4694 11.531L11.5302 10.4705Z" fill="#646464"/>
    </svg>
    </div>
  );
};
