import { styled } from '@mui/material/styles';

export const TestLabIcon = styled(({ className, isActive, color }) => {
	return (
		<div style={{ color: isActive ? color : 'inherit' }}>
            <svg width="40" height="40" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="31.2999" cy="30.8932" rx="30.6881" ry="30.6881" fill={isActive ? "white" : "#7766DF"}/>
            <path d="M26.0219 28.5371V20.7461H29.16V29.1864L26.7794 33.623H35.2197L32.8391 29.1864V20.7461H35.9771V28.5371L41.9075 37.8692C43.464 40.3185 41.7045 43.5215 38.8023 43.5215H23.1967C20.2946 43.5215 18.535 40.3185 20.0915 37.8692L26.0219 28.5371Z" fill={isActive ? "#7766DF" : "white"}/>
            <rect x="24.2905" y="17.7168" width="13.2015" height="3.02985" rx="1.08209" fill={isActive ? "#7766DF" : "white"}/>
            </svg>
		</div>
	);
})`
	display: flex;
`;