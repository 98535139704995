import { styled } from '@mui/material/styles';

export const LocalTestingIcon = styled(({ className, isActive, color }) => {
	return (
		<div style={{ color: isActive ? color : 'inherit' }}>
            <svg width="40" height="40" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="31.2999" cy="31.0612" rx="30.6881" ry="30.6881" fill={isActive ? "white" : "#7766DF"}/>
            <path d="M19.3994 22.6873C19.3994 21.7263 20.1784 20.9473 21.1394 20.9473H40.8594C41.8204 20.9473 42.5994 21.7263 42.5994 22.6873V35.2539H39.7093V23.8473H22.2994V35.2539H19.3994V22.6873Z" fill={isActive ? "#7766DF" : "white"}/>
            <path d="M16.4995 37.3808C16.4995 37.0605 16.7592 36.8008 17.0795 36.8008H44.9195C45.2398 36.8008 45.4995 37.0605 45.4995 37.3808V38.5408C45.4995 39.9289 44.3743 41.0541 42.9862 41.0541H19.0128C17.6248 41.0541 16.4995 39.9289 16.4995 38.5408V37.3808Z"fill={isActive ? "#7766DF" : "white"}/>
            </svg>
		</div>
	);
})`
	display: flex;
`;