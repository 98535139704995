import React from 'react'
import { styled } from '@mui/material/styles';

export const FunctionalTestLab = styled((props) => {
	const { className } = props;

	return (
		<div className={className}>
			<svg width="125" height="117" viewBox="0 0 125 117" fill="none" style={{ height: '20px', width: '20px' }} xmlns="http://www.w3.org/2000/svg">
                <path d="M43.0859 48.9591V13.707H57.2847V51.8968L46.5132 71.9709H84.703L73.9315 51.8968V13.707H88.1303V48.9591L110.142 83.648C110.514 84.2341 110.093 85 109.399 85C107.835 85 106.306 85.463 105.004 86.3306L104 87L102.266 88.1559C101.457 88.6951 100.463 88.8808 99.5145 88.6699C97.7245 88.2721 95.885 89.0932 94.986 90.6915L93.2968 93.6947C92.471 95.1627 92.3038 96.9113 92.8364 98.5093C92.9452 98.8356 93.086 99.1504 93.2566 99.4491L93.671 100.174C94.4751 101.582 94.3503 103.335 93.3552 104.615C92.2575 106.026 92.2294 107.995 93.2867 109.436L95.9577 113.079C96.6011 113.956 97.6693 114.416 98.749 114.281L101 114H103C103.552 114 104 114.448 104 115V115.759C104 116.311 103.552 116.759 103 116.759H6.69717C3.79506 116.759 2.03547 113.556 3.59202 111.107L43.0859 48.9591Z" fill="#7766DF"/>
                <rect x="35.252" width="59.7327" height="13.7092" rx="4" fill="#7766DF"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M110 107C107.261 107 105.042 104.762 105.042 102C105.042 99.238 107.261 97 110 97C112.739 97 114.959 99.238 114.959 102C114.959 104.762 112.739 107 110 107ZM124.008 105.536L121.685 104.184C121.815 103.474 121.901 102.749 121.901 102C121.901 101.252 121.815 100.526 121.685 99.816L124.008 98.464C124.957 97.912 125.281 96.688 124.733 95.732L122.75 92.268C122.203 91.312 120.989 90.983 120.041 91.536L117.694 92.901C116.598 91.961 115.352 91.192 113.967 90.697V89C113.967 87.896 113.079 87 111.983 87H108.017C106.921 87 106.033 87.896 106.033 89V90.697C104.648 91.192 103.402 91.961 102.306 92.901L99.9585 91.536C99.0105 90.983 97.7964 91.312 97.2494 92.268L95.2663 95.732C94.7183 96.688 95.0433 97.912 95.9913 98.464L98.3144 99.816C98.1844 100.526 98.0984 101.252 98.0984 102C98.0984 102.749 98.1844 103.474 98.3144 104.184L95.9913 105.536C95.0433 106.088 94.7183 107.312 95.2663 108.268L97.2494 111.732C97.7964 112.688 99.0105 113.017 99.9585 112.464L102.306 111.099C103.402 112.039 104.648 112.809 106.033 113.303V115C106.033 116.104 106.921 117 108.017 117H111.983C113.079 117 113.967 116.104 113.967 115V113.303C115.352 112.809 116.598 112.039 117.694 111.099L120.041 112.464C120.989 113.017 122.203 112.688 122.75 111.732L124.733 108.268C125.281 107.312 124.957 106.088 124.008 105.536Z" fill="#7766DF"/>
            </svg>
		</div>
	);
})`
	display: flex;
`;