import { styled } from "@mui/material/styles";

export const TimeIcon = styled((props) => {
  const { className } = props;

  return (
    <div className={className}>
        <svg width="56" height="44" viewBox="0 0 56 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.273926" width="56" height="43.726" rx="4" fill="#FACE09" fill-opacity="0.1"/>
        <path d="M27.9989 7C19.7281 7 13 13.7292 13 22.0005C13 30.2713 19.7281 37 27.9989 37C36.2695 37 42.9988 30.2713 42.9988 22.0005C42.9988 13.7292 36.2694 7 27.9989 7ZM27.9989 34.9573C20.8548 34.9573 15.0424 29.1449 15.0424 22.0005C15.0424 14.8555 20.8548 9.04278 27.9989 9.04278C35.1436 9.04278 40.9561 14.8555 40.9561 22.0005C40.9561 29.1449 35.1437 34.9573 27.9989 34.9573Z" fill="#BF9C00"/>
        <path d="M27.9959 11.261C22.0749 11.261 17.2578 16.0788 17.2578 22.0005C17.2578 27.9217 22.0749 32.7391 27.9959 32.7391C28.9835 32.7391 29.7827 31.9384 29.7827 30.9519C29.7827 29.9648 28.9836 29.1646 27.9959 29.1646C24.0457 29.1646 20.8323 25.9505 20.8323 22.0005C20.8323 18.0495 24.0457 14.8354 27.9959 14.8354C31.946 14.8354 35.1605 18.0495 35.1605 22.0005C35.1605 22.9875 35.9609 23.7877 36.948 23.7877C37.9356 23.7877 38.735 22.9875 38.735 22.0005C38.735 16.0788 33.9182 11.261 27.9959 11.261Z" fill="#BF9C00"/>
        <path d="M30.0838 22.0904C30.0848 22.0596 30.0926 22.031 30.0926 22.0002C30.0926 20.8436 29.1551 19.9045 27.9969 19.9045C26.8396 19.9045 25.9023 20.8436 25.9023 22.0002C25.9023 23.1579 26.8396 24.0959 27.9969 24.0959C28.0351 24.0959 28.0695 24.0865 28.1077 24.0845L34.1307 29.1309C34.4083 29.3648 34.824 29.3707 35.1084 29.126C35.4261 28.8544 35.4634 28.3768 35.1917 28.0591L30.0838 22.0904Z" fill="#BF9C00"/>
        </svg>

    </div>
  );
})`
  display: flex;
`;