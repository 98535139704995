import axios from "axios";
import { toast } from "react-toastify";
import { header } from "../../../utils/authheader";
import { getBaseUrl } from "../../../utils/configService";
export const TEST_RUN = "TEST_RUN";



export const AddTestRunDetail = async (payload, goBack) => {
    try {
        const BASE_URL = await getBaseUrl();
        const res = await axios.post(
            `${BASE_URL}/AddTestLab/SaveUpdateTestLabTestRun`,
            payload,
            header()
        ); // to add testcase name and return testcase id
        console.log("response_testrun ", res.data);
        const Data = res.data.Data;
        if (res.data.status === "success") {
           
            {
                goBack();
                toast.info("Successfully saved", {
                    style: {
                        background: "rgb(101, 77, 247)",
                        color: "rgb(255, 255, 255)",
                    },
                });
            }
        } else {
            toast.error(res.data.message)
        }
    } catch (error) {
        console.log("error saving ", error);
        toast.error("Network error");
    }
};


export const fetchTestRun = (selectedNodeId) => async (dispatch) => {
  
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/AddTestLab/GetTestRunListByRootId?RootId=${selectedNodeId}`,
        header()
      );
  
      if (response.data.status === "fail" || response.data === "") {
        dispatch({ type: TEST_RUN, payload: [] });
      } else {
        const reversedTestCaseList = response.data.reverse();
        dispatch({
          type: TEST_RUN,
          payload: reversedTestCaseList,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };