import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField"; 
import { useDispatch, useSelector } from "react-redux";
import { getBaseUrl } from "../../../../utils/configService";
import { header } from "../../../../utils/authheader";
import axios from "axios";
import { UpdateJourneyName, DeleteJourney } from "../../../../redux/actions/AdvanceAutomation/JourneyAction";

function JourneyModal({ open, onClose, journey, isDeleteMode }) {
  const dispatch = useDispatch();
  const [journeyName, setJourneyName] = useState(""); 
  const [loading, setLoading] = useState(false); 
  const { userId } = useSelector((state) => state.auth);

  // Whenever the modal opens and journey is passed, update the journeyName
  useEffect(() => {
    if (journey) {
      setJourneyName(journey.ApplicationName || ""); // Pre-fill with existing journey name
    }
  }, [journey]); // Only run when the journey prop changes

  // Handle journey deletion
  const handleDelete = async () => {
    try {
      setLoading(true);
      try {
        const BASE_URL = await getBaseUrl();
        const response = await axios.get(
          `${BASE_URL}/AdvanceAutomation/GetAutomationJourneyById?id=${journey.id}`,
          header()
        ); 
      dispatch(DeleteJourney(journey.id, response.data.rootId));  // Dispatch delete action
      } catch {
        console.log("Error");
      }
      onClose();
    } catch (error) {
      console.error("Error deleting journey:", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle journey name update
  const handleSave = async () => {
    try {
      setLoading(true);
      try {
        const BASE_URL = await getBaseUrl();
        const response = await axios.get(
          `${BASE_URL}/AdvanceAutomation/GetAutomationJourneyById?id=${journey.id}`,
          header()
        ); 
      const payload = {
        name: journeyName,
        userId: userId, // New name
        rootId: response.data.rootId,
        clientReferenceId: response.data.clientReferenceId,
      };
      dispatch(UpdateJourneyName(payload, response.data.rootId));  // Dispatch update action
    } catch {
        console.log("Error");
      }
      onClose();
    } catch (error) {
      console.error("Error updating journey name:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {open && (
        <Dialog
          open={open}
          onClose={onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm" 
          fullWidth={true} 
        >
          <DialogTitle id="alert-dialog-title">
            {isDeleteMode ? "Delete Journey Confirmation" : "Edit Journey Name"}
          </DialogTitle>
          <DialogContent>
            {isDeleteMode ? (
              <DialogContentText id="alert-dialog-description">
                {`Are you sure you want to delete the journey "${journey.ApplicationName}"?`}
              </DialogContentText>
            ) : (
              <TextField
                autoFocus
                margin="dense"
                label="Journey Name"
                type="text"
                fullWidth
                value={journeyName} // Value is always the latest state of journeyName
                onChange={(e) => setJourneyName(e.target.value)} 
                helperText={journeyName === "" ? "Name for a journey cannot be empty." : ""}
                error={journeyName === ""} // Show error if name is empty
              />
            )}
          </DialogContent>
          <DialogActions style={{ justifyContent: "center", marginBottom: "20px" }}>
            <Button
              onClick={isDeleteMode ? handleDelete : handleSave}
              style={{
                marginRight: "10px",
                backgroundColor: "#654DF7",
                height: "30px",
                width: "100px",
                color: "white",
              }}
              disabled={loading || (!isDeleteMode && journeyName === "")} // Disable if loading or name is empty in edit mode
            >
              {loading
                ? isDeleteMode
                  ? "Deleting..."
                  : "Saving..."
                : isDeleteMode
                ? "Delete"
                : "Save"}
            </Button>
            <Button
              onClick={onClose}
              color="primary"
              style={{
                backgroundColor: "#6c757d",
                width: "100px",
                height: "30px",
                color: "white",
              }}
              disabled={loading}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

export default JourneyModal;
