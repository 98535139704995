
import { styled } from '@mui/material/styles';

export const AlertIcon_red = styled((props) => {
	const { className } = props;

	return (
		<div className={className}>
			<svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.9705 14.858C10.9705 14.7219 10.9973 14.5872 11.0497 14.4616C11.1021 14.3359 11.1787 14.2219 11.2754 14.1261C11.3721 14.0304 11.4869 13.9547 11.6129 13.9034C11.739 13.8522 11.8739 13.8264 12.0101 13.8276C12.2108 13.8313 12.406 13.8939 12.5714 14.0079C12.7366 14.1219 12.8647 14.2822 12.9394 14.4685C13.0142 14.6549 13.0323 14.8591 12.9916 15.0557C12.9509 15.2523 12.8531 15.4325 12.7105 15.574C12.5679 15.7153 12.3868 15.8114 12.1898 15.8504C11.9929 15.8894 11.7887 15.8695 11.6031 15.793C11.4173 15.7166 11.2583 15.5872 11.1458 15.4209C11.0333 15.2546 10.9723 15.0588 10.9705 14.858ZM11.3016 12.3464L11.1728 7.54402C11.1605 7.42857 11.1728 7.31185 11.2086 7.20144C11.2444 7.09101 11.303 6.98934 11.3807 6.90304C11.4583 6.81674 11.5533 6.74774 11.6593 6.7005C11.7653 6.65326 11.8802 6.62885 11.9962 6.62885C12.1123 6.62885 12.2271 6.65326 12.3332 6.7005C12.4391 6.74774 12.5341 6.81674 12.6117 6.90304C12.6894 6.98934 12.748 7.09101 12.7838 7.20144C12.8197 7.31185 12.8319 7.42857 12.8197 7.54402L12.7001 12.3464C12.7001 12.5318 12.6265 12.7097 12.4953 12.8408C12.3642 12.9719 12.1862 13.0456 12.0009 13.0456C11.8154 13.0456 11.6376 12.9719 11.5065 12.8408C11.3754 12.7097 11.3016 12.5318 11.3016 12.3464Z" fill="#F64E4E"/>
<path d="M12 19.78C16.6999 19.78 20.51 15.9699 20.51 11.27C20.51 6.57007 16.6999 2.76001 12 2.76001C7.30005 2.76001 3.48999 6.57007 3.48999 11.27C3.48999 15.9699 7.30005 19.78 12 19.78Z" stroke="#F64E4E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

		</div>
	);
})`
	display: flex;
`;