import React from 'react'
import { styled } from '@mui/material/styles';

export const InPrivateLocations = styled((props) => {
	const { className } = props;

	return (
		<div className={className}>
			<svg width="81" height="75" viewBox="0 0 81 75" fill="none" style={{ height: '20px', width: '20px' }} xmlns="http://www.w3.org/2000/svg">
                <path d="M0 33.0901C0 31.0764 1.20793 29.2593 3.06461 28.4798L18.5 22C19.978 26.3535 20.9454 28.8155 23 33V66.5L4.03995 73.507C2.08091 74.231 0 72.7815 0 70.693V33.0901Z" fill="#7766DF"/>
                <path d="M59.5 26.5L76.897 19.6221C78.866 18.8437 81 20.2947 81 22.412V60.8243C81 62.4437 80.0237 63.9032 78.527 64.5214L62.1452 71.2874C60.1703 72.1031 58 70.6514 58 68.5146V30.5C58.9384 28.9768 59.2694 28.1294 59.5 26.5Z" fill="#7766DF"/>
                <path d="M27 41.6707C27 40.7462 28.1469 40.3168 28.7541 41.0139L36.7296 50.171C38.7225 52.4591 42.2775 52.4591 44.2704 50.171L52.2459 41.0139C52.8531 40.3168 54 40.7462 54 41.6707V69.123C54 71.0855 52.1468 72.5195 50.2471 72.027L29.2471 66.5826C27.924 66.2395 27 65.0455 27 63.6786V41.6707Z" fill="#7766DF"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M40 25.5C36.0886 25.5 32.9167 22.143 32.9167 18C32.9167 13.857 36.0886 10.5 40 10.5C43.9114 10.5 47.0833 13.857 47.0833 18C47.0833 22.143 43.9114 25.5 40 25.5ZM40 0C30.6117 0 23 8.0595 23 18C23 25.527 37.1737 48.0165 40 48C42.7823 48.0165 57 25.425 57 18C57 8.0595 49.3883 0 40 0Z" fill="#7766DF"/>
            </svg>
		</div>
	);
})`
	display: flex;
`;