import React, { useState, useEffect } from "react";
import { useStylesAdvan } from "./styles";
import {
  Grid,
} from "@material-ui/core";
import Button from "@mui/material/Button";
import { StyledTypography } from "./styles";
import { useSelector, useDispatch } from "react-redux";
import AddNewRun from "./AddNewRun";
import { CustomTable } from "./CustomTable";
import { GetRunsByJourneyId, GetDetailsByRunId } from "../../../../redux/actions/AdvanceAutomation/JourneyAction";

export default function Run() {
  const classes = useStylesAdvan();
  const [hasRuns, setHasRuns] = useState(null);
  const [showAddNewRun, setShowAddNewRun] = useState(false);
  const [addOredit, setAddOrEdit] = useState("");
  const [runToEdit, setRunToEdit] = useState(null);

  const dispatch = useDispatch();
  const { runsList, runDetails } = useSelector((state) => state.journey);
  const { journey_id } = useSelector((state) => state.journey);

  useEffect(() => {
    if (journey_id) {
      dispatch(GetRunsByJourneyId(journey_id));
    }
  }, [dispatch, journey_id]); 

  useEffect(() => {
    if (runDetails) {
      setRunToEdit(runDetails);
    }
  }, [runDetails]);

  useEffect(() => {
    setHasRuns(null);
    if (runsList.length > 0) {
      setHasRuns(runsList.length); 
    } else {
      setHasRuns(null);
    }
  }, [runsList]); 

  const handleAddNewRunClick = () => {
    setShowAddNewRun(true);
    setAddOrEdit("Add");
  };

  const handleEditRun = async (row) => {
    setAddOrEdit("Edit");
    try {
      await dispatch(GetDetailsByRunId(row.Id));
      setShowAddNewRun(true);  
    } catch (error) {
      console.error("Error fetching run details: ", error);
    }
  };

  const handleCancelClick = () => {
    setShowAddNewRun(false); 
    setRunToEdit(null);
  };

  return (
    <>
    {showAddNewRun ? (
        <AddNewRun onCancel={handleCancelClick} addOredit={addOredit} runToEdit={runToEdit} setRunToEdit={setRunToEdit}/> 
      ) : (
    <Grid
        container
        className={classes.header}
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        style={{ paddingLeft: 0 }}
      >
        <Grid item xs={6}>
          <div className={classes.highlight}>
            {!hasRuns ? "There is no run to show..." : `There are ${hasRuns} to show...`}
          </div>
        </Grid>
        <Grid item>
          <Button
            className={classes.button}
            sx={{
              backgroundColor: "rgb(101, 77, 247)",
              "&:hover": {
                backgroundColor: "rgb(101, 77, 247) !important",
                borderColor: "#654DF7",
                color: "#fff",
                "&:before": {
                  backgroundColor: "rgb(101, 77, 247) !important",
                  color: "#fff",
                },
              },
              color: "#fff",
            }}
            onClick={handleAddNewRunClick}
          >
            <StyledTypography>Add New Run</StyledTypography>
          </Button>
        </Grid>
      </Grid>
      )}
      {!showAddNewRun && runsList.length > 0 && (
        <CustomTable rows={runsList} handleEditRun={handleEditRun} />
      )}
    </>
  );
}
