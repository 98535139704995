import { styled } from '@mui/material/styles';

export const FunctionalLocalTesting = styled((props) => {
	const { className } = props;

	return (
		<div className={className}>
			<svg width="164" height="118" viewBox="0 0 164 118" fill="none" style={{ height: '20px', width: '20px' }} xmlns="http://www.w3.org/2000/svg">
                <path d="M16.4416 3.74C16.4416 1.67446 18.116 0 20.1816 0H144.239C146.304 0 147.979 1.67446 147.979 3.74V69.7855C147.979 70.232 147.438 70.4546 147.124 70.1375L146.674 69.6838C145.923 68.9262 144.9 68.5 143.833 68.5H135.983C135.026 68.5 134.101 68.8431 133.376 69.4671L132.419 70.2897C132.095 70.5685 131.593 70.3382 131.593 69.9106V16.4421H32.8837V81.1145H16.4416V3.74Z" fill="#654DF7"/>
                <path d="M0 94.4694C0 91.9381 2.05341 89.8868 4.58471 89.8894L113.324 90.0012C113.732 90.0016 114.099 90.2505 114.251 90.6298L115.55 93.8776C115.816 94.5409 115.603 95.2998 115.031 95.7284L113.432 96.9279C112.531 97.604 112 98.6651 112 99.7919V101.379C112 102.117 112.178 102.843 112.52 103.498L116.794 111.689C117.343 112.741 116.58 114 115.393 114H12.51C5.60092 114 0 108.399 0 101.49V94.4694Z" fill="#654DF7"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M140 102C135.617 102 132.067 98.4192 132.067 94C132.067 89.5808 135.617 86 140 86C144.382 86 147.935 89.5808 147.935 94C147.935 98.4192 144.382 102 140 102ZM162.413 99.6576L158.697 97.4944C158.905 96.3584 159.042 95.1984 159.042 94C159.042 92.8032 158.905 91.6416 158.697 90.5056L162.413 88.3424C163.932 87.4592 164.45 85.5008 163.574 83.9712L160.401 78.4288C159.525 76.8992 157.583 76.3728 156.066 77.2576L152.311 79.4416C150.557 77.9376 148.563 76.7072 146.347 75.9152V73.2C146.347 71.4336 144.926 70 143.173 70H136.827C135.073 70 133.652 71.4336 133.652 73.2V75.9152C131.436 76.7072 129.443 77.9376 127.689 79.4416L123.934 77.2576C122.417 76.3728 120.474 76.8992 119.599 78.4288L116.426 83.9712C115.549 85.5008 116.069 87.4592 117.586 88.3424L121.303 90.5056C121.095 91.6416 120.957 92.8032 120.957 94C120.957 95.1984 121.095 96.3584 121.303 97.4944L117.586 99.6576C116.069 100.541 115.549 102.499 116.426 104.029L119.599 109.571C120.474 111.101 122.417 111.627 123.934 110.742L127.689 108.558C129.443 110.062 131.436 111.294 133.652 112.085V114.8C133.652 116.566 135.073 118 136.827 118H143.173C144.926 118 146.347 116.566 146.347 114.8V112.085C148.563 111.294 150.557 110.062 152.311 108.558L156.066 110.742C157.583 111.627 159.525 111.101 160.401 109.571L163.574 104.029C164.45 102.499 163.932 100.541 162.413 99.6576Z" fill="#654DF7"/>
            </svg>
		</div>
	);
})`
	display: flex;
`;