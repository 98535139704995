import React, { useEffect } from "react";
import {
  OutlinedInput,
  FormControl,
  Button,
  Typography,
  DialogActions,
  Grid,
  Divider,
  DialogContent,
  DialogTitle,
  Dialog,
  IconButton,
  Box,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { IndicationIcon, CloseIcon } from "../../../../comman/icons";
import { AddSite, fetchSiteById } from "../../../../redux/actions/AdvanceAutomation/JourneyAction";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  },
  customFontSize: {
    fontSize: "14px !important",
    fontFamily: "Lexend Deca",
    fontWeight: "300!important",
    color: "#646464 !important",
  },
  customheight: {
    height: "40px",
  },
  customBackgroung: {
    border: "1.5px solid #DADADA !important",
    padding: "8px 14px", 
    height: "40px", 
    boxSizing: "border-box", 
  },
  input: {
    marginBottom: theme.spacing(1),
  },
  inputContainer: {
    marginBottom: theme.spacing(2), 
  },
}));

function AddJourney({ open, onClose, parent }) {
  const dispatch = useDispatch();
  const { siteData } = useSelector((state) => state.journey);
  const classes = useStyles();
  const [name, setName] = React.useState("");
  const [url, setUrl] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [nameError, setNameError] = React.useState(false);
  const [urlError, setUrlError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(false);
  const [nameLengthError, setNameLengthError] = React.useState(false);
  const [urlLengthError, setUrlLengthError] = React.useState(false);
  const [emailLengthError, setEmailLengthError] = React.useState(false);
  const [passwordLengthError, setPasswordLengthError] = React.useState(false);

  useEffect(() => {
    if (parent?.type === "edit") {
      dispatch(fetchSiteById(parent.id));
    }
  }, [parent, dispatch]);

  useEffect(() => {
    if (parent?.type === "edit" && siteData) {
      const site = siteData;
      setName(site.name || "");
      setUrl(site.url || "");
      setEmail(site.email || "");
      setPassword(site.password || "");
    }
  }, [siteData, parent]);

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  const resetForm = () => {
    setName("");
    setUrl("");
    setEmail("");
    setPassword("");
    setNameError(false);
    setUrlError(false);
    setEmailError(false);
    setPasswordError(false);
    setNameLengthError(false);
    setUrlLengthError(false);
    setEmailLengthError(false);
    setPasswordLengthError(false);
  };

  const handleNameChange = (event) => {
    const value = event.target.value;
    setName(value);
    setNameLengthError(value.length > 50);
  };

  const handleUrlChange = (event) => {
    const value = event.target.value;
    setUrl(value);
    setUrlLengthError(value.length > 50);
  };

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    setEmailLengthError(value.length > 50);
  };

  const handlePasswordChange = (event) => {
    const value = event.target.value;
    setPassword(value);
    setPasswordLengthError(value.length > 50);
  };

  const handleSubmit = () => {
    let payload = {
      id: parent?.type === "Add" ? 0 : siteData?.id,
      name: name,
      url: url,
      email: email,
      password: password,
      rootId: parent?.type === 'edit' ? parent.parentId : parent.id,
    };
    dispatch(AddSite(payload, onClose));
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "2px solid #DADADA",
          }}
        >
          <span>{parent?.type === "edit" ? "Update Journey" : "Add Journey"}</span>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={classes.input}>
                <Typography className={clsx(classes.customFontSize)}>
                  Name:
                </Typography>
                <FormControl
                  className={clsx(classes.textField)}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: "#654DF7",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#654DF7",
                      },
                      "& fieldset": {
                        borderColor: "transparent ",
                      },
                    },
                    height: "40px",
                  }}
                >
                  <OutlinedInput
                    id="outlined-adornment-name"
                    type="text"
                    placeholder="Enter your journey name"
                    value={name}
                    error={nameError || nameLengthError}
                    onChange={handleNameChange}
                    className={clsx(
                      classes.customheight,
                      classes.customFontSize,
                      classes.customBackgroung
                    )}
                    autoComplete="off"
                  />
                </FormControl>
                {nameLengthError && (
                  <Typography color="error">
                    Name cannot have more than 50 characters*
                  </Typography>
                )}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.input}>
                <Typography className={clsx(classes.customFontSize)}>
                  Enter the URL you want to automate:
                </Typography>
                <FormControl
                  className={clsx(classes.textField)}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: "#654DF7",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#654DF7",
                      },
                      "& fieldset": {
                        borderColor: "transparent ",
                      },
                    },
                    height: "40px",
                  }}
                >
                  <OutlinedInput
                    id="outlined-adornment-url"
                    type="text"
                    placeholder="Enter the URL"
                    value={url}
                    error={urlError || urlLengthError}
                    onChange={handleUrlChange}
                    className={clsx(
                      classes.customheight,
                      classes.customFontSize,
                      classes.customBackgroung
                    )}
                    readOnly={parent?.type === "edit"}
                    autoComplete="off"
                  />
                </FormControl>
                <Grid container direction="row" alignItems="center">
                  <Box mr={1}>
                    <IndicationIcon />
                  </Box>
                  <Typography className={clsx(classes.customFontSize)}>
                    If journey requires authentication then enter Username &
                    Password
                  </Typography>
                </Grid>
                {urlLengthError && (
                  <Typography color="error">
                    URL cannot have more than 50 characters*
                  </Typography>
                )}
              </div>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div className={classes.inputContainer}>
                    <Typography
                      className={clsx(classes.customFontSize)}
                      gutterBottom
                    >
                      Email/Username (Optional)
                    </Typography>
                    <FormControl
                      className={clsx(classes.textField)}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": {
                            borderColor: "#654DF7",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#654DF7",
                          },
                          "& fieldset": {
                            borderColor: "transparent ",
                          },
                        },
                      }}
                    >
                      <OutlinedInput
                        id="outlined-adornment-email"
                        type="text"
                        placeholder="Username"
                        value={email}
                        error={emailError || emailLengthError}
                        onChange={handleEmailChange}
                        className={clsx(
                          classes.customFontSize,
                          classes.customBackgroung
                        )}
                        readOnly={parent?.type === "edit"}
                        autoComplete="new-password"
                        fullWidth
                        inputProps={{
                          style: { padding: 0, height: "24px" }, 
                        }}
                      />
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.inputContainer}>
                    <Typography
                      className={clsx(classes.customFontSize)}
                      gutterBottom
                    >
                      Password (Optional)
                    </Typography>
                    <FormControl
                      className={clsx(classes.textField)}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": {
                            borderColor: "#654DF7",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#654DF7",
                          },
                          "& fieldset": {
                            borderColor: "transparent ",
                          },
                        },
                      }}
                    >
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type="password"
                        placeholder="Password"
                        value={password}
                        error={passwordError || passwordLengthError}
                        onChange={handlePasswordChange}
                        className={clsx(
                          classes.customFontSize,
                          classes.customBackgroung
                        )}
                        readOnly={parent?.type === "edit"}
                        autoComplete="new-password"
                        fullWidth
                        inputProps={{
                          style: { padding: 0, height: "24px" }, 
                        }}
                      />
                    </FormControl>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            onClick={handleSubmit}
            style={{
              marginRight: "10px",
              backgroundColor: "#654DF7",
              height: "30px",
              textTransform: "none",
              color: "white",
            }}
          >
            {parent?.type === "edit" ? "Update Journey" : "Add Journey"}
          </Button>
          <Button
            onClick={onClose}
            color="primary"
            style={{
              width: "100px",
              height: "30px",
              border: "1.5px solid #654DF7",
              textTransform: "none",
              color: "#654DF7",
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddJourney;
