import { styled } from '@mui/material/styles';

export const DocumentIcon = styled((props) => {
	const { className } = props;

	return (
		<div className={className}>
			<svg
				width='20'
				height='20'
				viewBox='0 0 20 20'
				fill='none'>
				<path
					d='M11.6667 4.99999V1.67499C11.7167 1.71665 11.7667 1.76665 11.8084 1.80832L16.525 6.52499C16.5667 6.56665 16.6167 6.61665 16.6584 6.66665H13.3334C12.8917 6.66534 12.4686 6.48932 12.1563 6.17704C11.844 5.86477 11.668 5.44161 11.6667 4.99999Z'
					fill='#654DF7'
				/>
				<path
					d='M17.45 8.33331H13.3333C12.4493 8.33331 11.6014 7.98212 10.9763 7.357C10.3512 6.73188 10 5.88404 10 4.99998V0.883313C9.81854 0.850152 9.63446 0.833418 9.45 0.833313H5C4.33696 0.833313 3.70107 1.09671 3.23223 1.56555C2.76339 2.03439 2.5 2.67027 2.5 3.33331V16.6666C2.5 17.3297 2.76339 17.9656 3.23223 18.4344C3.70107 18.9033 4.33696 19.1666 5 19.1666H15C15.663 19.1666 16.2989 18.9033 16.7678 18.4344C17.2366 17.9656 17.5 17.3297 17.5 16.6666V8.88331C17.4999 8.69885 17.4832 8.51477 17.45 8.33331ZM5.83333 11.6666H9.16667C9.38768 11.6666 9.59964 11.7544 9.75592 11.9107C9.9122 12.067 10 12.279 10 12.5C10 12.721 9.9122 12.933 9.75592 13.0892C9.59964 13.2455 9.38768 13.3333 9.16667 13.3333H5.83333C5.61232 13.3333 5.40036 13.2455 5.24408 13.0892C5.0878 12.933 5 12.721 5 12.5C5 12.279 5.0878 12.067 5.24408 11.9107C5.40036 11.7544 5.61232 11.6666 5.83333 11.6666ZM14.1667 16.6666H5.83333C5.61232 16.6666 5.40036 16.5789 5.24408 16.4226C5.0878 16.2663 5 16.0543 5 15.8333C5 15.6123 5.0878 15.4003 5.24408 15.2441C5.40036 15.0878 5.61232 15 5.83333 15H14.1667C14.3877 15 14.5996 15.0878 14.7559 15.2441C14.9122 15.4003 15 15.6123 15 15.8333C15 16.0543 14.9122 16.2663 14.7559 16.4226C14.5996 16.5789 14.3877 16.6666 14.1667 16.6666Z'
					fill='#654DF7'
				/>
			</svg>
		</div>
	);
})`
	display: flex;
`;