import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, CircularProgress, Tooltip } from "@mui/material";
import { useStyles, StyledTableCell } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ExecuteRun, GetAllResults } from "../../../../redux/actions/AdvanceAutomation/JourneyAction";

const formatDate = (dateString, timeString) => {
  if (!dateString || !timeString) return "";
  const dateTime = new Date(`${dateString}T${timeString}`);
  if (isNaN(dateTime.getTime())) return "";

  const options = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  };
  return dateTime.toLocaleString("en-US", options);
};

export function TableData({ rows, getBrowserIcon }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isExecuting, setIsExecuting] = useState({});
  const [executionStatus, setExecutionStatus] = useState({});
  const { userId } = useSelector((state) => state.auth);
  const { journey_id, journeyDetails } = useSelector((state) => state.journey);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(GetAllResults(journey_id));
    };
    const interval = setInterval(() => {
      if (rows.some(row => row.IsInProgress)) {
        fetchData(); 
      } else {
        clearInterval(interval);
      }
    }, 2000); 

    return () => clearInterval(interval);
  }, [dispatch, journey_id, rows]);

  const handleRowClick = (row) => {
    //this redirect to test case page
  };

  const handleExecute = async (row) => {
    setIsExecuting((prev) => ({
      ...prev,
      [row.RunId]: true,
    }));

    try {
      await dispatch(ExecuteRun(row.RunId, journey_id, userId));
      setExecutionStatus((prev) => ({
        ...prev,
        [row.RunId]: "completed",
      }));
    } catch (error) {
      console.error("Execution failed:", error);
    } finally {
      setIsExecuting((prev) => ({
        ...prev,
        [row.RunId]: false,
      }));
      dispatch(GetAllResults(journey_id));
    }
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>Browser</StyledTableCell>
            <StyledTableCell>Start Date</StyledTableCell>
            <StyledTableCell>End Date</StyledTableCell>
            <StyledTableCell>Test Cases</StyledTableCell>
            <StyledTableCell>Passed</StyledTableCell>
            <StyledTableCell>Failed</StyledTableCell>
            {journeyDetails?.isBaseline && (<StyledTableCell></StyledTableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, index) => (
            <TableRow
              key={`${row.TestRunName}-${row.BrowserName}-${index}`}
              className={`${classes.tableRow}`}
            >
              <StyledTableCell component="th" scope="row">
                <Link
                  to={`/advanceauto/${row?.ClientReferenceId}/${row.BrowserName}`}
                  style={{ textDecoration: "none", cursor: "pointer" }}
                  onClick={() => handleRowClick(row)}
                >
                  {row.TestRunName}
                </Link>
              </StyledTableCell>
              <StyledTableCell>
                {getBrowserIcon(row.BrowserName)}
              </StyledTableCell>
              <StyledTableCell className="p-4">
                {formatDate(row.TestRunStartDate, row.TestRunStartTime)}
              </StyledTableCell>
              <StyledTableCell className="p-4">
                {formatDate(row.TestRunEndDate, row.TestRunEndTime)}
              </StyledTableCell>
              <StyledTableCell className="p-4">
                {row.TotalTestCases}
              </StyledTableCell>
              <StyledTableCell className="p-4">
                {row.PassedTestCases}
              </StyledTableCell>
              <StyledTableCell className="p-4">
                {row.FailedTestCases}
              </StyledTableCell>
              {journeyDetails?.isBaseline && (
                <StyledTableCell className="p-4">
                  {isExecuting[row.RunId] || row.IsInProgress ? (
                    <Tooltip title="Executing">
                      <CircularProgress
                        style={{
                          color: "rgb(101, 77, 247)",
                          cursor: "pointer",
                          marginRight: "10px",
                        }}
                        size={25}
                      />
                    </Tooltip>
                  ) : executionStatus[row.RunId] === "completed" ? (
                    <Tooltip title="Execution Completed">
                      <CheckCircleIcon
                        style={{
                          color: "rgb(101, 77, 247)",
                          cursor: "pointer",
                          marginRight: "10px",
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Execute">
                      <PlayCircleIcon
                        style={{
                          color: "rgb(101, 77, 247)",
                          cursor: "pointer",
                          marginRight: "10px",
                        }}
                        onClick={() => handleExecute(row)}
                      />
                    </Tooltip>
                  )}
                </StyledTableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
