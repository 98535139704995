import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import { useStyles } from "./style"; 
import { Button } from "@mui/material";
import "./style.css";

const CurrentPlan = () => {
  const navigate = useNavigate();
  const modalRef = useRef(null);
  const classes = useStyles();
  const { userData, userId,subscriptionDetail } = useSelector((store) => store.auth);


  return (
    <Box className={classes.CurrentPlan}>
      <Typography className={classes.billingDetails}>
        BILLING DETAILS
      </Typography>
      <Box className={classes.CurrentPlan_Details}>
        <Box className={classes.CurrentPlan_Details_row}>
        <Box sx={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
  <Typography style={{ marginRight: 'auto', border: '0px solid blue' }}>
    Plan 
  </Typography>
  <Typography style={{ marginRight: '10px' }}>
    :
  </Typography>
</Box>
          <Typography style={{ marginLeft: '10px',}}>
          {subscriptionDetail?.remainingDaysInfo?.plan_name}
          </Typography>
        </Box>

        {/* Repeat the Box as needed */}
        <Box className={classes.CurrentPlan_Details_row}>
        <Box sx={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
  <Typography style={{ marginRight: 'auto', border: '0px solid blue' }}>
    Price
  </Typography>
  <Typography style={{ marginRight: '10px' }}>
    :
  </Typography>
</Box>
          <Typography style={{ marginLeft: '10px',  }}>
            N/A
          </Typography>
        </Box>
        <Box className={classes.CurrentPlan_Details_row}>
        <Box sx={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
  <Typography style={{ marginRight: 'auto', border: '0px solid blue' }}>
    Payment Amount
  </Typography>
  <Typography style={{ marginRight: '10px' }}>
    :
  </Typography>
</Box>
          <Typography style={{ marginLeft: '10px',  }}>
            N/A
          </Typography>
        </Box>
        <Box
  className={classes.CurrentPlan_Details_row_last}
 
>        <Box sx={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
  <Typography style={{ marginRight: 'auto', border: '0px solid blue' }}>
    Due Date
  </Typography>
  <Typography style={{ marginRight: '10px' }}>
    :
  </Typography>
</Box>
          <Typography style={{ marginLeft: '10px',  }}>
          {subscriptionDetail?.remainingDaysInfo?.end_date}
          </Typography>
        </Box>
      </Box>
     
      <Box 
  className={classes.button} 
  onClick={() => window.location.href = 'https://www.ghostqa.com/pricing/'}
  style={{ cursor: 'pointer' }} // This will change the cursor to a hand icon on hover
>
  Change Plan
</Box>

    </Box>
  );
};

export default CurrentPlan;
