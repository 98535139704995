import React from 'react';
import { styled } from '@mui/material/styles';
import firefoxIcon from './firefox.png';

export const BrowserFirefox = styled(({ className, size = 50 }) => {
  return (
    <div className={className}>
      <img
        src={firefoxIcon}
        alt="Firefox Logo"
        style={{ height: `${size}px`, width: `${size}px` }} // Dynamic size
      />
    </div>
  );
})`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;