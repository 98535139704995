import axios from "axios";
import { toast } from "react-toastify";
import { header } from "../../../utils/authheader";
import { getBaseUrl } from "../../../utils/configService";

export const GET_SELECTOR_LIST = "GET_SELECTOR_LIST"

export const GetSelector = () => {
    return async (dispatch) => {
      try {
        const BASE_URL = await getBaseUrl();
        const response = await axios.get(
          `${BASE_URL}/AddTestLab/GetAllTestLabGlobalData`,
          header()
        );
  
        dispatch({
          type: GET_SELECTOR_LIST,
          payload: response.data,
        });
      } catch (error) {
        console.error(error);
        toast.error(error);
      }
    };
  };

  export const AddUpdateSelector = (data) => {
    return async (dispatch) => {
      try {
        const BASE_URL = await getBaseUrl();
        const res = await axios.post(
          `${BASE_URL}/AddTestLab/AddUpdateTestLabGlobalData`,
          data,
          header()
        );
        if (res.data.status === "success") {
          dispatch(GetSelector());
          toast.info(res.data.message, {
            style: {
              background: "rgb(101, 77, 247)",
              color: "rgb(255, 255, 255)",
            },
          });
        } else if (res.data.status === "fail") {
          toast.error(res.data.message);
        }
      } catch (error) {
        console.log("error adding ", error);
        toast("Posting error");
      }
    };
  };

  export const DeleteTestData = (id) => {
    return async (dispatch) => {
      try {
        const BASE_URL = await getBaseUrl();
        const res = await axios.post(
          `${BASE_URL}/AddTestLab/DeleteTestLabGlobalData`,
          {
            id: id
          },
          header()
        );
        const response = res.data;
        if (response.status === "success") {
          dispatch(GetSelector());
          toast.info(response.message, {
            style: {
              background: "rgb(101, 77, 247)",
              color: "rgb(255, 255, 255)",
            },
          });
        }
  
        return response;
      } catch (error) {
        console.log("error sending ", error);
      }
    };
  };
