import React from "react";
import { styled } from "@mui/material/styles";

export const DrawerOpenIcon = ({ isActive, color }) => {
  return (
    <div style={{ color: isActive ? color : "inherit" }}>
      <svg
        width="44"
        height="31"
        viewBox="0 0 44 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.5"
          y="0.5"
          width="43"
          height="30"
          rx="3.5"
          stroke="#DADADA"
        />
        <path
          d="M33 8H23.2222M18.3333 8V23M33 13H23.2222M33 18H23.2222M33 23H23.2222M13.4444 13L11 15.5L13.4444 18"
          stroke="#646464"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};
