import React, { useState, useRef } from "react";
import { Grid, Typography, Paper, Box, Card } from "@material-ui/core";
import { useStyles } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CurrentPlan from "./planBox";
import IncludePlans from "./includePlan";
import { UsersIcons } from "../../comman/icons";
import { AlertIcon } from "../../comman/icons/alert-2_svgrepo.com";
import { AlertIcon_red } from "../../comman/icons/alert-2_svgrepo.com (1)";

export default function Subscription() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const scrollContainerRef = useRef(null);
  const { testSuits, executingSuite, selectedSuite, selectedTab } = useSelector(
    (state) => state.selenium
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [openDelModal, setopenDelModal] = useState(false);
  const [inprogress, setInProgress] = useState(false);
  const [suiteTab, setSuiteTab] = useState("1");
  const [addNewProject, setAddNewProject] = useState(false);
  const [depth, setdepth] = useState(0);
  const { userData, userId,subscriptionDetail } = useSelector((store) => store.auth);
  console.log("subscription_Detail",subscriptionDetail);


  const tabLableStyle = {
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "21px",
    padding: "10px 22px",
    textTransform: "capitalize",
  };

  const tabHeaderStyle = {
    fontSize: "12px",
    fontWeight: "400",
    fontFamily: "Lexend Deca",
    textTransform: "capitalize",
    height: "30px !important",
  };

  // Dummy feature list data
  const feature_list = [
    { name: "WebCam" },
    { name: "Project & Task Report" },
    { name: "Screenshot" },
    { name: "Attendence" },
    { name: "Employee Settings" },
    { name: "Leave Module" },
    { name: "Payroll" },
   
  ];
  const daysRemaining = subscriptionDetail?.remainingDaysInfo?.days_remaining;

  return (
    <>
      <div className={classes.main}>
      <Box 
  className={classes.reminderbox} 
  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
>
<Typography
      style={{
        display: 'flex',
        alignItems: 'center',
        color: daysRemaining <= 5 ? 'red' : 'inherit', // Conditionally set color to red
      }}
    >
      {daysRemaining <= 5 ? (
        <AlertIcon_red style={{ marginRight: 4 }} /> // Use the default AlertIcon
      ) : (
        <AlertIcon style={{ marginRight: 4 }} /> // Use AlertIconRed if condition not met
      )}
      {daysRemaining} Days left in your subscription! Enjoy your subscription now.
    </Typography>
</Box>

        <Box className={classes.secondbox}>
          <CurrentPlan />
          <Box className={classes.available_plan} >

          <Typography className={classes.billingDetails}>
            INCLUDED IN THE PLANS
          </Typography>
          <Box className={classes.includedplan}>
         {subscriptionDetail?.remainingDaysInfo?.features_available.map((feature, index) => (
        <IncludePlans key={index} name={feature} />
          ))}
    </Box> 
         </Box>      
        </Box>
      </div>
    </>
  );
}
