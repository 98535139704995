import React from "react";

export const IndicationIcon = ({ isActive, color }) => {
  return (
    <div style={{ color: isActive ? color : "inherit" }}>
      <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.5 6.5C13.5 10.0899 10.5899 13 7 13C3.41015 13 0.5 10.0899 0.5 6.5C0.5 2.91015 3.41015 0 7 0C10.5899 0 13.5 2.91015 13.5 6.5Z" fill="#654DF7"/>
        <path d="M6.34998 5.19961C6.34998 4.84062 6.64099 4.54961 6.99998 4.54961C7.35896 4.54961 7.64998 4.84062 7.64998 5.19961V9.74961C7.64998 10.1086 7.35896 10.3996 6.99998 10.3996C6.64099 10.3996 6.34998 10.1086 6.34998 9.74961V5.19961Z" fill="white"/>
        <path d="M7.64998 3.24961C7.64998 3.60859 7.35896 3.89961 6.99998 3.89961C6.64099 3.89961 6.34998 3.60859 6.34998 3.24961C6.34998 2.89062 6.64099 2.59961 6.99998 2.59961C7.35896 2.59961 7.64998 2.89062 7.64998 3.24961Z" fill="white"/>
        </svg>
    </div>
  );
};
