import { TEST_RUN } from "../../actions/testlab/testrunAction";

const initialState = {
    testrunlist: [],
  };


  const testrunReducer = (state = initialState, action) => {
    switch (action.type) {
      case TEST_RUN:
        return {
          ...state,
          testrunlist: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default testrunReducer;